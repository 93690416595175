import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PriceHelper } from '../../helper/price.helper';

interface IArticleChipPriceChanges {
	/** Valeur du prix de l'article. */
	priceValue: number;
	/** Icône du prix de l'article. */
	priceIcon: string;
	/** Css d'arrière-plan du prix de l'article. */
	priceBackgroundCss;
}

@Component({
	selector: "merch-article-chip-price",
	templateUrl: './article-chip-price.component.html',
	styleUrls: ['./article-chip-price.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleChipPriceComponent implements OnDestroy {

	//#region FIELDS

	private readonly moEventChangesSubject = new BehaviorSubject<IArticleChipPriceChanges>({} as unknown as IArticleChipPriceChanges);

	/** Icône du prix de l'article. */
	private msPriceIcon: string;
	/** Css d'arrière-plan du prix de l'article. */
	private msBackgroundPriceCss: string;

	//#endregion

	//#region PROPERTIES

	private msPriceType: string;
	/** @implements */
	public get priceType(): string { return this.msPriceType; }
	@Input() public set priceType(psPriceType: string) {
		if (psPriceType !== this.msPriceType) {
			this.msPriceType = psPriceType;
			this.msPriceIcon = PriceHelper.getPriceTypeIcon(this.msPriceType);
			this.msBackgroundPriceCss = this.getBackgroundPriceCss();
			this.raiseChangesEvent();
		}
	}

	private mnPriceValue: number;
	/** @implements */
	public get priceValue(): number { return this.mnPriceValue; }
	@Input() public set priceValue(pnPriceValue: number) {
		if (pnPriceValue !== this.mnPriceValue) {
			this.mnPriceValue = pnPriceValue;
			this.raiseChangesEvent();
		}
	}

	/** Flux continu récupérant le type de prix, sa valeur, son icône et sa css d'arrière-plan. */
	public get changes$(): Observable<IArticleChipPriceChanges> { return this.moEventChangesSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor() { }

	public ngOnDestroy(): void {
		this.moEventChangesSubject.complete();
	}

	/** Retourne la classe css pour l'arrière plan du prix en fonction du type de prix. */
	private getBackgroundPriceCss(): string {
		if (PriceHelper.isPetitPrice(this.msPriceType))
			return "yellow-price";
		else if (PriceHelper.isOutletPrice(this.msPriceType))
			return "orange-price";
		return "grey-price";
	}

	private raiseChangesEvent(): void {
		this.moEventChangesSubject.next({
			priceValue: this.mnPriceValue,
			priceIcon: this.msPriceIcon,
			priceBackgroundCss: this.msBackgroundPriceCss
		});
	}

	//#endregion

}