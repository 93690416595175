import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, combineLatest, defer, of } from 'rxjs';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PopoverService } from '../../../../services/popover.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { IEntityEntriesListParams } from '../../../entities/models/ientity-entries-list-params';
import { IFormListDetailEvent } from '../../../forms/models/iform-list-detail-event';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EPermissionScopes } from '../../../permissions/models/epermission-scopes';
import { TCRUDPermissions } from '../../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { ISelectorParams } from '../../../selector/selector/ISelectorParams';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Document } from '../../models/document';
import { EExplorerDisplayMode } from '../../models/eexplorer-display-mode';
import { EExplorerMode } from '../../models/eexplorer-mode';
import { EListItemOption } from '../../models/elist-item-option';
import { Folder } from '../../models/folder';
import { FolderContent } from '../../models/folder-content';
import { FormDocument } from '../../models/form-document';
import { IDocExlorerFilterValues } from '../../models/idoc-explorer-filter-values';
import { IListItemOption } from '../../models/ilist-item-option';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';
import { DocExplorerService } from '../../services/doc-explorer.service';
import { DocumentStatusService } from '../../services/document-status.service';

@Component({
	selector: 'calao-folder-list',
	templateUrl: './folder-list.component.html',
	styleUrls: ['./folder-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderListComponent extends DestroyableComponentBase {

	//#region FIELDS

	@Output("onOptionClicked") private readonly moOptionClickedEvent = new EventEmitter<IListItemOptionClickEvent>();
	@Output("onOpenClicked") private readonly moOpenClickedEvent = new EventEmitter<Document>();
	@Output("onPathChanged") private readonly moPathChangedEvent = new EventEmitter<string>();
	@Output("onDisplayModeChanged") private readonly moDisplayModeChangedEvent = new EventEmitter<EExplorerDisplayMode>();

	/** Filtres. */
	private readonly moObservableFilterValues = new ObservableProperty<IDocExlorerFilterValues>({});

	//#endregion FIELDS

	//#region PROPERTIES

	/** Route initiale. */
	@Input() public rootPath?: string;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "rootPath" })
	public readonly observableRootPath = new ObservableProperty<string>(undefined);

	/** Route courante. */
	@Input() public currentPath?: string;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "currentPath" })
	public readonly observableCurrentPath = new ObservableProperty<string>();

	/** Mode. */
	@Input() public mode?: EExplorerMode;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "mode" })
	public readonly observableMode = new ObservableProperty<EExplorerMode>(EExplorerMode.explorer);

	/** Mode d'affichage. */
	@Input() public displayMode?: EExplorerDisplayMode;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<EExplorerDisplayMode>(EExplorerDisplayMode.folders);

	public readonly displayMode$: Observable<EExplorerDisplayMode> = this.observableDisplayMode.value$.pipe(
		map((peDisplayMode?: EExplorerDisplayMode) => peDisplayMode ?? EExplorerDisplayMode.folders),
		secure(this)
	)

	/** Arbre de navigation. */
	public readonly observableNavigationTree = new ObservableArray<Folder>();

	/** Liste des dossiers à afficher. */
	public readonly observableFolders = new ObservableArray<FolderContent>();

	/** Liste des documents à afficher. */
	public readonly observableDocuments = new ObservableArray<Document>();
	public readonly observableDisplayNavigationTree = new ObservableProperty<boolean>(false);

	/** Indique si un chargement est en cours. */
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);

	public readonly observableListItemOptionsById = new ObservableProperty<Map<string, IListItemOption[]>>(new Map());


	/** Flux des options d'affichage. */
	public readonly observableDisplayModeOptions = new ObservableArray<ISelectOption<EExplorerDisplayMode>>([
		this.getFoldersSelectOption(),
		this.getDateSelectionOption(),
		this.getTabSelectOption()
	]);

	/** Flux des options de la vue formList. */
	public readonly formListParams$: Observable<IEntityEntriesListParams<any> | undefined>;
	/** Enum mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;
	/** Enum mode d'affichage. */
	public readonly explorerDisplayMode = EExplorerDisplayMode;

	public readonly displayFolders$: Observable<boolean> = this.displayMode$.pipe(
		map((peDisplayMode: EExplorerDisplayMode) => peDisplayMode === EExplorerDisplayMode.folders),
		secure(this)
	);

	public readonly observableFilterValues = new ObservableProperty<IDocExlorerFilterValues>({});
	/** Configuration du composant osapp-selector pour le filtrage par état de lecture. */
	public readonly observableNotReadSelectorParams = new ObservableProperty<ISelectorParams>(this.getNotReadSelectorParams());

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorer: DocExplorerService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPopover: PopoverService,
		protected readonly isvcDocumentStatus: DocumentStatusService,
		private readonly isvcPermissions: PermissionsService

	) {
		super();

		this.initFolderContent();
		this.initDisplayMode();
		this.initDocumentsNavigationTree();
		this.initItemsOptions();
		this.formListParams$ = this.getFormListParams$().pipe(secure(this));
	}

	private initFolderContent(): void {
		this.observableCurrentPath.value$.pipe(
			tap(() => this.observableIsLoading.value = true),
			switchMap((psPath: string) => this.isvcDocExplorerDocuments.getFolderContent$(psPath)),
			switchMap((poFolderContent: FolderContent) => {
				this.replaceOption(this.getDateSelectionOption(!ArrayHelper.hasElements(poFolderContent.folders)));
				const laDocsRetrievedRecursively: Document[] = poFolderContent.getDocumentsRecursively(!this.observableCurrentPath.value?.startsWith("trash"));

				return combineLatest([this.moObservableFilterValues.value$, this.observableDisplayMode.value$, this.observableMode.value$]).pipe(
					tap(([poFilters, peDisplayMode, peMode]: [IDocExlorerFilterValues, EExplorerDisplayMode, EExplorerMode | undefined]) => {
						if (peMode === EExplorerMode.picker)
							ArrayHelper.removeElementByFinder(poFolderContent.folders, (poFolder: FolderContent) => poFolder.current.isInTrash);

						this.observableNavigationTree.resetSubscription(
							this.isvcDocExplorer.getNavigationTree$(poFolderContent.current.path, this.observableRootPath.value).pipe(secure(this))
						);

						this.setFolders(poFolderContent, poFilters);
						this.setDocuments(laDocsRetrievedRecursively, poFolderContent, poFilters, peDisplayMode);
						this.observableIsLoading.value = false;
					}, () => {
						this.observableIsLoading.value = false;
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Une erreur est survenue lors de la récupération des documents." }));
					}),
				);
			}),
			secure(this)
		).subscribe();
	}

	private getFormListParams$(): Observable<IEntityEntriesListParams<any> | undefined> {
		return combineLatest([this.observableNavigationTree.changes$, this.observableDocuments.changes$]).pipe(
			map(([paFolders, paDocs]: [Folder[], Document[]]) => {
				const loCurrentFolder: Folder | undefined = ArrayHelper.getLastElement(paFolders);

				if (ArrayHelper.hasElements(loCurrentFolder?.documentTypes.forms)) {
					const laFormDocuments: IStoreDocument[] = [];
					paDocs.forEach((poDocument: Document) => {
						if (poDocument instanceof FormDocument)
							laFormDocuments.push(poDocument);
					});

					return {
						entityDescId: loCurrentFolder.documentTypes.forms[0].descriptor,
						listId: loCurrentFolder.documentTypes.forms[0].list,
						customEntries: laFormDocuments
					} as IEntityEntriesListParams<IStoreDocument>;
				}

				return undefined;
			})
		);
	}

	private initDocumentsNavigationTree(): void {
		combineLatest([this.observableDocuments.changes$, this.observableDisplayNavigationTree.value$]).pipe(
			filter(([_, pbDisplayNavigationTree]: [Document[], boolean]) => pbDisplayNavigationTree),
			switchMap(([paDocuments, _]: [Document[], boolean]) => this.isvcDocExplorer.fillDocumentsNavigationTrees$(paDocuments, this.observableRootPath.value)),
			secure(this)
		).subscribe();
	}

	private initDisplayMode(): void {
		this.observableNavigationTree.changes$.pipe(
			tap((paFolder: Folder[]) => {
				const loCurrentFolder: Folder | undefined = ArrayHelper.getLastElement(paFolder);
				if (!ArrayHelper.hasElements(loCurrentFolder?.documentTypes?.forms))
					this.replaceOption(this.getTabSelectOption(true));
				else
					this.replaceOption(this.getTabSelectOption());
			}),
			secure(this)
		).subscribe();
	}

	private initItemsOptions(): void {
		this.observableDocuments.changes$.pipe(
			switchMap((paDocuments: Document[]) => combineLatest(paDocuments.map((poDocument: Document) => poDocument.observableIsRead.value$)).pipe(
				tap(() => {
					this.observableListItemOptionsById.value = this.getItemsOptionsById(
						paDocuments,
						this.getFilePermissionsById(paDocuments)
					);
				}),
			)),
			secure(this)
		).subscribe();
	}

	private setFolders(poFolderContent: FolderContent, poFilters: IDocExlorerFilterValues): void {
		if (!StringHelper.isBlank(poFilters.name) || poFilters.notRead) { // On fait une recherhe sur le nom ou l'état de lecture.
			this.observableFolders.resetArray(poFolderContent.searchFolders(poFilters));
		}
		else
			this.observableFolders.resetArray(poFolderContent.folders);
	}

	private setDocuments(paDocsRetrievedRecursively: Document[], poFolderContent: FolderContent, poFilters: IDocExlorerFilterValues, peDisplayMode: EExplorerDisplayMode): void {
		if (!ObjectHelper.isEmpty(poFilters)) { // On fait une recherhe.
			const laFilterEntries: string[] = Object.keys(poFilters).filter((psKey: keyof IDocExlorerFilterValues) => ObjectHelper.isDefined(poFilters[psKey]));
			const lbOnlyNotRead: boolean = laFilterEntries.length === 1 && (laFilterEntries[0] as keyof IDocExlorerFilterValues) === "notRead";
			const lbIsDateDisplayMode: boolean = peDisplayMode === EExplorerDisplayMode.date;

			this.observableDocuments.resetArray(poFolderContent.searchDocuments(poFilters, !lbOnlyNotRead || lbIsDateDisplayMode, lbIsDateDisplayMode));
			this.observableDisplayNavigationTree.value = !lbOnlyNotRead;
		}
		else {
			if (peDisplayMode === EExplorerDisplayMode.date)
				this.observableDocuments.resetArray(paDocsRetrievedRecursively);
			else
				this.observableDocuments.resetArray(poFolderContent.documents);

			this.observableDisplayNavigationTree.value = false;
		}
	}

	private getTabSelectOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "list", value: EExplorerDisplayMode.tab, hidden: pbHidden };
	}

	private getDateSelectionOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "time", value: EExplorerDisplayMode.date, hidden: pbHidden };
	}

	private getFoldersSelectOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "folder", value: EExplorerDisplayMode.folders, hidden: pbHidden };
	}

	private replaceOption(poOption: ISelectOption<EExplorerDisplayMode>): void {
		const loOption: ISelectOption<EExplorerDisplayMode> | undefined = this.observableDisplayModeOptions.find(
			(poDisplayModeOption: ISelectOption<EExplorerDisplayMode>) => poDisplayModeOption.value === poOption.value
		);

		if (loOption?.hidden !== poOption.hidden)
			ArrayHelper.replaceElement(this.observableDisplayModeOptions, loOption, poOption);
	}

	private getFilePermissionsById(paDocuments: Document[]): Map<string, Map<TCRUDPermissions, boolean>> {
		const loFilePermissionsById = new Map<string, Map<TCRUDPermissions, boolean>>();

		paDocuments.forEach((poDocument: Document) => loFilePermissionsById.set(
			poDocument._id,
			this.getFilePermissions(poDocument)
		));

		return loFilePermissionsById;
	}

	private getFilePermissions(poDocument: Document): Map<TCRUDPermissions, boolean> {
		const loFilePermissions = new Map<TCRUDPermissions, boolean>();
		loFilePermissions.set("delete", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "delete", false));
		loFilePermissions.set("trash", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", false));
		loFilePermissions.set("edit", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "edit", false));
		return loFilePermissions;
	}

	private getItemsOptionsById(
		paDocuments: Document[],
		poPermissionsById: Map<string, Map<TCRUDPermissions, boolean>>
	): Map<string, IListItemOption[]> {
		const loItemsOptionsById = new Map<string, IListItemOption[]>();

		paDocuments.forEach((poDocument: Document) => loItemsOptionsById.set(
			poDocument._id,
			this.getItemOptions(
				poDocument,
				poPermissionsById.get(poDocument._id)
			)
		));

		return loItemsOptionsById;
	}

	private getItemOptions(
		poDocument: Document,
		poPermission?: Map<TCRUDPermissions, boolean>
	): IListItemOption[] {
		if (this.observableMode.value === EExplorerMode.picker)
			return [{ label: "Voir", icon: "eye", color: "primary", key: EListItemOption.read }];

		const laOptions: IListItemOption[] = [];

		if (!poDocument?.isInTrash) {
			if (poDocument.observableIsRead.value)
				laOptions.push({ label: "Non lu", icon: "mail-unread", color: "primary", key: EListItemOption.markAsUnread });
			else
				laOptions.push({ label: "Lu", icon: "mail-open", color: "primary", key: EListItemOption.markAsRead });

			if (poPermission) {
				if (poDocument instanceof FormDocument && poPermission.get("edit"))
					laOptions.push({ label: "Éditer", icon: "create", color: "primary", key: EListItemOption.edit });
			}

			if (this.isvcPermissions.evaluatePermission(EPermissionScopes.conversations, "read"))
				laOptions.push({ label: "Partager dans la conversation", icon: "share", color: "primary", key: EListItemOption.share });
		}

		if (poPermission) {
			if (poDocument?.canArchive && poPermission.get("trash"))
				laOptions.push({ label: "Mettre à la corbeille", icon: "trash", color: "primary", key: EListItemOption.trash });
			if (poDocument?.canRestore && poPermission.get("trash"))
				laOptions.push({ label: "Restaurer", icon: "refresh", color: "primary", key: EListItemOption.restore });
			if (poDocument?.isInTrash && poPermission.get("delete"))
				laOptions.push({ label: "Supprimer définitivement", icon: "trash", color: "danger", key: EListItemOption.delete });
		}

		return laOptions;
	}

	public goToFolder(poFolder: Folder): void {
		this.observableFilterValues.value = { notRead: this.moObservableFilterValues.value?.notRead };
		this.moPathChangedEvent.emit(poFolder.path);
	}

	public onDisplayModeChanged(peSelectedDisplayMode: EExplorerDisplayMode): void {
		this.moDisplayModeChangedEvent.emit(
			this.observableDisplayMode.value = peSelectedDisplayMode
		);
	}

	private onOptionClicked(poEvent: IListItemOptionClickEvent): void {
		this.moOptionClickedEvent.emit(poEvent);
	}

	public onOpenClicked(poDocument: Document): void {
		if (this.observableMode.value === EExplorerMode.picker)
			this.onOptionClicked({ document: poDocument, key: EListItemOption.pick });
		else
			this.moOpenClickedEvent.emit(poDocument);
	}

	public async onDetailClickedAsync(poEvent: IFormListDetailEvent<Document>): Promise<void> {
		const laItemOptions: IListItemOption[] | undefined = this.observableListItemOptionsById.value?.get(poEvent.item._id);

		if (laItemOptions)
			await this.openPopover$(poEvent, laItemOptions).toPromise();
	}

	@Queue<FolderListComponent, Parameters<FolderListComponent["openPopover$"]>, ReturnType<FolderListComponent["openPopover$"]>>({
		excludePendings: true
	})
	private openPopover$(poEvent: IFormListDetailEvent<Document>, paItemOptions: IListItemOption[]): Observable<boolean> {
		return defer(() => {
			return this.isvcPopover.showPopoverAsync(
				paItemOptions.map(
					(poListItemOption: IListItemOption) => ({
						title: poListItemOption.label,
						icon: poListItemOption.icon,
						color: poListItemOption.color,
						action: () => of(this.onOptionClicked({ document: poEvent.item, key: poListItemOption.key }))
					})
				),
				poEvent.event
			);
		}).pipe(mapTo(true));
	}

	public onFilterValuesChange(poFilterValues: IDocExlorerFilterValues): void {
		this.moObservableFilterValues.value = poFilterValues;
	}

	private getNotReadSelectorParams(): ISelectorParams {
		return {
			preselectedValues: [],
			displayMode: ESelectorDisplayMode.list,
			multiple: false,
			options: [
				{
					label: "Afficher uniquement les documents non lus",
					value: true
				},
			]
		};
	}

	//#endregion

}
