<osapp-header-modal [title]="observablePickerDescription.value$ | async"></osapp-header-modal>

<ion-content>
	<div class="content">
		<calao-entity-picker-filter-bar-component [filterValues]="{}" (onFilterValuesChange)="onFilterValuesChange($event)"
			[searchInputPlaceholder]="observableSearchboxPlaceholder.value$ | async"></calao-entity-picker-filter-bar-component>

		<ng-container *ngIf="observableFilteredEntries.changes$ | async as filteredDocuments; else loadingSpinner;">
			<!-- Affichage de la liste. -->
			<ng-container *ngIf="filteredDocuments.length !== 0; else emptyScreen;">
				<calao-virtual-scroll [items]="filteredDocuments" [itemSize]="98" autosize transparent margeLastItem>
					<ng-template let-item="$implicit">
						<ion-item (click)="close(item)">
							<ion-label>{{getEntityName(item)}}</ion-label>
						</ion-item>
					</ng-template>
				</calao-virtual-scroll>
			</ng-container>
		</ng-container>
	</div>

	<!-- Afficher s'il y a un chargement -->
	<ng-template #loadingSpinner>
		<div class="full-width">
			<osapp-spinner>
			</osapp-spinner>
		</div>
	</ng-template>

	<!-- Si le composant a terminé de s'initialiser et qu'il n'y a aucun résultat. -->
	<ng-template #emptyScreen>
		<div class="empty-screen flex-col">
			<span class="txt-message">
				<ion-note>Aucun résultat.</ion-note>
			</span>
		</div>
	</ng-template>

	<ion-fab *ngIf="hasCreateButton" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button class="fab-shadow" (click)="onCreateEntityAsync()">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
	</ion-fab>
</ion-content>