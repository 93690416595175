import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { defer, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IMerchandising } from '../../../../../../../../../apps/merch/src/app/tour/models/IMerchandising';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { ActivePageManager } from '../../../../../../model/navigation/ActivePageManager';
import { AppointmentService } from '../../../../../tour/appointment/appointment.service';
import { EAppointmentType } from '../../../../../tour/appointment/models/EAppointmentType';
import { IAppointment } from '../../../../../tour/appointment/models/IAppointment';
import { ITour } from '../../../../../tour/models/ITour';
import { TourService } from '../../../../../tour/tour/tour.service';
import { secure } from '../../../../../utils/rxjs/operators/secure';

/** Sélectionne un client à partir de la liste des rendez-vous de la tournée. */
@Component({
	selector: 'customer-selector-component',
	templateUrl: './customer-selector.component.html',
	styleUrls: ['./customer-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSelectorComponent extends ComponentBase {

	//#region FIELDS

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter);

	@Output("onCustomerSelected") private readonly moOnCustomerSelected = new EventEmitter<string>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Liste des rendez-vous. La valeur est `undefined` pendant son chargement et un tableau vide s'il n'y a pas de rendez-vous. */
	public readonly appointments$: Observable<IMerchandising[] | undefined> = of(undefined);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcTour: TourService,
		private readonly isvcAppointment: AppointmentService,
		private readonly ioRouter: Router
	) {
		super();
		this.appointments$ = this.getAppointments$();
	}

	private getAppointments$(): Observable<IMerchandising[] | undefined> {
		return defer(() => this.isvcTour.getCurrentTourAsync()).pipe(
			mergeMap(((poTour: ITour<unknown>) => this.isvcAppointment.getAppointments<IAppointment>(poTour._id, this.moActivePageManager))),
			map((paAppointments: IAppointment[]): IMerchandising[] =>
				paAppointments.filter((poAppointment: IAppointment) => poAppointment.appointmentType === EAppointmentType.merchandising) as IMerchandising[]),
			map((paAppointments: IMerchandising[]) => DateHelper.sortByDate<IMerchandising>(paAppointments, (poAppointment: IMerchandising) => poAppointment.expectedDate)),
			secure(this)
		);
	}

	public onCustomerChanged(psCustomerId: string): void {
		this.moOnCustomerSelected.emit(psCustomerId);
	}

	//#endregion METHODS

}
