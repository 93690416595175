import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable, defer, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserHelper } from '../../../../helpers/user.helper';
import { IContact } from '../../../../model/contacts/IContact';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { ContactsService } from '../../../../services/contacts.service';
import { PopoverService } from '../../../../services/popover.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EPermissionScopes } from '../../../permissions/models/epermission-scopes';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';

@Component({
	selector: "calao-contact-item",
	templateUrl: './contact-item.component.html',
	styleUrls: ['./contact-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactItemComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONTACT.ITM.C::";

	/** Événement lors d'un clic sur un item d'un contact. */
	@Output("onContactClicked") private readonly moOnContactClicked = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton supprimer d'un item d'un contact. */
	@Output("onDeleteContactClicked") private readonly moOnDeleteContactClickedEmitter = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton editer d'un item d'un contact. */
	@Output("onEditContactClicked") private readonly moOnEditContactClickedEmitter = new EventEmitter<IContact>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Contact. */
	@Input() public item?: IContact;
	@ObserveProperty<ContactItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<IContact>();

	/** Nom des groupes. */
	@Input() public groupsLabel?: string;
	@ObserveProperty<ContactItemComponent>({ sourcePropertyKey: "groupsLabel" })
	public readonly observableGroupsLabel = new ObservableProperty<string>("");

	public readonly observablePopOverItemsParams = new ObservableArray<IPopoverItemParams>([]);

	public get canEditContact(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermissionScopes.contacts, "edit");
	}

	public get canDeleteContact(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermissionScopes.contacts, "delete");
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcPopover: PopoverService
	) {
		super();
	}

	/** Emet l'évènement au clic sur l'item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onContactClicked(poContact: IContact): void {
		this.moOnContactClicked.emit(poContact);
	}

	public ngOnInit(): void {
		this.observableItem.value$.pipe(
			tap((poContact: IContact) => {
				const loEditPopOverParams: IPopoverItemParams = {
					action: () => this.onEditContactClicked$(),
					id: "edit",
					color: "primary",
					icon: "create",
					title: "Éditer"
				};
				const loDeletePopOverParams: IPopoverItemParams = {
					action: () => this.onDeleteContactClicked$(),
					id: "delete",
					color: "danger",
					icon: "trash",
					title: "Supprimer définitivement"
				};

				const laPopOverItemsParams: IPopoverItemParams[] = [];
				let lbHasEditPopOverParams = false;
				let lbDeletePopOverParams = false;
				laPopOverItemsParams.forEach((poPopOverParam: IPopoverItemParams) => {
					if (!lbHasEditPopOverParams)
						lbHasEditPopOverParams = poPopOverParam.id === loEditPopOverParams.id;
					if (!lbDeletePopOverParams)
						lbDeletePopOverParams = poPopOverParam.id === loDeletePopOverParams.id;
				});

				if (!lbHasEditPopOverParams && this.canEditContact && !poContact.userId)
					laPopOverItemsParams.push(loEditPopOverParams);
				if (!lbDeletePopOverParams && this.canDeleteContact && !poContact.userId)
					laPopOverItemsParams.push(loDeletePopOverParams);

				this.observablePopOverItemsParams.resetArray([...laPopOverItemsParams]);
			}),
			secure(this)
		).subscribe();
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<ContactItemComponent, Parameters<ContactItemComponent["presentPopOver$"]>, ReturnType<ContactItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.observablePopOverItemsParams, poEvent));
	}

	private onEditContactClicked$(): Observable<void> {
		if (this.observableItem.value)
			return of(this.moOnEditContactClickedEmitter.emit(this.observableItem.value));
		else {
			console.warn(`${ContactItemComponent.C_LOG_ID}contact is not defined !`);
			return EMPTY;
		}
	}

	private onDeleteContactClicked$(): Observable<void> {
		if (this.observableItem.value)
			return of(this.moOnDeleteContactClickedEmitter.emit(this.observableItem.value));
		else {
			console.warn(`${ContactItemComponent.C_LOG_ID}contact is not defined !`);
			return EMPTY;
		}
	}

	public isUserContact(poContact: IContact): Boolean {
		return UserHelper.getUserContactId() === poContact._id;
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poContact
	 */
	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big, true);
	}

	//#endregion

}