import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../../../../pipes/pipes.module';
import { BlankInformationModule } from '../../../../../blank-information/blank-information.module';
import { ArticleChipPriceModule } from '../../../../prices/components/article-chip-price/article-chip-price.module';
import { MovementsListComponent } from './movements-list.component';

const laComponents = [MovementsListComponent];

@NgModule({
	declarations: laComponents,
	imports: [
		CommonModule,
		IonicModule,
		PipeModule,
		BlankInformationModule,
		ArticleChipPriceModule
	],
	exports: laComponents
})
export class MovementsListModule { }
