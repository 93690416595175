<ion-card *ngIf="acquisitions.length > 0 && hasQualityAlgo()">
	<ion-card-header class="ion-text-center">
		<ion-card-subtitle>Qualité de la lecture</ion-card-subtitle>
		<ion-card-title>{{ getReadingQuality() }} %</ion-card-title>
	</ion-card-header>
</ion-card>

<!-- En-tête -->
<ion-grid *ngIf="!hasSpecificSummaryComponent()">
	<ion-row class="header">
		<ion-col size="4">
			Données
		</ion-col>
		<ion-col size="2" *ngIf="hasQualityAlgo()">
			Qualité
		</ion-col>
		<ion-col size="2" *ngIf="hasNominal()">
			Courante
		</ion-col>
		<ion-col size="2" *ngIf="hasNominal()">
			Nominale
		</ion-col>
		<ion-col size="2" *ngIf="displayCounter()">
			Nb
		</ion-col>
		<ion-col *ngIf="!!hasSearchButton">
			Trouver
		</ion-col>
	</ion-row>
</ion-grid>

<ion-item class="last-reading" lines="none">
	<ion-label>Dernière lecture</ion-label>
	<ion-label slot="end" class="reading-number">{{ lastScan }}</ion-label>
</ion-item>

<ng-container *ngTemplateOutlet="acquisitions.length === 0 && !hasNominal() ? noAcqContent : acqContent"></ng-container>

<ng-template #noAcqContent>
	<ion-item lines="none">Aucune acquisition</ion-item>
</ng-template>

<ng-template #acqContent>
	<ion-label class="reading-list">Liste des articles lus</ion-label>

	<!-- Tableau qui affiche toutes les données. -->
	<ng-container *ngIf="!hasSpecificSummaryComponent()">
		<ng-container *ngIf="getAcquisitions() as acqs">
			<cdk-virtual-scroll-viewport [style.height]="(98 * acqs.length) + 'px'" itemSize="98">
				<div *cdkVirtualFor="let acq of acqs;">
					<ion-row>
						<!-- Toutes les lignes du tableau. -->
						<ion-col size="4">
							<div class="epc">
								{{ codeToDisplay(acq) }}
							</div>
						</ion-col>
						<ion-col size="2" *ngIf="hasQualityAlgo()">
							<div class="percentage">
								{{ getAcquisitionQuality(acq) }}%
							</div>
						</ion-col>

						<!-- Gestion de la lecture nominale. -->
						<ng-container *ngIf="hasNominal()">
							<ion-col size="2">
								<ion-checkbox [checked]="isInCurrent(acq)" disabled="true" aria-label="Courante"></ion-checkbox>
							</ion-col>
							<ion-col size="2">
								<ion-checkbox [checked]="isInNominal(acq)" disabled="true" aria-label="Nominale"></ion-checkbox>
							</ion-col>
						</ng-container>

						<!-- Gestion du compteur. -->
						<ng-container *ngIf="displayCounter()">
							<!-- Si on doit afficher le compteur et la lecture nominale, des éléments peuvent ne pas avoir été comptés. -->
							<ng-container *ngIf="counter.get(acq) as counterValue">
								<ion-col size="2">
									<div>{{ counterValue }}</div>
								</ion-col>
							</ng-container>
						</ng-container>

						<ion-col *ngIf="!!hasSearchButton">
							<ion-button (click)="goToSearchPage(acq)" size="small">
								<ion-icon slot="icon-only" name="search"></ion-icon>
							</ion-button>
						</ion-col>
					</ion-row>
				</div>
			</cdk-virtual-scroll-viewport>
		</ng-container>
	</ng-container>
</ng-template>

<ng-container *ngIf="hasSpecificSummaryComponent()">
	<ion-list class="specific-summaries">
		<ng-container #scanArticlesSummaries>
		</ng-container>
	</ion-list>

	<ion-infinite-scroll threshold="200%" (ionInfinite)="loadNextWage()">
		<ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Chargement ...">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ng-container>