import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { IMovement } from '../../../models/i-movement';
import { StockService } from '../../../services/stock.service';

@Component({
	selector: 'calao-movements-list',
	templateUrl: './movements-list.component.html',
	styleUrls: ['./movements-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovementsListComponent {

	//#region FIELDS

	private static readonly C_LOG_ID = "MVML.C::";

	private readonly moMovementsSubject = new ReplaySubject<IMovement[]>();

	//#endregion FIELDS

	//#region PROPERTIES

	private msCustomerId: string | null | undefined = null;
	@Input("customerId") public set customerId(psNewValue: string | null | undefined) {
		this.msCustomerId = psNewValue;
		this.onSelectionChanged();
	}

	@Input({ required: true }) public itemId!: string;
	@ObserveProperty<MovementsListComponent>({ sourcePropertyKey: "itemId" })
	public readonly observableItemId = new ObservableProperty<string>(undefined, () => this.onSelectionChanged());

	public readonly movements$ = this.moMovementsSubject.asObservable();

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(
		private readonly isvcStock: StockService
	) { }

	public onSelectionChanged(): void {
		if (StringHelper.isValid(this.msCustomerId) && StringHelper.isValid(this.itemId)) {
			this.isvcStock.getMovementsFromItemIdsAsync(this.msCustomerId, [this.itemId])
				.then((paMovements: IMovement[]) => this.moMovementsSubject.next(paMovements));
		}
	}

	//#endregion METHODS

}
