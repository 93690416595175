import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ContactHelper } from '../../../helpers/contactHelper';
import { EnumHelper } from '../../../helpers/enumHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { UserData } from '../../../model/application/UserData';
import { EContactsListDisplayType } from '../../../model/contacts/EContactsListDisplayType';
import { EContactsListDisplaying } from '../../../model/contacts/EContactsListDisplaying';
import { IContact } from '../../../model/contacts/IContact';
import { IContactsListDisplaying } from '../../../model/contacts/IContactsListDisplaying';
import { IContactsListParams } from '../../../model/contacts/IContactsListParams';
import { IContactsSelectorParams } from '../../../model/contacts/IContactsSelectorParams';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupMember } from '../../../model/contacts/IGroupMember';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { IPicture } from '../../../model/picture/IPicture';
import { EntitiesService } from '../../../modules/entities/services/entities.service';
import { ISector } from '../../../modules/sectors/models/isector';
import { ESelectorDisplayMode } from '../../../modules/selector/selector/ESelectorDisplayMode';
import { ContactsService } from '../../../services/contacts.service';
import { GroupsService } from '../../../services/groups.service';

@Component({
	selector: "contacts-list",
	templateUrl: './contactsList.component.html',
	styleUrls: ['./contactsList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_DEFAULT_TITLE: string = "Liste des contacts";
	private static readonly C_DEFAULT_ADD_BUTTON_TEXT: string = "Choisir";

	/** Modèle (tableau) contenant les contacts à afficher. */
	private maModel: Array<IContact> | Array<string> = [];

	//#endregion

	//#region PROPERTIES

	/** Paramètres du composant. */
	@Input() public params: IContactsListParams;

	/** Accesseurs du modèle de la liste des contacts.
	 * ### ATTENTION : Peut ne pas être un tableau dans le cas où on limite à un seul contact/contactId.
	 * */
	@Input() public set model(paValues: IContact[] | string[] | IContact | string) {
		if (!paValues)
			paValues = [];
		if (!(paValues instanceof Array)) // Si la nouvelle valeur n'est pas un tableau alors on la transforme en tableau.
			paValues = typeof paValues === "string" ? [paValues as string] : [paValues as IContact];

		if (!ArrayHelper.areArraysEqual(paValues as string[], this.maModel as string[]))
			this.setModel(paValues).subscribe();
		else
			this.setAddButtonVisibility();
	}
	public get model(): Array<IContact> | Array<string> | IContact | string { return this.maModel; }

	public singleModel: IContact | undefined;
	public selectedContacts: IContact[] = [];

	@Output() public readonly modelChange: EventEmitter<Array<IContact> | Array<string>> = new EventEmitter();

	/** Propriétés des contacts à afficher. */
	public contactDisplayProperty: IContactsListDisplaying;
	/** Icônes des propriétés à afficher. */
	public contactDisplayIconName: IContactsListDisplaying;
	/** Indique si le bouton d'ajout de contacts doit être afficher ou non. */
	public addButtonVisible = false;
	/** Liste des contacts avec leur disponibilité et leur avatar. */
	public contactsWithAvailabilityAndAvatar: { contact: IContact, isAvailable: boolean, avatar: IAvatar }[] = [];

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcContacts: ContactsService,
		protected readonly isvcEntities: EntitiesService,
		protected readonly ioRouter: Router,
		protected readonly ioRoute: ActivatedRoute,
		protected readonly isvcGroups: GroupsService,
		ioChangeDetector: ChangeDetectorRef
	) {
		super(ioChangeDetector);
	}

	public ngOnInit(): void {
		if (!this.params)
			throw new Error("Veuillez initialiser les paramètres du composant.");

		this.initProperties();

		if (!ArrayHelper.hasElements(this.maModel as string[])) // Si le modèle n'a pas d'éléments, on l'initialise ; cast nécéssaire sinon erreur de build.
			this.maModel = [];
	}

	/** Modifie le modèle et le tableau des contacts à afficher.
	 * @param paValues Tableau des nouveaux contacts.
	 */
	protected setModel(paValues: Array<IContact> | Array<string>): Observable<IContact[]> {
		let loGetResult$: Observable<IContact[]>;

		// Si on reçoit un tableau d'identifiants de contacts.
		if (typeof ArrayHelper.getFirstElement(paValues as string[]) === "string") {
			loGetResult$ = this.isvcContacts.getContactsByIds((paValues as Array<string>))
				.pipe(
					catchError(poError => { console.error("Erreur récupération contacts dans contactsList : ", poError); return throwError(() => poError); }),
					map((paResults: IContact[]) => ArrayHelper.unique(paResults))
				);
		}
		else // Sinon, on a un tableau de contacts.
			loGetResult$ = of(paValues as Array<IContact>);

		return loGetResult$
			.pipe(
				tap((paContacts: IContact[]) => {
					paContacts = paContacts.sort((poContactA: IContact, poContactB: IContact) => ContactHelper.compareContactsByLastName(poContactA, poContactB));
					this.onModelChanged(paContacts); // Inutile de notifier l'altération des contacts à l'initialisation.
					this.setContactsWithAvailabilityAndAvatar(paContacts);
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Modifie le tableau indexé des contacts avec leur disponibilité et leur avatar.
	 * @param paContacts Tableau des contacts récupérés.
	 */
	private setContactsWithAvailabilityAndAvatar(paContacts: IContact[]): void {
		this.contactsWithAvailabilityAndAvatar = [];

		paContacts.forEach((poContact: IContact) => {
			this.contactsWithAvailabilityAndAvatar.push({
				contact: poContact,
				isAvailable: poContact !== undefined,
				avatar: poContact && this.canGenerateAvatar(poContact.picture) ? this.createAvatar(poContact.picture) : undefined
			});
		});
		this.detectChanges();
	}

	/** Initialise les propriétés dont a besoin le composant. */
	private initProperties(): void {
		if (!this.params.displayType)
			this.params.displayType = EContactsListDisplayType.normal;

		if (!this.params.contactsSelectorParams)
			this.params.contactsSelectorParams = {};

		if (StringHelper.isBlank(this.params.title) && this.params.displayType === EContactsListDisplayType.normal && this.params.hasDefaultTitle)
			this.params.title = ContactsListComponent.C_DEFAULT_TITLE;

		if (!ArrayHelper.hasElements(this.params.displayProperties))
			this.params.displayProperties = [];
		if (!ArrayHelper.hasElements(this.params.displayIcons))
			this.params.displayIcons = [];

		if (StringHelper.isBlank(this.params.addButtonIcon) && StringHelper.isBlank(this.params.addButtonText)) // Si pas d'icône et texte vide alors texte par défaut.
			this.params.addButtonText = ContactsListComponent.C_DEFAULT_ADD_BUTTON_TEXT;

		this.initDynamicPropertiesToDisplay();
	}

	/** Affecte une valeur au booléen permettant l'affichage ou non du bouton d'ajout de contacts en fonction de paramètres du composant.
	 * @param pnContacts Nombre de contacts.
	 */
	private setAddButtonVisibility(): void {
		this.addButtonVisible = !this.params.readOnly &&
			(
				this.params.contactsSelectorParams === undefined ||
				isNaN(this.params.contactsSelectorParams.selectionLimit) ||
				this.params.contactsSelectorParams.selectionLimit > this.maModel.length
			);
	}

	/** Initialise dynamiquement les propriétés du composant servant à l'affichage des données des contacts et les icônes de ces données. */
	private initDynamicPropertiesToDisplay(): void {
		const laEnumValues: Array<string> = EnumHelper.getValues(EContactsListDisplaying);
		this.contactDisplayProperty = {} as any;
		this.contactDisplayIconName = {} as any;

		laEnumValues.forEach((psProperty: string | number) => {
			this.contactDisplayProperty[psProperty] = this.params.displayProperties.findIndex((psValue: string) => psValue === psProperty) >= 0;
			this.contactDisplayIconName[psProperty] = this.params.displayIcons[this.params.displayProperties.findIndex((psValue: string) => psValue === psProperty)];
		});
	}

	/** Vérifie qu'on peut générer un avatar à partir de l'objet reçu, renvoie 'true' si on peut, 'false' sinon.
	 * @param poPicture Objet image dont il faut vérifier l'existence.
	 */
	private canGenerateAvatar(poPicture: IPicture): boolean {
		return poPicture && !(StringHelper.isBlank(poPicture.url) && StringHelper.isBlank(poPicture.base64) && StringHelper.isBlank(poPicture.guid));
	}

	/** Construit un avatar à partir d'une image. */
	private createAvatar(poPicture: IPicture): IAvatar {
		return {
			text: "",
			size: EAvatarSize.big,
			data: StringHelper.isBlank(poPicture.url) ? poPicture.base64 : poPicture.url,
			guid: poPicture.guid,
			mimeType: poPicture.mimeType,
			icon: "person"
		};
	}

	/** Ouvre le sélecteur de contacts. */
	public selectContacts(): void {
		const laOldContacts: IContact[] = this.getContactsFromContactsWithAvailability();
		const lsSiteId: string = this.params.contactsContainer?._id?.includes(EPrefix.group) ? (this.params.contactsContainer as ISector).siteId : undefined;
		const loContactsSelectorParams: IContactsSelectorParams = {
			...this.params.contactsSelectorParams,
			preSelectedIds: laOldContacts.map((poContact: IContact) => poContact._id),
			siteIds: !StringHelper.isBlank(lsSiteId) ? [lsSiteId] : undefined
		};

		let loGetContacts$: Observable<IGroupMember[]>;
		let loGetGroups$: Observable<IGroupMember[] | null> = of(null);

		if (loContactsSelectorParams.hasGroupSelector && (loContactsSelectorParams.roles?.length ?? 0) > 0) {
			loGetGroups$ = this.isvcGroups.getGroupsByRoles(loContactsSelectorParams.roles)
				.pipe(
					tap((paGroups: IGroup[]) => {
						loContactsSelectorParams.groupFilterParams = {
							options: paGroups.map((poGroup: IGroup) => ({ label: poGroup.name, value: poGroup })),
							displayMode: ESelectorDisplayMode.tags
						};
					})
				);
		};

		// Si on est en multi-workspaces OU que l'utilisateur n'a pas de bases de données de workspaces.
		if (this.params.isMultiWorkspaces || (UserData.current && !ArrayHelper.hasElements(UserData.current.workspaceInfos)))
			loGetContacts$ = this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams, this.params.pageTitle);
		else // Cas mono-workspace.
			loGetContacts$ = this.isvcContacts.openContactsSelectorAsModalWithWorkspacePreSelection(this.params.contactsContainer, loContactsSelectorParams, this.params.pageTitle);

		loGetGroups$
			.pipe(
				mergeMap(() => loGetContacts$),
				filter((paContacts: IContact[]) => !!paContacts), // Ne prend en compte que si on a validé la sélection de contacts.
				tap((paContacts: IContact[]) => {
					this.model = paContacts;
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Retourne le tableau des contacts contenus dans l'objet `contactsWithAvailability`. */
	protected getContactsFromContactsWithAvailability(): IContact[] {
		const laContacts: IContact[] = [];

		for (let lnIndex = 0; lnIndex < this.contactsWithAvailabilityAndAvatar.length; ++lnIndex) {
			laContacts.push(this.contactsWithAvailabilityAndAvatar[lnIndex].contact);
		}

		return laContacts;
	}

	/** Supprime le contact cliqué.
	 * @param psId Id du contact à supprimer.
	 */
	public remove(psId: string): void {
		ArrayHelper.removeElementByFinder(this.contactsWithAvailabilityAndAvatar, (poItem: { contact: IContact; isAvailable: boolean; avatar: IAvatar; }) => poItem.contact._id === psId);

		const laNewContacts: IContact[] = this.getContactsFromContactsWithAvailability();

		this.setAddButtonVisibility();
		this.onModelChanged(laNewContacts);

		if (this.params.chooseAfterRemove)
			this.selectContacts();
	}

	/** Met à jour le modèle et envoie un événement signalant que le modèle a changé.
	 * @param paNewContacts Nouveau tableau de contacts sélectionnés.
	 */
	private onModelChanged(paNewContacts: IContact[]): void {
		this.singleModel = ArrayHelper.getFirstElement(paNewContacts);
		this.selectedContacts = paNewContacts;
		// Si le modèle contient un tableau d'identifiants de string, on transforme le nouveau tableau de contacts en identifiants,
		// sinon c'est que le tableau contient des contacts qu'on laisse tel quel.
		this.maModel = this.params.contactsById ? paNewContacts.map((poContact: IContact) => poContact._id) : paNewContacts;

		// Suppression des cacheDatas dans le cas où on manipule un ou plusieurs IContact.
		if (!this.params.contactsById) {
			if (this.model instanceof Array)
				(this.model as IContact[]).forEach((poContact: IContact) => StoreHelper.deleteDocumentCacheData(poContact));
			else
				StoreHelper.deleteDocumentCacheData(this.model as IContact);
		}

		this.setAddButtonVisibility();
		this.modelChange.emit(this.model as Array<string> | Array<IContact>);
	}

	public route(poData: IContact): void {
		if (!this.params.disbaleRouteToContact)
			this.isvcEntities.navigateToEntityViewAsync(poData, this.ioRoute);
	}

	public getContactName(poContact: IContact | undefined): string {
		return (poContact?._id === UserHelper.getUserContactId()) ? "Moi" : ContactHelper.getCompleteFormattedName(poContact);
	}

	//#endregion

}