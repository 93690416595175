import { Injectable } from '@angular/core';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Injectable()
export class UserFeedBackService {

	//#region FIELDS

	/** Durée d'une notification par vibration en millisecondes. */
	private static readonly C_VIBRATION_DURATION_MS = 200;
	/** Durée d'une notification par vibration en millisecondes. */
	private static readonly C_ERROR_VIBRATION_DURATION_MS = 2 * UserFeedBackService.C_VIBRATION_DURATION_MS;
	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "USR.FDBCK::";

	//#endregion

	//#region METHODS

	constructor(private readonly ioVibration: Vibration) { }

	/** Élément html qui produit un bip sonore de 190.438ms (environ). */
	private getBeepInstance(): HTMLAudioElement {
		return new Audio("/assets/sounds/beep.wav");

	}

	/** Élément html qui produit un bip sonore d'erreur de 128.821ms (environ). */
	private getBeepErrorInstance(): HTMLAudioElement {
		return new Audio("/assets/sounds/error-beep.wav");
	}

	/** Notifie l'utilisateur en faisant vibrer l'appareil et en émettant un bip sonore (n'attend pas la fin de lecture du bip).
	 * #### Il faut ajouter les assets 'sounds' dans le `angular.json` de l'app sinon pas de son.
	 */
	public notify(): void {
		console.debug(`${UserFeedBackService.C_LOG_ID}Notification à l'utilisateur.`);
		this.ioVibration.vibrate(UserFeedBackService.C_VIBRATION_DURATION_MS);
		this.beep();
	}

	/** Déclenche un bip sonore (n'attends pas la fin de lecture du bip).
	 * #### Il faut ajouter les assets 'sounds' dans le `angular.json` de l'app sinon pas de son.
	 */
	public beep(): void {
		// Pour déclencher un bip à chaque appel, il faut travailler avec une instance différente à chaque fois.
		this.playSound(this.getBeepInstance());
	}

	/** Notifie l'utilisateur qu'une erreur s'est produite en faisant vibrer l'appareil et en émettant un bip sonore d'erreur (n'attend pas la fin de lecture du bip).
	 * #### Il faut ajouter les assets 'sounds' dans le `angular.json` de l'app sinon pas de son.
	 */
	public notifyError(): void {
		console.debug(`${UserFeedBackService.C_LOG_ID}Notification d'erreur à l'utilisateur.`);
		this.ioVibration.vibrate(UserFeedBackService.C_ERROR_VIBRATION_DURATION_MS);
		this.errorBeep();
	}

	/** Déclenche un bip sonore d'erreur (n'attend pas la fin de lecture du bip).
	 * #### Il faut ajouter les assets 'sounds' dans le `angular.json` de l'app sinon pas de son.
	 */
	public errorBeep(): void {
		// Pour déclencher un bip à chaque appel, il faut travailler avec une instance différente à chaque fois.
		this.playSound(this.getBeepErrorInstance());
	}

	/** Joue le son d'un élément audio.
	 * @param poAudioElement Élément audio à jouer.
	 */
	private playSound(poAudioElement: HTMLAudioElement): Promise<void> {
		poAudioElement.volume = 1; // Volume max.

		return poAudioElement.play()
			.catch(poError => console.error(`${UserFeedBackService.C_LOG_ID}error while playing sound :`, poError));
	}

	//#endregion

}