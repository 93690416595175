import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CatalogModalMainComponent } from './catalog-modal-main.component';

const components = [
	CatalogModalMainComponent,
];

@NgModule({
	declarations: components,
	imports: [
		IonicModule,
	]
})
export class CatalogModalMainModule { }
