import { Injectable } from '@angular/core';
import { AlertButton } from '@ionic/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { DateHelper } from '../../../../helpers/dateHelper';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { UserHelper } from '../../../../helpers/user.helper';
import { EPrefix } from '../../../../model/EPrefix';
import { UserData } from '../../../../model/application/UserData';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { Store } from '../../../../services/store.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { LoggerService } from '../../../logger/services/logger.service';
import { RackHelper } from '../helpers/rack.helper';
import { TaskHelper } from '../helpers/task.helper';
import { ERackStatus } from '../models/ERackStatus';
import { ETaskStatus } from '../models/ETaskStatus';
import { CancelRackError } from '../models/errors/cancel-rack-error';
import { DevalidateRackError } from '../models/errors/devalidate-rack-error';
import { ValidateError } from '../models/errors/validate-error';
import { ETaskLogActionId } from '../models/etask-log-action-id';
import { IRack } from '../models/irack';
import { IRackLogAction } from '../models/irack-log-action';
import { ITask } from '../models/itask';
import { TaskService } from './task.service';

@Injectable()
export class RackService<T extends IRack = IRack> {

	//#region FIELDS

	private static readonly C_LOG_ID = "RCK.S::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcLogger: LoggerService,
		private readonly isvcTask: TaskService,
		protected readonly isvcUiMessage: UiMessageService
	) { }

	/** Créer et retourne un nouveau portant.
	 * @param psTaskId Identifiant de la tâche du portant.
	 */
	public createRack(psTaskId: string): IRack {
		return {
			_id: RackHelper.buildRackId(psTaskId, GuidHelper.newGuid()),
			createContactId: UserHelper.getUserContactId(),
			createDate: new Date(),
			status: ERackStatus.active
		};
	}

	/** Récupère les portants associés à une tâche.
	 * @param psTaskId Identifiant du document de la tâche.
	 * @param pbLive Indique si on souhaite écouter les changements (`false` par défaut).
	 */
	public getRacksFromTaskId$(psTaskId: string, pbLive: boolean = false): Observable<T[]> {
		const lsStartId = `${EPrefix.rack}${psTaskId}`;

		return this.isvcStore.get({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			live: pbLive,
			viewParams: {
				include_docs: true,
				startkey: lsStartId,
				endkey: `${lsStartId}${Store.C_ANYTHING_CODE_ASCII}`
			},
		} as IDataSource<T>);
	}

	/** Récupère les portants associés à une tâche.
	 * @param psTaskId Tâche dont il faut récupérer les portants affiliés.
	 * @param pbLive Indique si on souhaite écouter les changements (`false` par défaut).
	 */
	public getRacksFromTask$(poTask: ITask, pbLive: boolean = false): Observable<T[]> {
		return this.getRacksFromTaskId$(poTask._id, pbLive);
	}

	/** Récupère un portant.
	 * @param psRackId Identifiant du portant.
	 * @param pbLive Indique si on souhaite écouter les changements (`false` par défaut).
	 */
	public getRack(psRackId: string, pbLive = false): Observable<T | undefined> {
		return this.isvcStore.getOne(
			{
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				live: pbLive,
				viewParams: {
					key: psRackId,
					include_docs: true
				}
			} as IDataSource<T>,
			false
		);
	}

	/** Récupère tous les portants que l'utilisateur peut voir (ceux créés par celui-ci ou ceux qui sont clôturés).
	 * @param psTaskId L'identifiant de la tâche.
	 * @param pbIsLive Ecoute les changements sur la base de données, `false` par défaut.
	 */
	public getFilteredRacksFromTaskId$(psTaskId: string, pbIsLive: boolean = false): Observable<T[]> {
		return this.getRacksFromTaskId$(psTaskId, pbIsLive)
			.pipe(
				map((paRacks: T[]) => {
					const lsContactId: string = UserHelper.getUserContactId();

					return RackHelper.sortRacksBy(
						paRacks.filter((poRack: T) => poRack.createContactId === lsContactId || poRack.status === ERackStatus.closed),
						"createDate"
					);
				})
			);
	}

	/** Récupère tous les portants que l'utilisateur peut voir (ceux créés par celui-ci ou ceux qui sont clôturés).
	 * @param poTask Tâche.
	 * @param pbIsLive Ecoute les changements sur la base de données, `false` par défaut.
	 */
	public getFilteredRacksFromTask$(poTask: ITask, pbIsLive: boolean = false): Observable<T[]> {
		return this.getFilteredRacksFromTaskId$(poTask._id, pbIsLive);
	}

	/** Enregistre plusieurs portants.
	 * @param paRacks Portants à enregistrer.
	 */
	public saveRacks(paRacks: T[]): Observable<boolean> {
		return this.isvcStore.putMultipleDocuments(paRacks).pipe(map((paResponses: IStoreDataResponse[]) => paResponses.every((poResponse: IStoreDataResponse) => poResponse.ok)));
	}

	/** Enregistre un portant.
	 * @param poRack Portant à enregistrer.
	 * @param psAppName L'identifiant de l'application. (ex: merchapp)
	 */
	public saveRack(poRack: T, psAppName: string): Observable<boolean> {
		if (!poRack._rev) // Si c'est l'enregistrement d'un nouveau portant.
			return this.saveNewRack(poRack, psAppName);
		else
			return this.isvcStore.put(poRack).pipe(map((poResponse: IStoreDataResponse) => poResponse.ok));
	}

	private saveNewRack(poNewRack: T, psAppName: string): Observable<boolean> {
		return this.isvcTask.getTask(RackHelper.getTaskIdFromRack(poNewRack._id), false)
			.pipe(
				mergeMap((poTask: ITask) => this.putNewRack(poNewRack, poTask, psAppName)),
				map((poResultData: IStoreDataResponse | IStoreDataResponse[]) => {
					if (poResultData instanceof Array) {
						if (poResultData.find((poResponse: IStoreDataResponse) => poResponse.id === poNewRack._id)?.ok)
							this.createRackLogAction(poNewRack._id);

						return poResultData.every((poResponse: IStoreDataResponse) => poResponse.ok);
					}
					else {
						if (poResultData.ok)
							this.createRackLogAction(poNewRack._id);

						return poResultData.ok;
					}
				})
			);
	}

	/** Enregistre le nouveau portant avec la tâche qui lui est associée.
	 * @param poNewRack Nouveau portant à enregistrer.
	 * @param poTask Tâche associé au portant.
	 * @param psAppName L'identifiant de l'application. (ex: merchapp)
	 */
	private putNewRack(poNewRack: T, poTask: ITask, psAppName: string): Observable<IStoreDataResponse | IStoreDataResponse[]> {
		const lsTargetDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(poTask);
		let loSaveTask$: Observable<void> = of(undefined);

		if (poTask.status !== ETaskStatus.active) { // Modification possible du statut de la tâche.
			poTask.status = ETaskStatus.active;
			if (!poTask.startDate)
				poTask.startDate = new Date();
			loSaveTask$ = this.isvcTask.saveTask(poTask, psAppName).pipe(mapTo(undefined));
		}

		return loSaveTask$.pipe(mergeMap(_ => this.isvcStore.put(poNewRack, lsTargetDatabaseId)));
	}

	/** Log l'action de création d'un portant.
	 * @param psRackId Identifiant du portant dont il faut logger l'action de création.
	 */
	private createRackLogAction(psRackId: string): void {
		this.isvcLogger.action(
			RackService.C_LOG_ID,
			`User '${UserData.current?._id}' created rack '${psRackId}'.`,
			TaskHelper.getAppActionId(ETaskLogActionId.createRack),
			{ taskDocumentId: psRackId, userId: UserData.current?._id }
		);
	}

	/** Valide un portant, le sauvegarde et génère un log d'action.
	 * @param poRack Le portant à sauvegarder.
	 * @param psAppName L'identifiant de l'application. (ex: merchapp)
	 * @throws `ValidateError` si une erreur est survenue lors de la validation.
	 */
	public validate(poRack: T, psAppName: string): Observable<true> {
		if (poRack.status === ERackStatus.closed) // Si le portant est déjà clos, pas besoin de le réenregistrer.
			return of(true);

		poRack.status = ERackStatus.closed;

		return this.saveRack(poRack, psAppName)
			.pipe(
				mergeMap((pbSave: boolean) => {
					if (pbSave) {
						this.changeRackStatusActionLog(poRack);
						return of(true) as Observable<true>;
					}
					else
						return throwError(() => `Un problème d'enregistrement est survenu lors de la validation du portant '${poRack._id}'.`);
				}),
				catchError((poError: string | any) => this.throwChangeRackStatusError(poRack, poError))
			);
	}

	/** Valide plusieurs portants, les sauvegarde et génère un log d'action.
	 * @param paRacks Les portants à sauvegarder.
	 * @param psAppName L'identifiant de l'application. (ex: merchapp)
	 * @throws `ValidateRackError` si une erreur est survenue lors de la validation.
	 */
	public async validateRacksAsync(paRacks: T[], psAppName: string): Promise<true> {
		for (let lnIndex = 0; lnIndex < paRacks.length; ++lnIndex) {
			await this.validate(paRacks[lnIndex], psAppName).toPromise();
		}

		return true;
	}

	/** Dévalide un portant et retourne le nouveau portant qui peut être modifié contrairement à celui qu'on dévalide.
	 * @param poRack Portant qu'il faut dévalider.
	 * @param poDevalidatedRack Clone du portant à dévalider qui lui est dévalidé.
	 * @throws `DevalidateRackError` si une erreur est survenue lors de la dévalidation.
	 */
	public devalidate(poRack: T, poDevalidatedRack: T): Observable<T> {
		poDevalidatedRack.createContactId = UserHelper.getUserContactId();

		poRack.status = ERackStatus.canceled;

		return this.isvcStore.putMultipleDocuments([poRack, poDevalidatedRack])
			.pipe(
				mergeMap((paResponses: IStoreDataResponse[]) => {
					if (paResponses.every((poResponse: IStoreDataResponse) => poResponse.ok)) {
						this.changeRackStatusActionLog(poRack, true);
						return of(poDevalidatedRack);
					}
					else
						return throwError(() => `Un problème d'enregistrement est survenu lors de la dévalidation du portant '${poRack._id}'.`);
				}),
				catchError((poError: string | any) => this.throwChangeRackStatusError(poRack, poError, true))
			);
	}

	/** Annule un portant.
	 * @param poRack Portant qu'il faut annuler.
	 * @param poTask Tâche associé au portant à annuler.
	 * @param psRackLabel Libellé du portant.
	 * @throws `CancelRackError` si un problème est survenu lors de l'annulation.
	 */
	public cancel(poRack: T, poTask: ITask): Observable<true> {
		return this.showCancelRackPopup(poTask)
			.pipe(mergeMap((poResult: IUiResponse<boolean>) => poResult.response ? this.execCancel(poRack) : EMPTY));
	}

	private showCancelRackPopup(poTask: ITask): Observable<IUiResponse<boolean>> {
		const lsRackLabel: string = this.getRackLabel(poTask);
		const lsTaskTypeLabel: string = this.isvcTask.getTaskTypeLabel(poTask, { hasPreposition: true });

		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: `Annuler le ${lsRackLabel} ?`,
				message: `Souhaitez-vous annuler ce ${lsRackLabel} ${lsTaskTypeLabel} ?`,
				buttons: [
					{ text: "Ne rien faire", handler: () => UiMessageService.getFalsyResponse(), cssClass: "cancel-btn" } as AlertButton,
					{ text: "Annuler", handler: () => UiMessageService.getTruthyResponse(), cssClass: "devalidate-btn" } as AlertButton
				],
				cssClass: "cancel-rack-popup"
			})
		);
	}

	/** Annule un portant, l'enregistre et génère l'action.
	 * @param poRack Portant à annuler
	 * @throws `CancelRackError` si une erreur est survenue.
	 */
	private execCancel(poRack: T): Observable<true> {
		poRack.status = ERackStatus.canceled;

		return this.isvcStore.put(poRack)
			.pipe(
				mergeMap((poResponse: IStoreDataResponse) => {
					if (poResponse.ok) {
						this.changeRackStatusActionLog(poRack);
						return of(true) as Observable<true>;
					}
					else
						return throwError(() => `Un problème d'enregistrement est survenu lors de l'annulation du portant '${poRack._id}'.`);
				}),
				catchError((poError: string | any) => this.throwChangeRackStatusError(poRack, poError))
			);
	}

	/** Crée un log d'action suite au chagement de statut d'un portant.
	 * @param poRack Portant dont le statut a été modifié.
	 * @param pbIsDevalidate Précise si le portant en question est un portant dévalidé.
	 */
	private changeRackStatusActionLog(poRack: T, pbIsDevalidate?: boolean): void {
		let lsAction: string;
		let leActionId: ETaskLogActionId;

		if (poRack.status === ERackStatus.closed) {
			lsAction = "validé";
			leActionId = ETaskLogActionId.validateRack;
		}
		else if (pbIsDevalidate) {
			lsAction = "dévalidé";
			leActionId = ETaskLogActionId.devalidateRack;
		}
		else {
			lsAction = "annulé";
			leActionId = ETaskLogActionId.cancelRack;
		}

		this.isvcLogger.action(
			RackService.C_LOG_ID,
			`Le portant '${poRack._id}' a été ${lsAction} par '${UserHelper.getUserContactId()}'`,
			TaskHelper.getAppActionId(leActionId),
			{ rackDocumentId: poRack._id, userId: UserData.current?._id } as IRackLogAction
		);
	}

	/** Retourne l'erreur survenue lors d'une action sur le portant :
	 * - validation -> `ValidateError`
	 * - dévalidation -> `DevalidateRackError`
	 * - annulation -> `CancelRackError`
	 * @param poRack Portant qui devait être validé/dévalidé ou annulé.
	 * @param poError Erreur survenue.
	 * @param pbIsDevalidate Précise si le portant en question est un portant dévalidé.
	 */
	private throwChangeRackStatusError(poRack: T, poError: string | any, pbIdDevalidate?: boolean): Observable<never> {
		let loError: ValidateError | DevalidateRackError | CancelRackError;
		let lsStringBaseError: string;
		let lsStringSuffixError: string | undefined;
		let lsAction: string;

		if (typeof poError === "string")
			lsStringBaseError = poError;
		else {
			lsStringBaseError = "Un problème est survenu lors de";
			lsStringSuffixError = `du portant '${poRack._id}'.`;
		}

		if (poRack.status === ERackStatus.closed) {
			loError = new ValidateError(lsStringSuffixError ? `${lsStringBaseError} la validation ${lsStringSuffixError}` : poError);
			lsAction = "Validating";
		}
		else if (pbIdDevalidate) {
			loError = new DevalidateRackError(lsStringSuffixError ? `${lsStringBaseError} la dévalidation ${lsStringSuffixError}` : poError);
			lsAction = "Devalidating";
		}
		else {
			loError = new CancelRackError(lsStringSuffixError ? `${lsStringBaseError} l'annulation ${lsStringSuffixError}` : poError);
			lsAction = "Canceling";
		}

		console.error(`${RackService.C_LOG_ID}${lsAction} error in rack '${poRack._id}':`, poError);

		return throwError(() => loError);
	}

	/** Retourne la chaîne du dernier signataire ou `undefined`.
	 * @param paRacks Portants à filtrer.
	 */
	public getLatestSignatory(paRacks: T[]): string | undefined {
		const loLatestRackWithSignatory: T | undefined = ArrayHelper.getLastElement(
			DateHelper.sortByDate(
				paRacks.filter((poRack: IRack) => poRack?.validation?.signature.signatory && poRack?.validation?.signature.date),
				(poRack: IRack) => poRack?.validation?.signature.date
			));

		return loLatestRackWithSignatory?.validation?.signature.signatory ?? undefined;
	}

	/** Récupère le libellé d'un portant.
	 * @param poTask Tâche où récupérer le libellé.
	 */
	public getRackLabel<TaskType extends ITask>(poTask: TaskType): string {
		return poTask.display?.rackLabel ?? poTask.rackLabel ?? "portant";
	}

	/** Récupère le libellé des portants.
	 * @param poTask Tâche où récupérer le libellé.
	 */
	public getRacksLabel<TaskType extends ITask>(poTask: TaskType): string {
		return poTask.display?.racksLabel ?? poTask.racksLabel ?? "portants";
	}

	/** Retourne l'index du portant dans le tableau de portants, `-1` si le portant n'est pas trouvé.
	 * @param paRacks Tableau de portants où chercher l'index.
	 * @param psRackId Identifiant du portant à rechercher dans le tableau.
	 */
	public getRackIndex(paRacks: IRack[], psRackId: string): number {
		return paRacks.findIndex((poRack: IRack) => poRack._id === psRackId);
	}

	//#endregion

}