import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { EInlineFieldLayoutSeparator } from './models/einline-field-layout-separator';

@Component({
	selector: 'calao-inline-field-layout',
	templateUrl: './inline-field-layout.component.html',
	styleUrls: ['./inline-field-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineFieldLayoutComponent {

	//#region FIELDS

	/** Evènement lors du clic sur le champs. */
	@Output("onFieldClicked") private readonly moFieldClickedEvent = new EventEmitter<Event>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Libellé du champs. */
	@Input() public label: string;
	@ObserveProperty<InlineFieldLayoutComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	/** Icône du champs. Par défaut "box". */
	@Input() public icon?: string;
	@ObserveProperty<InlineFieldLayoutComponent>({ sourcePropertyKey: "icon" })
	public readonly observableIcon = new ObservableProperty<string>("box");

	/** Masque le libellé si `true`. Est à `false` par défaut. */
	@Input() public hideLabel?: boolean;
	@ObserveProperty<InlineFieldLayoutComponent>({ sourcePropertyKey: "hideLabel" })
	public readonly observableHideLabel = new ObservableProperty<boolean>(false);

	/** Style de séparateur à appliquer au champs. Par défaut "none". */
	@Input() public separator?: EInlineFieldLayoutSeparator;
	@ObserveProperty<InlineFieldLayoutComponent>({ sourcePropertyKey: "separator" })
	public readonly observableSeparator = new ObservableProperty<EInlineFieldLayoutSeparator>(EInlineFieldLayoutSeparator.none);

	//#endregion PROPERTIES

	//#region METHODS

	constructor() { }

	public raiseFieldClicked(poEvent: Event): void {
		this.moFieldClickedEvent.emit(poEvent);
	}

	//#endregion METHODS

}
