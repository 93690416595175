import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DmsModule } from '@calaosoft/osapp/modules/dms/dms.module';
import { CatalogItemFinderModule } from '@calaosoft/osapp/modules/logistics/catalog/catalog-item-finder/catalog-item-finder.module';
import { ModalModule } from '@calaosoft/osapp/modules/modal/modal.module';
import { TitledInputModule } from '@calaosoft/osapp/modules/titled-input/titled-input.module';
import { IonicModule } from '@ionic/angular';
import { CatalogModalComponent } from './catalog-modal-search.component';

const components = [
	CatalogModalComponent,
];

@NgModule({
	declarations: components,
	imports: [
		CatalogItemFinderModule,
		IonicModule,
		ModalModule,
		TitledInputModule,
		FormsModule,
		DmsModule
	]
})
export class CatalogModalSearchModule { }
