import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { catchError, EMPTY, finalize, firstValueFrom, forkJoin, from, map, mapTo, mergeMap, Observable, of, take, takeUntil, tap } from 'rxjs';
import { SearchComponent } from '../../../../components/search/search.component';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ContactHelper } from '../../../../helpers/contactHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { UserHelper } from '../../../../helpers/user.helper';
import { UserData } from '../../../../model/application/UserData';
import { EContactsType } from '../../../../model/contacts/EContactsType';
import { IContact } from '../../../../model/contacts/IContact';
import { IGetGroupMembersOptions } from '../../../../model/contacts/IGetGroupMembersOptions';
import { IGroup } from '../../../../model/contacts/IGroup';
import { IGroupMember } from '../../../../model/contacts/IGroupMember';
import { EPrefix } from '../../../../model/EPrefix';
import { IIndexedArray } from '../../../../model/IIndexedArray';
import { ISearchOptions } from '../../../../model/search/ISearchOptions';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ApplicationService } from '../../../../services/application.service';
import { ContactsService } from '../../../../services/contacts.service';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { GroupsService } from '../../../../services/groups.service';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '../../../../services/loading.service';
import { PlatformService } from '../../../../services/platform.service';
import { IApplicationRole } from '../../../../services/security/IApplicationRole';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { NotImplementedError } from '../../../errors/model/not-implemented-error';
import { IListDefinitionsField } from '../../../forms/models/IListDefinitionsField';
import { Loader } from '../../../loading/Loader';
import { ModalComponentBase } from '../../../modal';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { HasPermissions } from '../../../permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../permissions/models/epermission-scopes';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { ISite } from '../../../sites/models/isite';
import { Site } from '../../../sites/models/site';
import { SitesService } from '../../../sites/services/sites.service';
import { Contact } from '../../models/contact';
import { IContactSelection } from '../../models/contacts-picker/icontact-selection';
import { IContactsPickerParams } from '../../models/contacts-picker/icontacts-picker-params';
import { IDataSelection } from '../../models/contacts-picker/idata-selection';
import { IGroupSelection } from '../../models/contacts-picker/igroup-selection';
import { EContactsPickerSort } from './econtacts-picker-sort';

interface IContactsAvailability<T extends IContact = IContact> {
	availableContacts: T[];
	unavailableContactIds: string[];
}

@Component({
	selector: 'calao-contacts-picker-modal',
	templateUrl: './contacts-picker-modal.component.html',
	styleUrls: ['./contacts-picker-modal.component.scss'],
})
export class ContactsPickerModalComponent<T extends Contact = Contact> extends ModalComponentBase<T[]> {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONTPICKR.C::";

	private maContactsByGroupId = new Map<string, T[]>();
	private maSelectedGroupFilters: IGroup[];
	private maOldSelectedContacts: IContactSelection<T>[];
	private maOldSelectedGroups: IGroupSelection<T>[];

	/** Index de l'onglet actif. */
	private readonly moObservableActiveIndex = new ObservableProperty<number>(0);

	//#endregion

	//#region PROPERTIES

	public permissionScope: EPermissionScopes[] = [EPermissionScopes.contacts];
	public readonly infinity = Infinity;

	/** Paramètres du composant. */
	public params: IContactsPickerParams;

	public title?: string;
	@ObserveProperty<ContactsPickerModalComponent>({ sourcePropertyKey: "title" })
	public readonly observableTitle = new ObservableProperty<string>();

	/** Indique si des résultats ont été trouvés ou non pendant la recherche. */
	public hasSearchResult: boolean;
	/** Tableau des données de sélection (contacts et/ou groupes). */
	public dataSelections: IDataSelection[] = [];
	/** Tableau des données de sélection de contacts filtrées. */
	public filteredContactDataSelections: IContactSelection<T>[] = [];
	/** Tableau des données de sélection de groupes filtrées. */
	public filteredGroupDataSelections: IGroupSelection<T>[] = [];
	/** Indique si il est possible de séléctionner plus de contacts. */
	public canSelectMore = true;
	/** Indique si le type de contacts à sélectionner est 'contacts'. */
	public isContactsType: boolean;
	/** Indique si le type de contacts à sélectionner est 'groups'. */
	public isGroupsType: boolean;
	/** Indique si le diviseur des groupes est étendu ou non. */
	public isGroupsDividerExpanded = false;
	/** Indique si le diviseur des contacts est étendu ou non. */
	public isContactsDividerExpanded = true;
	/** Contact de l'utilisateur. */
	public userContact: IContactSelection;
	/** Tableau des données de sélection de contacts. */
	public contactSelections: IContactSelection<T>[] = [];
	/** Tableau des données de sélection de groupes. */
	public groupSelections: IGroupSelection<T>[] = [];
	public groupSearchOptions?: ISearchOptions<IGroupSelection<T>>;
	/** Indique si tous les groupes sont sélectionnés ou non. */
	public areAllGroupsSelected: boolean;
	/** Indique si tous les contacts sont sélectionnés ou non. */
	public areAllContactsSelected: boolean;
	/** Identifiant utilisé pour indiquer au radio-group quel groupe est sélectionné. */
	public selectedGroupId?: string;
	/** Identifiant utilisé pour indiquer au radio-group quel contact est sélectionné. */
	public selectedContactId?: string;
	/** Indique si l'adresse mail doit être affcihée. */
	public showMail = false;
	/** Options de filtrage des groupes. */
	public groupsSelectOptions: ISelectOption[];
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	@HasPermissions({ permission: "create" })
	public get showCreateContact(): boolean { return this.params.showCreateContact ?? true; }

	@HasPermissions({ permission: "create" })
	public get showCreateGroup(): boolean { return this.params.showCreateGroup ?? true; }

	public get canValidate(): boolean {
		let lnSelectedLength: number;
		if (!this.params.excludeCurrentUser)
			lnSelectedLength = this.dataSelections.filter((poData: IDataSelection) => poData.isSelected).length;
		else {
			const lsCurrentUserId: string = UserHelper.getUserContactId();
			lnSelectedLength = this.dataSelections.filter((poData: IDataSelection) => poData.isSelected && poData._id !== lsCurrentUserId).length;
		}

		return lnSelectedLength >= this.params.min! && lnSelectedLength <= this.params.max!;
	}

	/** Retourne `true` si le composant doit afficher des slides, `false` sinon. */
	public get hasSlides(): boolean { return this.isContactsType && this.isGroupsType; }

	/** Retourne `true` si le composant doit afficher les fabButtons liés aux contacts, `false` sinon. */
	public get hasContactFabButtons(): boolean {
		return this.hasSlides ? this.moObservableActiveIndex.value === 0 : this.isContactsType; // Index 0 pour les contacts.
	}

	/** Retourne `true` si le composant doit afficher les fabButtons liés aux groupes, `false` sinon. */
	public get hasGroupFabButtons(): boolean {
		return this.hasSlides ? this.moObservableActiveIndex.value === 1 : this.isGroupsType; // Index 1 pour les groupes.
	}

	/** Retourne `true` si tous les contacts sont désactivés, `false` sinon. */
	public get areAllContactsSelectionDisabled(): boolean { return this.contactSelections.every((poItem: IContactSelection<T>) => poItem.isDisabled); }

	/** Retourne `true` si tous les groupes sont désactivés, `false` sinon. */
	public get areAllGroupsSelectionDisabled(): boolean { return this.groupSelections.every((poItem: IGroupSelection<T>) => poItem.isDisabled); }

	/** Retoune `true` si le bouton de sélection de tous les participants est masqué. */
	public get hideAllSelectionButton(): boolean | undefined { return this.params.hideAllSelectionButton; }

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		/** Service de gestion des loaders. */
		private readonly isvcLoading: LoadingService,
		protected readonly isvcUiMessage: UiMessageService,
		private readonly isvcSites: SitesService,
		private isvcEntityLink: EntityLinkService,
		public readonly isvcPermissions: PermissionsService,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcPlatform: PlatformService,
		poModalCtrl: ModalController
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.initParams();
		this.loadData();
	}

	private initParams(): void {
		if (!this.params)
			this.params = {};

		if (!NumberHelper.isValidPositive(this.params.min))
			this.params.min = 0;

		if (!NumberHelper.isValidPositive(this.params.max))
			this.params.max = Infinity;

		if (this.params.type !== EContactsType.contactsAndGroups && this.params.type !== EContactsType.groups)
			this.params.type = EContactsType.contacts;

		this.isContactsType = this.params.type === EContactsType.contacts || this.params.type === EContactsType.contactsAndGroups;
		this.isGroupsType = this.params.type === EContactsType.groups || this.params.type === EContactsType.contactsAndGroups;

		if (this.isContactsType) {
			this.isContactsDividerExpanded = true;
			this.isGroupsDividerExpanded = false;
		}
		else {
			this.isContactsDividerExpanded = false;
			this.isGroupsDividerExpanded = true;
		}

		if (this.params.excludeCurrentUser !== true)
			this.params.excludeCurrentUser = false;

		if (!this.params.preSelectedIds)
			this.params.preSelectedIds = [];

		if (!this.params.contactsData)
			this.params.contactsData = [];

		if (this.params.groupFilterParams?.preselectedValues)
			this.maSelectedGroupFilters = coerceArray(this.params.groupFilterParams.preselectedValues);

		this.params.allSelectionButton = this.params.allSelectionButton ?? true;

		if (this.params.permissionContactPath)
			this.permissionScope = (Array.isArray(this.params.permissionContactPath)) ? this.params.permissionContactPath : [this.params.permissionContactPath];

		if (this.params.permissionGroupPath)
			this.permissionScope = (Array.isArray(this.params.permissionGroupPath)) ? this.params.permissionGroupPath : [this.params.permissionGroupPath];

		if (!this.params.sort)
			this.params.sort = EContactsPickerSort.alphabetical;

		this.prepareSearchOptions();
	}

	public loadData(): void {
		let loLoader: Loader;

		from(this.isvcLoading.create(ApplicationService.C_LOAD_DATA_LOADER_TEXT))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.init()),
				finalize(() => loLoader.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Prépare les options pour le composant 'Search'. */
	private prepareSearchOptions(): void {
		if (!this.params.searchOptions)
			this.params.searchOptions = {};

		this.params.searchOptions = {
			hasPreFillData: this.params.searchOptions.hasPreFillData !== undefined ? this.params.searchOptions.hasPreFillData : true,
			isSearchPanelEnable: this.params.searchOptions.isSearchPanelEnable,
			locationSearch: this.params.searchOptions.locationSearch,
			maxFilteredEntries: this.params.searchOptions.maxFilteredEntries,
			searchboxPlaceholder: !StringHelper.isBlank(this.params.searchOptions.searchboxPlaceholder) ?
				this.params.searchOptions.searchboxPlaceholder : "Rechercher un contact",
			...this.params.searchOptions
		};

		this.groupSearchOptions = {
			searchboxPlaceholder: "Rechercher un groupe"
		};

		this.groupsSelectOptions = this.isvcPermissions.getPermissionRoles(undefined, GroupsService.C_EXCLUDE_ROLE_IDS).map((poRole: IApplicationRole) => ({ label: poRole.label, value: poRole }));

		this.innerPrepareSearchOptions_searchFunction();
	}

	private innerPrepareSearchOptions_searchFunction(): void {
		const laSearchableFields: IListDefinitionsField<T & IGroup>[] | undefined = this.innerPrepareSearchOptions_getSearchableFields();

		if (laSearchableFields) {
			// Si la fonction de recherche n'est pas renseignée, il faut remplir les champs recherchables et peut-être créer une fonction de recherche.
			if (this.params.searchOptions && !this.params.searchOptions.searchFunction) {
				this.params.searchOptions.searchableFields = []; // On vide le tableau des champs recherchables pour utiliser une recherche personnalisée.

				this.params.searchOptions.searchFunction = (poEntry: IContactSelection<T>, psSearchValue: string) =>
					laSearchableFields.some((poField: IListDefinitionsField) => this.groupMemberMatch(poEntry.data, poField, psSearchValue));
			}
			else
				console.info(`${ContactsPickerModalComponent.C_LOG_ID}Une fonction de recherche a été passée en paramètre, attention au typage nécessaire pour fonctionner.`);
			if (this.groupSearchOptions) {
				this.groupSearchOptions.searchFunction = (poEntry: IGroupSelection<T>, psSearchValue: string) => {
					return laSearchableFields.some((poField: IListDefinitionsField) => {
						return this.groupMemberMatch(poEntry.data, poField, psSearchValue) ||
							poEntry.members.some((poMember: T) => this.groupMemberMatch(poMember, poField, psSearchValue));
					});
				};
			}
		}
		else
			console.error(`${ContactsPickerModalComponent.C_LOG_ID}No searchable fields in form !`);
	}

	private groupMemberMatch(poData: IGroupMember, poField: IListDefinitionsField, psSearchValue: string): boolean {
		psSearchValue = psSearchValue.toLowerCase();

		if (poData[poField.key] &&
			((typeof poData[poField.key] === "string" && (poData[poField.key] as string).toLowerCase().indexOf(psSearchValue) >= 0) ||
				(typeof poData[poField.key] === "number" && (poData[poField.key]!).toString().indexOf(psSearchValue) >= 0))) {
			return true;
		}
		else
			return false;
	}

	private innerPrepareSearchOptions_getSearchableFields(): IListDefinitionsField<T & IGroup>[] | undefined {
		let laSearchableFields: IListDefinitionsField<T & IGroup>[] | undefined;

		if (ArrayHelper.hasElements(this.params.searchOptions?.searchableFields))
			laSearchableFields = this.params.searchOptions!.searchableFields;
		else {
			const laSearchContactsKeys: IListDefinitionsField<T>[] = [{ key: "firstName" }, { key: "lastName" }, { key: "displayName" }];
			const laSearchGroupsKeys: IListDefinitionsField<IGroup>[] = [{ key: "name" }, { key: "description" }];

			if (this.isContactsType && this.isGroupsType) // Cas contacts ET groupes.
				laSearchableFields = [...laSearchContactsKeys, ...laSearchGroupsKeys];
			else if (this.isContactsType) // Cas contacts uniquement.
				laSearchableFields = laSearchContactsKeys;
			else // Cas groupes uniquement.
				laSearchableFields = laSearchGroupsKeys;
		}

		return laSearchableFields;
	}

	/** Initialise les contacts du sélecteur en récupérant tous les contacts et en précochant ceux nécessaires. */
	private init(): Observable<boolean> {
		let loGet$: Observable<IDataSelection[]>;

		if (this.isContactsType && (this.isGroupsType || this.params.groupFilterParams)) { // Cas contacts et groupes.
			loGet$ = forkJoin([this.getContacts(), this.getGroups()])
				.pipe(map((paResults: [IContactSelection<T>[], IGroupSelection<T>[]]) => ArrayHelper.flat(paResults as IDataSelection[][])));
		}
		else if (this.isContactsType) // Cas contacts uniquement.
			loGet$ = this.getContacts();
		else // Cas groupes uniquement.
			loGet$ = this.getGroups();

		return loGet$
			.pipe(
				tap((paResults: IDataSelection[]) => {
					this.initData(paResults, this.params.preSelectedIds, false);
					this.onFilteredContactsChanged(this.contactSelections);
					this.onFilteredGroupsChanged(this.groupSelections);
				}),
				mapTo(true)
			);
	}

	private getContacts(): Observable<IContactSelection<T>[]> {
		return this.getContactsObservable()
			.pipe(
				catchError(poError => { console.error(`${ContactsPickerModalComponent.C_LOG_ID}Erreur récupération contacts :`, poError); return EMPTY; }),
				map((paContacts: T[]) => {
					paContacts = ArrayHelper.unique(paContacts);
					if (this.params.excludeNonUsers)
						paContacts = paContacts.filter((poContact: T) => !StringHelper.isBlank(poContact.userId));
					if (this.params.excludeUsers)
						paContacts = paContacts.filter((poContact: T) => StringHelper.isBlank(poContact.userId));

					return paContacts;
				}),
				mergeMap((paContacts: T[]) => this.getContactSelections(paContacts)),
				tap((paSelections: IContactSelection<T>[]) => this.contactSelections = Array.from(paSelections)),
				take(1)
			);
	}

	private getContactsObservable(): Observable<T[]> {
		if (ArrayHelper.hasElements(this.params.contactsData))
			return of(this.params.contactsData as any as T[]);

		else if (ArrayHelper.hasElements(this.params.groupIds)) {
			return this.isvcGroups.getGroupContactsIds(this.params.groupIds)
				.pipe(mergeMap((paContactIdsByGroupId: IIndexedArray<string[]>) => this.getContactsFromGroupIds(paContactIdsByGroupId)));
		}

		else if (ArrayHelper.hasElements(this.params.roles)) {
			return this.isvcGroups.getGroupsByRoles(this.params.roles)
				.pipe(
					mergeMap((paGroups: IGroup[]) => this.isvcGroups.getGroupContactsIds(paGroups)),
					mergeMap((paContactIdsByGroupId: IIndexedArray<string[]>) => this.getContactsFromGroupIds(paContactIdsByGroupId))
				);
		}

		else if (this.params.filters?.linkedTo)
			return this.isvcEntityLink.getLinkedEntities(this.params.filters.linkedTo.entityId, EPrefix.contact, this.params.filters.linkedTo.types.contact);

		else {
			return this.isvcContacts.getContactsByPrefix<T>({
				prefix: this.params.prefix,
				databaseId: this.params.databaseId,
				live: true
			} as IGetGroupMembersOptions);
		}
	}

	private getContactSelections(paContacts: T[]): Observable<IContactSelection<T>[]> {
		return this.isvcSites.getContactsSites(paContacts).pipe(
			map((poSitesByContactIds: Map<string, Site[]>) => {
				const laSelections: IContactSelection<T>[] = [];

				for (let lnIndex = 0; lnIndex < paContacts.length; lnIndex++) {
					const loSelection: IContactSelection<T> = this.contactToContactSelection(paContacts[lnIndex]);
					let lbAddSelection = true;

					if (this.params.showLinkedSites || ArrayHelper.hasElements(this.params.siteIds)) {
						const laSites: ISite[] | undefined = poSitesByContactIds.get(loSelection._id);

						if (this.params.showLinkedSites)
							loSelection.details = laSites?.map((poSite: ISite) => poSite.name).join(", ");

						if (ArrayHelper.hasElements(this.params.siteIds))
							lbAddSelection = laSites ? ArrayHelper.intersection(laSites?.map((poSite: ISite) => poSite._id), this.params.siteIds)?.length > 0 : false;
					}

					if (lbAddSelection)
						laSelections.push(loSelection);
				}

				return laSelections;
			})
		);
	}

	private getContactsFromGroupIds(paContactIdsByGroupId: IIndexedArray<string[]>): Observable<T[]> {
		const loContactsAvailability: IContactsAvailability<T> = this.getContactsAvailability(paContactIdsByGroupId);
		let loGetContacts$: Observable<T[]>;

		if (ArrayHelper.hasElements(loContactsAvailability.unavailableContactIds))
			loGetContacts$ = this.isvcContacts.getContactsByIds(loContactsAvailability.unavailableContactIds);
		else
			loGetContacts$ = of([]);

		return loGetContacts$.pipe(map((paGetContacts: T[]) => paGetContacts.concat(loContactsAvailability.availableContacts)));
	}

	private getContactsAvailability(paContactIdsByGroupId: IIndexedArray<string[]>): IContactsAvailability<T> {
		const loResult: IContactsAvailability<T> = {
			availableContacts: [],
			unavailableContactIds: []
		};
		// Récupération de tous les identifiants de contacts depuis l'objet 'poContacts' et du tableau 'this.params.contactsData', sans doublon.
		const laUniqueContactIds: string[] =
			ArrayHelper.unique(
				ArrayHelper.flat(
					Object.values(paContactIdsByGroupId)).concat((this.params.contactsData as T[]).map((poContact: T) => poContact._id)
					)
			);

		// Tri des contacts déjà présents et de ceux à récupérer.
		if (ArrayHelper.hasElements(this.params.contactsData)) {
			laUniqueContactIds.forEach((psId: string) => {
				const loContact: IContact | undefined = this.params.contactsData?.find((poContact: T) => poContact._id === psId);
				// Si le contact est défini, c'est qu'on a déjà ses données ; sinon il faut l'ajouter au tableau des contacts à récupérer en base de données.
				loContact ? loResult.availableContacts.push(loContact as T) : loResult.unavailableContactIds.push(psId);
			});
		}
		else
			loResult.unavailableContactIds.push(...laUniqueContactIds);

		return loResult;
	}

	private contactToContactSelection(poContact: T, pbIsSelected: boolean = false): IContactSelection<T> {
		return {
			_id: poContact._id,
			data: poContact,
			isSelected: pbIsSelected,
			isContactData: true,
			isDisabled: this.params.disableItemFunction ? this.params.disableItemFunction(poContact) : false
		} as IContactSelection<T>;
	}

	private getGroups(): Observable<IGroupSelection<T>[]> {
		let laGroups$: Observable<IGroup[] | undefined>;

		if (ArrayHelper.hasElements(this.params.groupsData))
			laGroups$ = of(this.params.groupsData);
		else if (ArrayHelper.hasElements(this.params.groupIds))
			laGroups$ = this.isvcGroups.getDisplayableGroups(this.params.groupIds);
		else if (ArrayHelper.hasElements(this.params.roles))
			laGroups$ = this.isvcGroups.getGroupsByRoles(this.params.roles);
		else
			laGroups$ = this.isvcGroups.getDisplayableGroups();

		return laGroups$
			.pipe(
				catchError(poError => { console.error(`${ContactsPickerModalComponent.C_LOG_ID}Erreur récupération groupes :`, poError); return EMPTY; }),
				mergeMap((paGroups: IGroup[]) => this.isvcGroups.getGroupContacts(
					paGroups.concat(this.params.groupFilterParams?.options?.map((poOption: ISelectOption<IGroup>) => poOption.value) ?? []), // On ajoute les groupes du filtrage pour les filtrer par la suite.
					this.params.prefix ? coerceArray(this.params.prefix) : undefined
				)
					.pipe(
						tap((paContactsByGroupId: Map<string, T[]>) => this.maContactsByGroupId = paContactsByGroupId),
						map((paContactsByGroupId: Map<string, T[]>) => {
							let laGroups: IGroup[];

							if (ArrayHelper.hasElements(this.params.roles))
								laGroups = paGroups.filter((poGroup: IGroup) => this.params.roles?.every((psRole: string) => this.isvcGroups.hasRole(poGroup, psRole)));
							else
								laGroups = paGroups;

							return laGroups.map((poGroup: IGroup) => this.groupToGroupSelection(poGroup, paContactsByGroupId));
						})
					)),
				tap((paSelections: IGroupSelection<T>[]) => this.groupSelections = Array.from(paSelections)),
				take(1)
			);
	}

	private groupToGroupSelection(poGroup: IGroup, paContactsByGroupId: Map<string, T[]>): IGroupSelection<T> {
		const laValidGroupMembers: T[] = [];
		const laDisabledGroupMembers: T[] = [];

		const laMembers: T[] | undefined = paContactsByGroupId.get(poGroup._id);
		if (laMembers) {
			if (this.params.disableItemFunction) {
				laMembers.forEach((poContact: T) => {
					if (this.params.disableItemFunction && !this.params.disableItemFunction(poContact))
						laValidGroupMembers.push(poContact);
					else
						laDisabledGroupMembers.push(poContact);
				});
			}
			else
				laValidGroupMembers.push(...laMembers);
		}

		return {
			_id: poGroup._id,
			data: poGroup,
			members: laValidGroupMembers,
			disabledMembers: laDisabledGroupMembers,
			isSelected: false,
			isContactData: false,
			isDisabled: laValidGroupMembers.length === 0 && !this.params.preSelectedIds?.includes(poGroup._id)
		} as IGroupSelection<T>;
	}

	/** Initialise les données sélectionnables et modifie `paDataSelections` si besoin pour ne contenir que des objets `DataSelection`.
	 * @param paDataSelections Tableau des données sélectionnables (contacts et groupes) qui peut contenir des objets contact ou groupe (via création).
	 * @param paPreSelectionIds Tableau des identifiants de données qui doivent déjà être présélectionnées.
	 * @param pbCheckDataSelectionsIntegrity Indique si on doit vérifier l'intégrité des données (on veut uniquement du type 'DataSelection'), `true` par défaut.
	 */
	private initData(paDataSelections: IDataSelection[], paPreSelectionIds?: string[], pbCheckDataSelectionsIntegrity: boolean = true): void {
		if (pbCheckDataSelectionsIntegrity) {
			this.contactSelections = [];
			this.groupSelections = [];

			paDataSelections.forEach((poItem: IDataSelection) => {
				poItem.isSelected = paPreSelectionIds?.some((psId: string) => psId === poItem.data._id);
				poItem.isContactData ? this.contactSelections.push(poItem as IContactSelection<T>) : this.groupSelections.push(poItem as IGroupSelection<T>);
			});
		}
		else
			this.selectedMembersSettings(paDataSelections, paPreSelectionIds);

		this.dataSelections = paDataSelections;
		this.setOldContactsAndGroupsSelected();
	}

	/** Mets 'isSelected' et 'isGroupMember' à 'true', aux membres des groupes sélectionnés.
	 * @param paDataSelections Tableau des données sélectionnables (contacts et groupes).
	 * @param paPreSelectionIds Tableau des identifiants de données qui doivent déjà être présélectionnées.
	 */
	private selectedMembersSettings(paDataSelections: IDataSelection[], paPreSelectionIds?: string[]): void {
		if (ArrayHelper.hasElements(paPreSelectionIds)) // Pré-sélectionne les contacts et les groupes.
			paDataSelections.forEach((poItem: IDataSelection) => poItem.isSelected = paPreSelectionIds.some((psId: string) => psId === poItem.data._id));

		const laSelectedGroups: IGroupSelection[] = paDataSelections.filter((poItem: IDataSelection) => GroupsService.isGroup(poItem._id) && poItem.isSelected) as IGroupSelection[];
		const laAllContacts: IContactSelection[] = paDataSelections.filter((poItem: IDataSelection) => ContactsService.isContact(poItem._id)) as IContactSelection[];
		const laSelectedContactGroupMembers: IContactSelection[] = this.getSelectedMembers(laSelectedGroups, laAllContacts) as IContactSelection[];
		const laSelectedDisabledContactGroupMembers: IContactSelection[] = this.getSelectedDisabledMembers(laSelectedGroups, laAllContacts) as IContactSelection[];

		[...laSelectedContactGroupMembers, ...laSelectedDisabledContactGroupMembers]
			.forEach((poContact: IContactSelection<T>) => {
				poContact.isGroupMember = true;
				poContact.isSelected = true;
			});
	}

	/** Retourne un tableau des contacts membres des groupes participants à la conversation.
	 * @param paGroupsSelections Tableau des groupes participants à la conversation.
	 * @param paContacts Tableau de tous les contacts.
	 */
	private getSelectedMembers(paGroupsSelections: IGroupSelection[], paContacts: IContactSelection[]): IContactSelection[] {
		return paContacts.filter((poContact: IContactSelection<T>) =>
			paGroupsSelections.some((poGroup: IGroupSelection<T>) =>
				poGroup.members.some((poMember: T): boolean => poContact._id === poMember._id)
			));
	}

	/** Retourne un tableau des membres 'disabled' des groupes participants à la conversation.
	 * @param paGroupsSelections Tableau des groupes participants à la conversation.
	 * @param paContacts Tableau de tous les contacts.
	 */
	private getSelectedDisabledMembers(paGroupsSelections: IGroupSelection[], paContacts: IContactSelection[]): IContactSelection[] {
		return paContacts.filter((poContact: IContactSelection<T>) =>
			paGroupsSelections.some((poGroup: IGroupSelection<T>) =>
				poGroup.disabledMembers?.some((poMember: T): boolean => poContact._id === poMember._id)
			));
	}

	private propagateData(poSelection: IDataSelection<IGroupMember>, pbIsUpdate?: boolean): void {
		const laSelections: IDataSelection<IGroupMember>[] = [...this.dataSelections];

		if (pbIsUpdate) { // S'il s'agit d'une mise à jour.
			const lnUpdatedIndex: number = laSelections.findIndex((poItem: IDataSelection<IGroupMember>) => poItem._id === poSelection._id);

			if (lnUpdatedIndex !== -1) // La donnée est déjà présente, il faut remplacer l'ancienne donnée par celle mise à jour.
				laSelections[lnUpdatedIndex] = poSelection;
		}
		else
			laSelections.push(poSelection);

		this.initData(laSelections, this.getSelectedData(laSelections).map((poGroupMember: IGroupMember) => poGroupMember._id));
	}

	/** Inverse la sélection de tous les groupes, met à jour la possibilité de cocher et met à jour la toolbar. */
	public onGroupAllSelectionChanged(): void {
		if (!this.areAllGroupsSelectionDisabled) {
			this.areAllGroupsSelected = !this.areAllGroupsSelected;

			this.groupSelections.forEach((poGroup: IGroupSelection<T>) => {
				if (!poGroup.isDisabled) { // On modifie seulement si le groupe n'est pas désactivé.
					poGroup.isSelected = this.areAllGroupsSelected;
					this.onGroupSelectionChanged(poGroup, false);
				}
			});

			this.updateCanSelectMore();
		}
	}

	/** Inverse la sélection du groupe, met à jour la possibilité de cocher et met à jour la toolbar.
	 * @param poGroupData Donnée du groupe dont la sélection a changé.
	 * @param pbHasToUpdate Indique si on doit mettre à jour la sélection du groupe, et la toolbar.
	 */
	public onGroupSelectionChanged(poGroupData: IGroupSelection<T>, pbHasToUpdate: boolean = true): void {
		if (!poGroupData.isDisabled) {
			if (pbHasToUpdate) {
				if (this.params.max === 1) {
					this.groupSelections.forEach((poGroupSelection: IGroupSelection<T>) => {
						if (poGroupSelection._id === poGroupData._id) {
							this.reverseSelection(poGroupSelection);
							poGroupSelection.isDisabled = poGroupSelection.members.length === 0;
						}
						else
							poGroupSelection.isSelected = false;
					});

					this.selectedGroupId = poGroupData.isSelected ? poGroupData._id : undefined;
				}
				else {
					this.reverseSelection(poGroupData);
					poGroupData.isDisabled = poGroupData.members.length === 0;
				}
			}

			poGroupData.members.forEach((poMember: T) => this.innerOnGroupSelectionChanged(poGroupData, poMember));

			if (pbHasToUpdate) {
				this.areAllGroupsSelected = this.groupSelections.filter((poGroupSelection: IGroupSelection<T>) => !poGroupSelection.isDisabled)
					.every((poGroup: IGroupSelection<T>) => poGroup.isSelected);
				this.updateCanSelectMore();
			}
		}
		if (this.params.onGroupClicked)
			this.params.onGroupClicked(poGroupData.data, poGroupData.isSelected, poGroupData.members, poGroupData.disabledMembers);

		this.detectChanges();
	}

	public onGroupClicked(poGroupData: IGroupSelection<T>): void {
		if (poGroupData.isDisabled) {
			const lsMessage: string = poGroupData.members.length === 0 ?
				"Ce groupe ne peut pas être sélectionné car il ne contient aucun membre." :
				"Aucun contact de ce groupe ne peut participer à une conversation car ils n'ont ni compte utilisateur ni adresse email.";

			this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: lsMessage })
			);
		}
	}

	private innerOnGroupSelectionChanged(poGroupData: IGroupSelection<T>, poMember: T): void {
		const loMemberData: IDataSelection | undefined = this.contactSelections.find((poContact: IContactSelection<T>) => poContact._id === poMember._id);

		if (loMemberData) { // Si on a trouvé le membre (normal).
			// Si on n'a pas de fonction de désactivation ou que l'item ne remplit pas le critère de désactivation.
			if (!this.params.disableItemFunction || !this.params.disableItemFunction(poMember)) {
				if (poGroupData.isSelected) { // On coche et bloque le membre si le groupe a été sélectionné.
					loMemberData.isSelected = true;
					(loMemberData as IContactSelection<T>).isGroupMember = true;
				}
				else { // Si on a décoché le groupe et que le membre n'est pas présent dans un autre groupe, il faut le désélectionner et le débloquer.
					if (!this.isMemberPresentInAnotherSelectedGroup(loMemberData as IContactSelection<T>, poGroupData)) {
						loMemberData.isSelected = false;
						(loMemberData as IContactSelection<T>).isGroupMember = false;
					}
				}
			}
		}
		else
			console.error(`${ContactsPickerModalComponent.C_LOG_ID}Erreur pour (dé)cocher le contact '${poMember._id}' car non présent dans les données du sélecteur.`);
	}

	/** Crée un nouveau contact. */
	public createNewContact(): void {
		let loPrepareCreation$: Observable<IGroup | undefined> = of(undefined);

		if (ArrayHelper.hasElements(this.params.groupIds)) {
			if (this.params.groupIds && this.params.groupIds.length > 1)
				console.warn(new NotImplementedError("Création d'un nouveau contact en fonction de plusieurs groupes possibles."));
			else {
				loPrepareCreation$ = this.isvcGroups.getGroup(ArrayHelper.getFirstElement(this.params.groupIds))
			}
		}

		loPrepareCreation$
			.pipe(
				mergeMap((poGroup: IGroup | undefined) => this.innerCreateNewContact(poGroup)),
				tap((poNewContact: T | undefined) => poNewContact ? this.manageContactUpdatedOrCreated(poNewContact) : undefined),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private innerCreateNewContact(poGroup?: IGroup): Observable<T | undefined> {
		if (!this.params.entityModalParams)
			this.params.entityModalParams = {};

		this.params.entityModalParams.context = this.params.createParams;

		return this.isvcContacts.openCreateContactModal(this.params.entityModalParams)
			.pipe(
				mergeMap((poNewContact?: T) => {
					if (poGroup && poNewContact)
						return this.isvcGroups.updateGroup(poGroup, [], [poNewContact]).pipe(mapTo(poNewContact));
					else
						return of(poNewContact);
				})
			);
	};

	protected manageContactUpdatedOrCreated(poData: T, pbIsUpdate?: boolean, pbIsSelected?: boolean): void {
		const loSelection: IContactSelection<T> = this.contactToContactSelection(poData, pbIsSelected);

		this.propagateData(loSelection, pbIsUpdate);
		this.onContactSelectionChanged(loSelection);

		if (this.userContact)
			this.contactSelections.push(this.userContact as IContactSelection<T>);

		this.onFilteredContactsChanged(this.contactSelections, pbIsUpdate);
	}

	/** Crée un nouveau groupe. */
	public createNewGroup(): void {
		this.isvcGroups.openCreateGroupeModal()
			.pipe(
				mergeMap((poGroup: IGroup) => this.isvcGroups.getGroupContacts([poGroup])
					.pipe(
						map((poContactsByGroupId: Map<string, T[]>) => {
							const loSelection: IGroupSelection<T> = this.groupToGroupSelection(poGroup, poContactsByGroupId);

							this.propagateData(loSelection);
							this.onGroupSelectionChanged(loSelection);
							this.onFilteredGroupsChanged(this.groupSelections);
						})
					)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Retourne `true` si au moins un groupe sélectionné autre que le groupe manipulé contient le membre passé en paramètre, retourne `false` sinon.
	 * @param poMember Membre du groupe dont il faut vérifier s'il est présent dans un autre groupe.
	 * @param poGroup Groupe dans lequel le membre est présent et qu'il faut ignorer pour la recherche.
	 */
	private isMemberPresentInAnotherSelectedGroup(poMember: IContactSelection<T>, poGroup: IGroupSelection<T>): boolean {
		return this.groupSelections.some((poGroupSelection: IGroupSelection<T>) => poGroupSelection.isSelected &&
			poGroupSelection._id !== poGroup._id &&
			poGroupSelection.members.some((poContact: T) => poContact._id === poMember._id));
	}

	/** Inverse la sélection de tous les contacts, met à jour la possibilité de cocher et met à jour la toolbar. */
	public onContactAllSelectionChanged(): void {
		if (!this.areAllContactsSelectionDisabled) {
			this.areAllContactsSelected = !this.areAllContactsSelected;

			this.filteredContactDataSelections.forEach((poContact: IContactSelection<T>) => {
				if (!poContact.isDisabled)
					poContact.isSelected = this.areAllContactsSelected;
			});

			if (this.userContact)
				this.userContact.isSelected = true;

			this.updateCanSelectMore();
		}
	}

	/** Inverse la sélection du contact, met à jour la possibilité de cocher et met à jour la toolbar.
	 * @param poContact Donnée du contact dont la sélection a changé.
	 * @param poIonItem `ion-item` de l'élément cliqué.
	 */
	public async onContactSelectionChanged(poContact: IContactSelection<IContact>): Promise<void> {
		const loContact: IContactSelection<T> = poContact as IContactSelection<T>;

		if (!poContact.isDisabled) {
			if (this.params.max === 1)
				this.selectOnlyOneContact(loContact);
			else {
				if (poContact.isGroupMember) {
					// Réponse de la popup, 'true' pour continuer, 'false' pour annuler.
					const lbHasToContinue = !!(await this.showDeselectContactPopupAsync(loContact)).response;

					if (lbHasToContinue) {
						// Le premier groupe sélectionné qui contient le contact.
						const laSelectedGroups: IGroupSelection<T>[] | undefined = this.getSelectedContactGroups(loContact);
						if (ArrayHelper.hasElements(laSelectedGroups)) { // Si le tableau des groupes sélectionnés n'est pas vide.
							laSelectedGroups.forEach((loSelectedGroup: IGroupSelection<T>) => {
								this.onGroupSelectionChanged(loSelectedGroup);
								this.addOtherGroupMembers(loSelectedGroup, loContact);
							});
						}
						else
							console.error(`${ContactsPickerModalComponent.C_LOG_ID}Contact '${poContact._id}' is a group member but no associated group.`);
					}
				}
				else
					this.reverseSelection(loContact);
			}

			this.updateAreAllContactsSelected();
			this.updateCanSelectMore();
		}
	}

	/** Ajoute tous les membres d'un groupe comme sélectionnés.
	 * @param poGroupSelection Le groupe dont on doit ajouter les membres.
	 * @param poExcludeContact Le membre à ne pas ajouter.
	 */
	private addOtherGroupMembers(poGroupSelection: IGroupSelection<T>, poExcludeContact: IContactSelection<T>): void {
		if (ArrayHelper.hasElements(poGroupSelection.members)) {
			poGroupSelection.members.forEach((poMember: T) => {
				const loContactMember: IDataSelection | undefined = this.contactSelections.find((poContact: IContactSelection<T>) => poContact._id === poMember._id);
				if (loContactMember && !loContactMember.isSelected && poExcludeContact._id !== loContactMember._id)
					loContactMember.isSelected = true;
			});
		}
	}

	/** Sélectionne le contact qui correspond au contact passé en paramètre,
	 * et désélectionne tous les autres contacts.
	 * @param poContact Donnée du contact à sélectionner.
	 */
	private selectOnlyOneContact(poContact: IContactSelection<T>): void {
		if (ArrayHelper.hasElements(this.contactSelections)) {
			this.contactSelections.forEach((poContactSelection: IContactSelection<T>) => {
				poContactSelection.isSelected = poContactSelection._id === poContact._id;
			});
		}

		this.selectedContactId = poContact.isSelected ? poContact._id : undefined;
	}

	/** Retourne la liste des groupes sélectionnés qui contiennent le contact.
	 * @param poContact Donnée du contact à trouver.
	 */
	private getSelectedContactGroups(poContact: IContactSelection<T>): IGroupSelection<T>[] {
		return this.groupSelections.filter((poGroupSelection: IGroupSelection<T>) =>
			poGroupSelection.isSelected && poGroupSelection.members.some((poMember: T) => poContact._id === poMember._id)
		);
	}

	/** Inverse la sélection d'un contact ou d'un groupe.
	 * @param poSelection Contact ou groupe à inverser la sélection.
	 */
	private reverseSelection(poSelection: IContactSelection<T> | IGroupSelection<T>): void {
		poSelection.isSelected = !poSelection.isSelected;
	}

	/** Affiche une popup de confirmation de déselection d'un contact appartenant à un groupe sélectionné.
	 * @param poContact Donnée du contact à afficher.
	 * @param paGroupsSelection Liste des groupes sélectionnés qui contiennent le contact.
	 * @returns 'true' pour continuer, 'false' pour annuler.
	 */
	private showDeselectContactPopupAsync(poContact: IContactSelection<T>): Promise<IUiResponse<boolean>> {
		// Liste des groupes du contact sélectionné.
		const laSelectedGroups: IGroupSelection<T>[] = this.getSelectedContactGroups(poContact);
		const lsUserName: string = ContactHelper.getCompleteFormattedName(poContact.data);
		const lsGroupNames: string = laSelectedGroups.map((poGroupSelection: IGroupSelection<T>) => `"${poGroupSelection.data.name}"`).join(", ");
		const lsGroupLabel: string = laSelectedGroups.length > 1 ? "des groupes" : "du groupe";

		return firstValueFrom(this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: "Retirer un participant",
				message: `${lsUserName} fait partie ${lsGroupLabel} ${lsGroupNames} dont les autres membres resteront participants.`,
				buttons: [
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() },
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() }
				] as AlertButton[]
			})
		));
	}

	private updateAreAllContactsSelected(): void {
		let lbAreAllSelected: boolean = this.filteredContactDataSelections.filter((poContactSelection: IContactSelection<T>) => !poContactSelection.isDisabled)
			.every((poContact: IContactSelection<T>) => poContact.isSelected);

		if (this.userContact)
			lbAreAllSelected = lbAreAllSelected && !!this.userContact.isSelected;

		this.areAllContactsSelected = lbAreAllSelected;
		this.detectChanges();
	}

	/** Met à jour le booléen indiquant si on peut sélectionner davantage de contacts. */
	private updateCanSelectMore(): void {
		if (this.params.max) { // On bloque la sélection si on a atteint le maximum de sélections.
			const lnSelectedUserContact: number = this.userContact && this.userContact.isSelected ? 1 : 0;
			this.canSelectMore = this.params.max > (this.dataSelections.filter((poData: IDataSelection) => poData.isSelected).length + lnSelectedUserContact);
		}
	}

	/** Crée une liste des contacts sélectionnés que l'on envoie aux abonnés avant de fermer la modale. */
	public validate(): void {
		const laSelectedData: IGroupMember[] = [];

		if (this.isContactsType && this.isGroupsType) // Cas contacts ET groupes.
			laSelectedData.push(...this.getSelectedContactsAndGroups());

		else if (this.isContactsType) // Cas contacts uniquement.
			laSelectedData.push(...this.getSelectedContacts());

		else // Cas groupes uniquement.
			laSelectedData.push(...this.getSelectedGroups());

		const laUniqueSelectedData: T[] = [];
		laSelectedData.forEach((poData: T) => {
			if (!laUniqueSelectedData.some((poUniqueData: T) => poUniqueData._id === poData._id))
				laUniqueSelectedData.push(poData);
		});
		this.close(laUniqueSelectedData);
	}

	private getSelectedContactsAndGroups(): T[] {
		const laSelectedGroups: IGroup[] = [];
		const loContactIdsFromGroupsMap = new Map<string, string>();

		this.groupSelections.forEach((poGroup: IGroupSelection<T>) => {
			if (poGroup.isSelected) { // On ajoute tous les identifiants de membres dont le groupe est sélectionné, et on ajoute le groupe sélectionné.
				poGroup.members.forEach((poContact: T) => loContactIdsFromGroupsMap.set(poContact._id, poContact._id));
				laSelectedGroups.push(poGroup.data);
			}
		});

		// On retourne un tableau des données de contacts sélectionnés qui n'appartiennent pas à un des groupes sélectionnés.
		const laSelectedContacts: T[] = this.contactSelections
			.filter((poItem: IContactSelection<T>) => poItem.isSelected && !loContactIdsFromGroupsMap.has(poItem._id))
			.map((poItem: IContactSelection<T>) => poItem.data);

		return [...laSelectedGroups, ...laSelectedContacts] as T[];
	}

	protected getSelectedContacts(): T[] {
		return this.getSelectedData(this.contactSelections) as T[];
	}

	private getSelectedGroups(): IGroup[] {
		return this.getSelectedData(this.groupSelections) as IGroup[];
	}

	private getSelectedData(paArray: IDataSelection[]): IGroupMember[] {
		return paArray.filter((poItem: IDataSelection) => poItem.isSelected).map((poItem: IDataSelection) => poItem.data);
	}

	/** Réception du changement du tableau des entrées filtrées depuis le composant 'Search'.
	 * @param paNewFilteredContacts Nouveau tableau contenant les données filtrées.
	 */
	public onFilteredContactsChanged(paNewFilteredContacts: IContactSelection<T>[], pbIsUpdate?: boolean): void {
		let laNewFilteredContacts: IContactSelection<T>[] = this.filterByGroupFilter(Array.from(paNewFilteredContacts), this.maSelectedGroupFilters);

		if (UserData.current && (pbIsUpdate || !ArrayHelper.areArraysFromDatabaseEqual(laNewFilteredContacts, this.filteredContactDataSelections))) {
			const lsUserContactId: string = ContactsService.getContactIdFromUserId(UserData.current.name);
			const loUserContact: IContactSelection<T> | undefined = // On supprime le contact de l'utilisateur de la liste avant affectation pour éviter des problèmes de rendu.
				ArrayHelper.removeElementByFinder(laNewFilteredContacts, (poItem: IDataSelection) => poItem._id === lsUserContactId) as IContactSelection<T>;

			laNewFilteredContacts = laNewFilteredContacts.filter((poItem: IDataSelection) => poItem._id !== lsUserContactId);

			if (!this.params.excludeCurrentUser)
				this.userContact = loUserContact;

			this.sortContacts(laNewFilteredContacts);
			this.hasSearchResult = ArrayHelper.hasElements(this.filteredContactDataSelections);

			this.updateAreAllContactsSelected();
			this.updateCanSelectMore();
			this.detectChanges();
		}
	}

	/** Réception du changement du tableau des entrées filtrées depuis le composant 'Search'.
	 * @param paNewFilteredGroups Nouveau tableau contenant les données filtrées.
	 */
	public onFilteredGroupsChanged(paNewFilteredGroups: IGroupSelection<T>[]): void {
		if (!ArrayHelper.areArraysFromDatabaseEqual(paNewFilteredGroups, this.filteredGroupDataSelections)) {
			this.sortGroups(paNewFilteredGroups);
			this.sortContacts(this.filteredContactDataSelections);
			this.hasSearchResult = ArrayHelper.hasElements(this.filteredGroupDataSelections);

			this.updateCanSelectMore();
			this.detectChanges();
		}
	}

	/** Permet de tracker l'identifiant du contact affiché afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du contact.
	 * @param poContact Contact affiché.
	 */
	public trackById(pnIndex: number, poContact: IGroupMember): string {
		return poContact._id;
	}

	/** Permet d'étendre l'affichage des groupes ou de le rétracter. */
	public groupExpandToggle(): void {
		this.isGroupsDividerExpanded = !this.isGroupsDividerExpanded;
	}

	/** Permet d'étendre l'affichage des contacts ou de le rétracter. */
	public contactsExpandToggle(): void {
		this.isContactsDividerExpanded = !this.isContactsDividerExpanded;
	}

	public onTabChanged(pnIndex: number): void {
		if (pnIndex !== this.moObservableActiveIndex.value) {
			this.moObservableActiveIndex.value = pnIndex;
			this.detectChanges();
		}
	}

	public onGroupFilterSelectionChanged(paGroups: IGroup[], poSearchComponent: SearchComponent<IContact>): void {
		this.maSelectedGroupFilters = paGroups;

		this.onFilteredContactsChanged(poSearchComponent.search() as IContactSelection<T>[]);
	}

	private filterByGroupFilter(paContactSelections: IContactSelection<T>[], paGroups: IGroup[]): IContactSelection<T>[] {
		return !ArrayHelper.hasElements(paGroups) ? paContactSelections : paContactSelections.filter((poContactSelection: IContactSelection<T>) =>
			paGroups.some((poSelectedGroup: IGroup) =>
				this.maContactsByGroupId.get(poSelectedGroup._id)?.some((poContact: T) => poContact._id === poContactSelection._id)
			)
		);
	}

	public onGroupRoleSelectionChanged(paRoles: IApplicationRole[], poSearchComponent: SearchComponent<IGroupSelection<T>>): void {
		this.onFilteredGroupsChanged(this.filterGroupsByRoles(poSearchComponent.search(), paRoles));
	}

	private filterGroupsByRoles(paDocuments: IGroupSelection<T>[], paRoles: IApplicationRole[]): IGroupSelection<T>[] {
		return !ArrayHelper.hasElements(paRoles) ?
			paDocuments : paDocuments.filter((poDocument: IGroupSelection<T>) => paRoles.every((poRole: IApplicationRole) => poDocument.data.roles?.includes(poRole.id)));
	}

	/** Trie la liste des contacts : les contacts qui étaient sélectionnés au départ sont mis en premier, les autres après + un tri alphabétique.
	 * @param paContactsSelection La liste à trier.
	 * @returns Retourne la liste triée.
	 */
	private sortContactsByOldSelectedContacts(paContactsSelection: IContactSelection<T>[]): IContactSelection<T>[] {
		return paContactsSelection.sort((poContact1: IContactSelection<T>, poContact2: IContactSelection<T>): number => {
			const lbIsOldSelectedContact1: boolean = this.isOldSelectedContact(poContact1);
			const lbIsOldSelectedContact2: boolean = this.isOldSelectedContact(poContact2);

			if ((lbIsOldSelectedContact1 && lbIsOldSelectedContact2) || (!lbIsOldSelectedContact1 && !lbIsOldSelectedContact2))
				return this.compareContactsByLastname(poContact1, poContact2);

			if (lbIsOldSelectedContact1)
				return -1;
			else
				return 1;
		});
	}

	/** Trie la liste des groupes : les groupes qui étaient sélectionnés au départ sont mis en premier, les autres après + un tri alphabétique.
	 * @param paGroupsSelection La liste à trier.
	 * @returns Retourne la liste triée.
	 */
	private sortGroupsByOldSelectedGroups(paGroupsSelection: IGroupSelection<T>[]): IGroupSelection<T>[] {
		return paGroupsSelection.sort((poGroup1: IGroupSelection<T>, poGroup2: IGroupSelection<T>): number => {
			const lbIsOldSelectedGroup1: boolean = this.isOldSelectedGroup(poGroup1);
			const lbIsOldSelectedGroup2: boolean = this.isOldSelectedGroup(poGroup2);

			if ((lbIsOldSelectedGroup1 && lbIsOldSelectedGroup2) || (!lbIsOldSelectedGroup1 && !lbIsOldSelectedGroup2))
				return this.compareGroupByName(poGroup1, poGroup2);

			if (lbIsOldSelectedGroup1)
				return -1;
			else
				return 1;
		});
	}

	/** Vérifie que le contact était déjà sélectionné avant.
	 * @param poContact Le contact à vérifier.
	 * @returns `true` s'il était déjà sélectionné avant, `false` sinon.
	 */
	private isOldSelectedContact(poContact: IContactSelection<T>): boolean {
		return this.maOldSelectedContacts.some((poOldSelectedContact: IContactSelection<T>) => poOldSelectedContact._id === poContact._id);
	}

	/** Vérifie que le groupe était déjà sélectionné avant.
	 * @param poGroup Le groupe à vérifier.
	 * @returns `true` s'il était déjà sélectionné avant, `false` sinon.
	 */
	private isOldSelectedGroup(poGroup: IGroupSelection<T>): boolean {
		return this.maOldSelectedGroups.some((poOldSelectedGroup: IGroupSelection<T>) => poOldSelectedGroup._id === poGroup._id);
	}

	/** Trie la liste des contacts en fonction du paramètre sort.
	 * @param paContactsSelection La liste à trier.
	 */
	private sortContacts(paContactsSelection: IContactSelection<T>[]): void {
		const laContactsSelection: IContactSelection<T>[] = [...paContactsSelection];

		switch (this.params.sort) {
			case EContactsPickerSort.alphabetical:
				this.filteredContactDataSelections = this.sortContactsByLastname(laContactsSelection);
				break;

			case EContactsPickerSort.byPreSelectedParticipants:
				this.filteredContactDataSelections = this.sortContactsByOldSelectedContacts(laContactsSelection);
				break;
		}
	}

	/** Trie la liste des groupes en fonction du paramètre sort.
	 * @param paGroupsSelection La liste à trier.
	 */
	private sortGroups(paGroupsSelection: IGroupSelection<T>[]): void {
		const laGroupsSelection: IGroupSelection<T>[] = [...paGroupsSelection];

		switch (this.params.sort) {
			case EContactsPickerSort.alphabetical:
				this.filteredGroupDataSelections = this.sortGroupByName(laGroupsSelection);
				break;

			case EContactsPickerSort.byPreSelectedParticipants:
				this.filteredGroupDataSelections = this.sortGroupsByOldSelectedGroups(laGroupsSelection);
				break;
		}
	}

	/** Trie la liste des contacts par leurs noms de famille, par ordre alphabétique.
	 * @param paContactsSelection La liste à trier.
	 * @returns Retourne la liste triée.
	 */
	private sortContactsByLastname(paContactsSelection: IContactSelection<T>[]): IContactSelection<T>[] {
		return paContactsSelection.sort((poContactSelectionA: IContactSelection<T>, poContactSelectionB: IContactSelection<T>) =>
			this.compareContactsByLastname(poContactSelectionA, poContactSelectionB)
		);
	}

	/** Trie la liste des groupes par leurs noms, par ordre alphabétique.
	 * @param paGroupsSelection La liste à trier.
	 * @returns Retourne la liste triée.
	 */
	private sortGroupByName(paGroupsSelection: IGroupSelection<T>[]): IGroupSelection<T>[] {
		return paGroupsSelection.sort((poGroupSelectionA: IGroupSelection<T>, poGroupSelectionB: IGroupSelection<T>) =>
			this.compareGroupByName(poGroupSelectionA, poGroupSelectionB)
		);
	}

	/** Compare deux contacts selon leurs noms de famille.
	 * @param poContactSelectionA Le premier contact à comparer.
	 * @param poContactSelectionB Le second contact à comparer.
	 * @returns `-1` si A est avant B, `1` si A est après B, 0 si A et B sont égaux.
	 */
	private compareContactsByLastname(poContactSelectionA: IContactSelection<T>, poContactSelectionB: IContactSelection<T>): number {
		return ContactHelper.compareContactsByLastName(poContactSelectionA.data, poContactSelectionB.data);
	}

	/** Compare deux groupes selon leurs noms.
	 * @param poGroupSelectionA Le premier groupe à comparer.
	 * @param poGroupSelectionB Le second groupe à comparer.
	 * @returns `-1` si A est avant B, `1` si A est après B, 0 si A et B sont égaux.
	 */
	private compareGroupByName(poGroupSelectionA: IGroupSelection<T>, poGroupSelectionB: IGroupSelection<T>): number {
		return poGroupSelectionA.data.name?.toLowerCase().localeCompare(poGroupSelectionB.data.name?.toLowerCase());
	}

	/** Définit la liste des anciens contacts et groupes sélectionnés. */
	private setOldContactsAndGroupsSelected(): void {
		this.maOldSelectedContacts = this.dataSelections.filter((poContactOrGroup: IDataSelection<IGroupMember>) =>
			ContactsService.isContact(poContactOrGroup._id) && poContactOrGroup.isSelected
		) as IContactSelection<T>[];
		this.maOldSelectedGroups = this.dataSelections.filter((poContactOrGroup: IDataSelection<IGroupMember>) =>
			GroupsService.isGroup(poContactOrGroup._id) && poContactOrGroup.isSelected
		) as IGroupSelection<T>[];
	}

	//#endregion

}
