import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { GuidHelper } from '../../../../../helpers/guidHelper';
import { IdHelper } from '../../../../../helpers/idHelper';
import { UserHelper } from '../../../../../helpers/user.helper';
import { EPrefix } from '../../../../../model/EPrefix';
import { EDatabaseRole } from '../../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../../model/store/IDataSource';
import { IDataSourceViewParams } from '../../../../../model/store/IDataSourceViewParams';
import { Store } from '../../../../../services/store.service';
import { LoggerService } from '../../../../logger/services/logger.service';
import { TourService } from '../../../../tour/tour/tour.service';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { ControlStep } from '../../../inventory/models/control-filters/control-step';
import { RackHelper } from '../../helpers/rack.helper';
import { ERackStatus } from '../../models/ERackStatus';
import { ReturnRackHelper } from '../helpers/return-rack.helper';
import { EReturnTaskBehaviorType } from '../models/ereturn-task-behavior-type';
import { IInventoryNavigationParams } from '../models/IInventoryNavigationParams';
import { IPickingNavigationParams } from '../models/IPickingNavigationParams';
import { IRackReturnArticle } from '../models/IRackReturnArticle';
import { IReceipt } from '../models/IReceipt';
import { IReceiptReturnArticle } from '../models/IReceiptReturnArticle';
import { IReturnRack } from '../models/ireturn-rack';
import { IReturnTaskBehavior } from '../models/ireturn-task-behavior';
import { IReturnTask } from '../models/IReturnTask';
import { ReturnRackService } from './return-rack.service';

@Injectable()
export class ReturnsService {

	//#region FIELDS

	protected static readonly C_LOG_ID = "RTRNS.S::";

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcStore: Store,
		protected readonly isvcLogger: LoggerService,
		protected readonly isvcReturnRack: ReturnRackService
	) { }

	/** Récupère les bons de la tâche.
	 * @param psTaskId L'identifiant de la tâche qui contient les identifiants de la tournée et du rendez-vous.
	 * @param pbIsLive `true` pour écouter les changements sur les bases de données (`false` par défaut).
	 */
	public getReceipts(psTaskId: string, pbIsLive?: boolean): Observable<IReceipt[]>;
	/** Récupère les bons de la tâche.
	 * @param psTaskId Tâche dont on veut récupérer les bons.
	 * @param pbIsLive `true` pour écouter les changements sur les bases de données (`false` par défaut).
	 */
	public getReceipts(poTask: IReturnTask, pbIsLive?: boolean): Observable<IReceipt[]>;
	public getReceipts(poTaskData: string | IReturnTask, pbIsLive?: boolean): Observable<IReceipt[]> {
		const lsStartKey: string = typeof poTaskData === "string" ? this.buildGetReceiptsStartKey(poTaskData) : `${EPrefix.receipt}${poTaskData._id}`;

		return this.isvcStore.get<IReceipt>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			} as IDataSourceViewParams,
			live: pbIsLive
		} as IDataSource);
	}

	/** Retourne la clé de début pour récupérer les bons d'une tâche.
	* @param psTaskId Identifiant de la tâche dont on veut récupérer les bons.
	*/
	private buildGetReceiptsStartKey(psTaskId: string): string {
		return `${EPrefix.receipt}${EPrefix.task}${EPrefix.appoint}${TourService.C_TOUR_PREFIX}${IdHelper.extractIdWithoutPrefix(IdHelper.getIdFromComposedId(psTaskId, TourService.C_TOUR_PREFIX), TourService.C_TOUR_PREFIX,)}_${IdHelper.extractIdWithoutPrefix(IdHelper.getIdFromComposedId(psTaskId, EPrefix.appoint), EPrefix.appoint)}_${IdHelper.getLastGuidFromId(psTaskId)}`;
	}

	/** Récupération des paramètres de navigation pour naviguer sur une page de prélèvement d'un nouveau portant.
	 * @param poReturnTask Tâche de reprise dont il faut créer un nouveau portant pour prélèvement.
	 * @param paReceipts Tableau des bons de reprise de la tâche de reprise.
	 */
	public getNavigateToNewPickingParams(poReturnTask: IReturnTask, paReceipts?: IReceipt[]): Observable<IPickingNavigationParams> {
		return defer(() => paReceipts ? of(paReceipts) : this.getReceipts(poReturnTask._id))
			.pipe(
				mergeMap((paReceiptResults: IReceipt[]) => {
					return this.getNavigateToPickingReceiptArticlesAsync(poReturnTask, paReceiptResults)
						.then((paReceiptArticleResults: IReceiptReturnArticle[]) => {
							const loNewRack: IReturnRack = {
								_id: RackHelper.buildRackId(poReturnTask._id, GuidHelper.newGuid()),
								createContactId: UserHelper.getUserContactId(),
								status: ERackStatus.active,
								createDate: new Date(),
								picking: {},
								controls: []
							};

							return this.createPickingNavigationParamsAsync(poReturnTask, loNewRack, paReceiptResults, paReceiptArticleResults);
						});
				})
			);
	}

	/** Récupération des paramètres de navigation pour naviguer sur une page de prélèvement, `undefined` si portant non trouvé.
	 * @param poReturnTask Tâche de reprise dont il faut créer un nouveau portant pour prélèvement.
	 * @param poRackData Identifiant du portant ou portant dont on veut naviguer vers la page de prélèvement.
	 * @param paReceipts Tableau des bons de reprise de la tâche de reprise.
	 */
	public getNavigateToPickingParams(poReturnTask: IReturnTask, poRackData: string | IReturnRack, paReceipts?: IReceipt[]): Observable<IPickingNavigationParams | undefined> {
		return defer(() => typeof poRackData === "string" ? this.isvcReturnRack.getRack(poRackData) : of(poRackData))
			.pipe(
				mergeMap((poRack?: IReturnRack) => {
					if (poRack) {
						return defer(() => paReceipts ? of(paReceipts) : this.getReceipts(poReturnTask._id))
							.pipe(
								mergeMap((paReceiptResults: IReceipt[]) => {
									return this.getNavigateToPickingReceiptArticlesAsync(poReturnTask, paReceipts)
										.then((paReceiptArticleResults: IReceiptReturnArticle[]) =>
											this.createPickingNavigationParamsAsync(poReturnTask, poRack, paReceiptResults, paReceiptArticleResults)
										);
								})
							);
					}
					else
						return of(undefined);
				})
			);
	}

	/** Récupère le tableau des articles des bons de la reprise à tester.
	 * @param poReturnTask Tâche de reprise dont il faut créer un nouveau portant pour prélèvement.
	 * @param paReceipts Tableau des bons de reprise de la tâche de reprise.
	 */
	private getNavigateToPickingReceiptArticlesAsync(poReturnTask: IReturnTask, paReceipts?: IReceipt[]): Promise<IReceiptReturnArticle[]> {
		return defer(() => paReceipts ? of(paReceipts) : this.getReceipts(poReturnTask._id).pipe(take(1)))
			.toPromise()
			.then((paReceiptResults: IReceipt[]) => {
				return ArrayHelper.unique(
					ArrayHelper.flat(paReceiptResults.map((poReceipt: IReceipt) => poReceipt.items)),
					(poItem: IReceiptReturnArticle) => poItem.itemId
				);
			});
	}

	/** Crée les paramètres de navigation pour un prélèvement.
	 * @param poReturnTask Tâche de reprise
	 * @param poRack Portant dont on veut aller sur la page de prélèvement.
	 * @param paReceipts Tableau des bons de reprise de la tâche de reprise.
	 * @param paEligibleReceiptArticles Tableau des articles de reprise éligibles pour prélèvement.
	 */
	private createPickingNavigationParamsAsync(poReturnTask: IReturnTask, poRack: IReturnRack, paReceipts: IReceipt[], paEligibleReceiptArticles: IReceiptReturnArticle[])
		: Promise<IPickingNavigationParams> {
		return this.isvcReturnRack.getRacksFromTask$(poReturnTask)
			.toPromise()
			.then((paRacks: IReturnRack[]) => {
				// On récupère l'index du portant dans le tableau.
				const lnIndex: number = ReturnRackHelper.sortByCreateDate(paRacks).findIndex((poFilteredRack: IReturnRack) => poFilteredRack._id === poRack._id);

				return {
					...this.createInventoryNavigationParams(poReturnTask, poRack, paRacks, paReceipts),
					availableReceiptArticles: paEligibleReceiptArticles,
					// Si lnIndex === -1 : cas d'une création d'un nouveau portant, on retourne la longueur du tableau ; sinon on retourne l'index du portant.
					index: (lnIndex === -1) ? paRacks.length : lnIndex
				} as IPickingNavigationParams;
			});
	}

	/** Crée les paramètres de navigation pour un inventaire.
	 * @param poReturnTask Tâche de reprise.
	 * @param poRack Portant dont on veut aller sur la page d'inventaire.
	 * @param paRacks Tableau des portants filtrés
	 * @param paReceipts Tableau des bons de la tâche.
	 */
	public createInventoryNavigationParams(poReturnTask: IReturnTask, poRack: IReturnRack, paRacks: IReturnRack[], paReceipts?: IReceipt[]): IInventoryNavigationParams {
		const lnRackIndex: number = ReturnRackHelper.sortByCreateDate(paRacks).findIndex((paRack: IReturnRack) => paRack._id === poRack._id);

		return {
			rack: poRack,
			index: lnRackIndex,
			task: poReturnTask,
			receipts: paReceipts
		} as IInventoryNavigationParams;
	}

	/** Récupère tous les articles d'une tâche de reprise, de façon unique.
	 * @param poReturnTask Tâche de reprise dont il faut récupérer les articles.
	 * @param paReceipts Tableau des bons de la tâche.
	 * @param paRacks Tableau des portants de la tâche.
	 */
	public getReturnTaskAllArticles(poReturnTask: IReturnTask, paReceipts?: IReceipt[], paRacks?: IReturnRack[]): Observable<Array<IReceiptReturnArticle | IRackReturnArticle>> {
		const loReceipts$: Observable<IReceipt[]> = paReceipts ? of(paReceipts) : this.getReceipts(poReturnTask);

		return loReceipts$.pipe(
			mergeMap((paReceiptResults: IReceipt[]) => {
				const loRacks$: Observable<IReturnRack[]> = paRacks ? of(paRacks) : defer(() => this.isvcReturnRack.getNotCanceledRacksFromTaskAsync(poReturnTask));

				return loRacks$.pipe(
					map((paRackResults: IReturnRack[]) => {
						// On récupère tous les articles des bons qu'on concatène avec tous les articles des portants.
						return [...ArrayHelper.flat(paReceiptResults.map((poReceipt: IReceipt) => poReceipt.items)), ...ReturnRackHelper.getPickedArticles(paRackResults)];
					})
				);
			}),
			map((paEligibleReceiptArticles: Array<IReceiptReturnArticle | IRackReturnArticle>) =>
				ArrayHelper.unique(paEligibleReceiptArticles, (poItem: IReceiptReturnArticle | IRackReturnArticle) => poItem.itemId)
			)
		);
	}

	/** Crée et retourne une instance de contrôle d'étape, `undefined` si on ne peut pas créer l'instance.
	 * @param poTask Tâche dont il faut créer l'instance de contrôle d'étape.
	 */
	public getStepControlInstance(poTask: IReturnTask): ControlStep | undefined {
		return ModelResolver.toClass(ControlStep, poTask.steps?.control);
	}

	/** Indique si la tâche possède le comportement `updateRackStatus` qui consiste à mettre à jour le statut des portants
	 * en fonction de celui de la tâche.
	 * @param poTask
	 */
	public hasUpdateRackStatusBehavior(poTask: IReturnTask): boolean {
		return poTask.steps?.validation?.task?.behaviors?.some((poTaskBehavior: IReturnTaskBehavior) =>
			poTaskBehavior.type === EReturnTaskBehaviorType.updateRackStatus
		) ?? false;
	}

	//#endregion

}