import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPageComponent } from '@calaosoft/osapp/components/dynamicPage/dynamicPage.component';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { CanDeactivateGuard } from '@calaosoft/osapp/guards/canDeactivate.guard';
import { LogOutGuard } from '@calaosoft/osapp/guards/log-out.guard';
import { NotAuthenticatedGuard } from '@calaosoft/osapp/guards/not-authenticated.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { CanActivateGuardsScheduler } from "@calaosoft/osapp/modules/routing/models/can-activate-guards-scheduler";
import { RemoveCredentialsGuard } from '@calaosoft/osapp/modules/security/guards/remove-credentials.guard';
import { TEXTILOT_DASHBOARD_URL } from '../config';
import { TourResolver } from './tour/resolvers/tour.resolver';

export const C_CUSTOMER_CONTACTS = "customersContacts";
export const C_CUSTOMER_CONTACTS_FORM_DESCRIPTOR = `formDesc_${C_CUSTOMER_CONTACTS}`;

const CONNECTED_APPS_PAGE_INFO = new PageInfo({
	title: "Applications connectées",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: TEXTILOT_DASHBOARD_URL,
		cssClass: "full-size"
	}
});

const routes: Routes = [
	{ path: '', redirectTo: `tours/${TourResolver.C_CURRENT}`, pathMatch: 'full' },
	{
		path: 'authenticator',
		loadChildren: () => import('./authentication/authentication-page.module').then(poModule => poModule.AuthenticationPageModule),
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: 'home',
		redirectTo: `tours/${TourResolver.C_CURRENT}`,
		pathMatch: 'full'
	},
	{
		path: 'diagnostics',
		loadChildren: () => import('./diagnostics/diagnostics.module').then(poModule => poModule.DiagnosticsPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'disconnect',
		loadChildren: () => import('@calaosoft/osapp/modules/authentication/authentication.module').then(poModule => poModule.AuthenticationModule),
		canActivate: [LogOutGuard]
	},
	{
		path: 'invalidate-session',
		loadChildren: () => import('@calaosoft/osapp/modules/authentication/authentication.module').then(poModule => poModule.AuthenticationModule),
		//! On doit séquentialiser et appeler `LogOutGuard` en second car sinon on navigue directement et les autres gardiens ne sont pas activés.
		canActivate: [CanActivateGuardsScheduler.schedule([RemoveCredentialsGuard, LogOutGuard])]
	},
	{
		path: 'tours',
		loadChildren: () => import('./tour/tour.module').then(poModule => poModule.TourPageModule)
	},
	{
		path: "tours/:tourId/appointments",
		loadChildren: () => import('./appointment/merch-appointment.module').then(m => m.MerchAppointmentModule)
	},
	{
		path: "tours/:tourId/appointments/:appointType/:appointmentGuid/tasks",
		loadChildren: () => import('./task/task.module').then(poModule => poModule.TaskModule)
	},
	{
		path: 'customerContacts',
		loadChildren: () => import("./customer-contacts/customer-contact.module").then(poModule => poModule.CustomerContactModule)
	},
	{
		path: 'connected-apps',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONNECTED_APPS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'changelogs',
		loadChildren: () => import('@calaosoft/osapp/modules/about/changelogs/changelogs.module').then(m => m.ChangelogsModule),
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
		canActivate: [AuthenticatedGuard],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
