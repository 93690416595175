import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EGalleryDisplayMode } from '../../../../../components/gallery/models/EGalleryDisplayMode';
import { IGalleryCommand } from '../../../../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../../../../model/gallery/IGalleryFile';
import { GalleryFile } from '../../../../../model/gallery/gallery-file';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { IDeletableTag } from '../../../../tags/models/ideletable-tag';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { FieldBase } from '../../../models/FieldBase';
import { IGalleryFieldParams } from '../../../models/IGalleryFieldParams';
import { FormsService } from '../../../services/forms.service';
import { IInlineListField } from './inline-list-field-layout/models/iinline-list-field';
import { IInlineListFieldLayoutParams } from './inline-list-field-layout/models/iinline-list-field-layout-params';

interface IFileTag extends IDeletableTag {
	value: IGalleryFile;
}

@Component({
	templateUrl: './galleryField.component.html',
})
export class GalleryFieldComponent extends FieldBase<IGalleryFile[]> implements OnInit, OnDestroy, IInlineListField {

	//#region FIELDS

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();

	//#endregion

	//#region PROPERTIES

	public readonly galleryCommand$: Observable<IGalleryCommand>;

	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IGalleryFieldParams & IInlineListField;
	public layout: 'inline';
	public layoutParams: IInlineListFieldLayoutParams;

	/** Liste des permissions par guid de document. */
	public readonly observablePermissionsByGuid = new ObservableProperty<Map<string, Map<string, boolean>>>(new Map());

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetector: ChangeDetectorRef) {
		super(psvcForms, poChangeDetector);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;

		if (this.layout === "inline") {
			this.params.displayMode = EGalleryDisplayMode.add;
			this.params.filePickerVisible = false;
			this.params.imagePickerVisible = false;
			this.params.cameraButtonVisible = false;
		}
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moGalleryCommandSubject.complete();
	}

	public onFilesChanged(paFiles: IGalleryFile[]): void {
		this.fieldValue = paFiles.map((poFile: IGalleryFile) => ModelResolver.toClass(GalleryFile, poFile));
		this.markAsDirty();
	}

	public getFileTagsFromFiles(paFiles: IGalleryFile[] = [], poPermissonsByGuid: Map<string, Map<string, boolean>>): IFileTag[] {
		return paFiles.map((poFile: IGalleryFile, pnIndex: number) => {
			const loFilePermissions: Map<string, boolean> | undefined = poPermissonsByGuid.get(poFile.guid);

			return {
				id: pnIndex.toString(),
				label: poFile.name,
				deletable: loFilePermissions?.get('trash') || loFilePermissions?.get('delete'),
				value: poFile
			};
		})
	}

	public removeFile(poTag: IDeletableTag, poPermissonsByGuid: Map<string, Map<string, boolean>>, pfDeleteItem: (pnIndex: number, pbSoft?: boolean) => void) {
		pfDeleteItem(+poTag.id, !poPermissonsByGuid.get((poTag as IFileTag).value.guid)?.get("delete"));
	}

	//#endregion
}