import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AnomalyBarcodeReaderComponent } from './anomaly-barcode-reader.component';

const components = [AnomalyBarcodeReaderComponent];

@NgModule({
	declarations: components,
	imports: [
		IonicModule
	],
	exports: components
})
export class AnomalyBarcodeReaderModule { }
