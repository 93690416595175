import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../pipes/pipes.module';
import { HeaderModule } from '../../header';
import { IFrameModule } from '../../iframe/iframe.module';
import { ChangelogsComponent } from './components/changelogs.component';
import { ChangelogsPageRoutingModule } from './page/changelogs-routing.module';
import { ChangelogsPage } from './page/changelogs.page';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		HeaderModule,
		PipeModule,
		IFrameModule,
		ChangelogsPageRoutingModule
	],
	declarations: [ChangelogsPage, ChangelogsComponent]
})
export class ChangelogsModule { }