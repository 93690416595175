<ion-content>
	<ion-input #input (ionInput)="onInputChanged($event)"></ion-input>
	<div class="current-status">
		Articles ({{ acquisitions?.length }}) (total {{ totalScanned }}):

		<ng-container #scanArticleSummary></ng-container>
	</div>

	<ion-list class="specific-summaries">
		<ng-container #scanArticlesSummaries>
		</ng-container>
	</ion-list>

	<ion-infinite-scroll threshold="200%" (ionInfinite)="loadNextWage()">
		<ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Chargement ...">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<!-- Validation de la modale. -->
	<ion-fab-button color="success" (click)="validate()">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>