import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { ComponentBase } from '@calaosoft/osapp/helpers/ComponentBase';
import { BarcodeReaderService } from '@calaosoft/osapp/modules/logistics/barcode-reader/services/barcode-reader.service';
import { IBarcodeAcquisition } from '@calaosoft/osapp/modules/logistics/devices/models/ibarcode-acquisition';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: "merch-anomaly-barcode-reader",
	templateUrl: './anomaly-barcode-reader.component.html',
	styleUrls: ['./anomaly-barcode-reader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnomalyBarcodeReaderComponent extends ComponentBase {

	//#region FIELDS

	/** Événement de notification lors d'une lecture de code-barres. */
	@Output("onRead") private readonly moReadEvent = new EventEmitter<string>();

	//#endregion

	//#region METHODS

	constructor(private readonly isvcBarcodeReader: BarcodeReaderService, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);

		this.isvcBarcodeReader.onBarcodeRead().pipe(
			tap((paAcquisitions: IBarcodeAcquisition[]) => this.moReadEvent.emit(ArrayHelper.getFirstElement(paAcquisitions).code)),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	/** Lit un code-barre unique et notifie son parent de sa valeur. */
	public readAsync(): Promise<void> {
		return this.isvcBarcodeReader.readAsync();
	}

	//#endregion

}