import { ETypeAction } from "./etype-action";
import { IControlAction } from "./icontrol-action";

export abstract class ControlAction implements IControlAction {

	//#region PROPERTIES

	/** @implements */
	public abstract readonly type: ETypeAction;

	//#endregion

}