import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: "calao-catalog-item-finder",
	templateUrl: './catalog-item-finder.component.html',
	styleUrls: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogItemFinderComponent {

	//#region FIELDS

	/** Événement lors de la validation d'un scan manuel. */
	@Output("onManualScanValidated") private readonly moManualScanValidatedEvent = new EventEmitter<string>();
	/** Événement lors de la validation d'un scan par lecture de code-barres. */
	@Output("onBarcodeScanValidated") private readonly moBarcodeScanValidatedEvent = new EventEmitter<string>();

	//#endregion

	//#region METHODS

	constructor() { }

	/** Notifie le parent qu code-barres a été lu.
	 * @param psEvent Événement correspondant au code barre lu.
	 */
	public raiseValidBarcodeScan(psEvent: string): void {
		this.moBarcodeScanValidatedEvent.emit(psEvent);
	}

	/** Notifie le parent qu'un scan manuel a été fait seulement si la valeur entrée est valide.
	 * @param psEvent Événement de changement de valeur dans l'input.
	 */
	private raiseValidManualScan(psItemId: string): void {
		this.moManualScanValidatedEvent.emit(psItemId);
	}

	public onItemIdTyped(psTypedText: string): void {
		if (psTypedText?.length === 7) {
			this.raiseValidManualScan(psTypedText);
		}
	}

	//#endregion

}