import { Injectable } from '@angular/core';

import { SecurityService } from '../services/security.service';

/** Garde qui déconnecte l'utilisateur courant. */
@Injectable({ providedIn: "root" })
export class LogOutGuard  {

	//#region METHODS

	constructor(private readonly isvcSecurity: SecurityService) { }

	public canActivate(): Promise<boolean> {
		return this.logOutAsync();
	}

	public canLoad(): Promise<boolean> {
		return this.logOutAsync();
	}

	private logOutAsync(): Promise<boolean> {
		console.debug("DSCNCT.G::User log out.");
		return this.isvcSecurity.logOutAsync();
	}

	//#endregion

}