<ng-container *ngIf="movements$ | async as movements; else nothingSelected;">
	<ng-container *ngIf="movements.length !== 0; else empty;">
		<ng-container *ngTemplateOutlet="displayMovements; context: { movements: movements }"></ng-container>
	</ng-container>
</ng-container>

<ng-template #nothingSelected>
	<div class="safe-area">
		<calao-blank-information icon="cactus"
			title="Sélectionnez un client dans la liste pour afficher l'historique article"
			subtitle="Aucun historique à afficher"></calao-blank-information>
	</div>
</ng-template>

<ng-template #empty>
	<div class="safe-area">
		<ion-note>Aucun mouvement de cet article chez le client.</ion-note>
	</div>
</ng-template>

<ng-template #displayMovements let-movements="movements">
	<ion-grid>
		<ion-row class="first-row" color="primary">
			<ion-col>Semaine</ion-col>
			<ion-col>Traitement</ion-col>
			<ion-col>Prix</ion-col>
			<ion-col>Quantité</ion-col>
		</ion-row>

		<ion-row *ngFor="let movement of movements">
			<ion-col class="date-label">{{movement.dateLabel ?? ""}}</ion-col>
			<ion-col>
				{{movement.state?.label ?? ""}}
			</ion-col>
			<ion-col>
				<merch-article-chip-price *ngIf="movement.price" [priceType]="movement.price.type"
					[priceValue]="movement.price.value"></merch-article-chip-price>
			</ion-col>
			<ion-col class="qty">{{movement.qty}}</ion-col>
		</ion-row>
	</ion-grid>
</ng-template>