<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<div class="location-bloc-area">
		<ion-input [value]="fieldValue" (ionInput)="fieldValue = $event.detail.value?.toString()" (ionBlur)="onBlurEvent()"
			mode="md" debounce="1000" type="text" placeholder="Adresse complète">
			<ion-icon *ngIf="observableHasPlaceCoords.value$ | async " name="location"></ion-icon>
		</ion-input>
		<ion-note *ngIf="observableHasPlaceCoords.value$ | async "> {{this.params.geocodingSuccessLabel}}
		</ion-note>

		<ion-list [hidden]="!(observableItems.length$ | async)">
			<ion-item *ngFor="let item of observableItems" (click)="onSelecteItem(item, fieldValue)">
				<ion-label>
					{{item.street}} {{item.zipCode ?? ""}} {{item.city}}
				</ion-label>
				<ion-note color="medium" slot="end">{{item.distance}} km</ion-note>
			</ion-item>
		</ion-list>
	</div>
</ng-template>

<ng-template #inline>
	<calao-inline-field-layout [icon]="layoutParams.icon" [label]="layoutParams.label"
		[separator]="layoutParams.separator" [hideLabel]="observableHideLabel.value$ | async"
		(onFieldClicked)="observableModalInputValue.value = fieldValue; observableIsModalOpen.value = true">
		<calao-inline-field-layout-content>
			<span class="address">{{ fieldValue }}</span>
		</calao-inline-field-layout-content>
	</calao-inline-field-layout>

	<ion-modal [isOpen]="observableIsModalOpen.value$ | async" (didDismiss)="observableIsModalOpen.value = false">
		<ng-template>
			<ion-header>
				<ion-toolbar>
					<ion-buttons slot="start">
						<ion-button color="dark" shape="round" (click)="observableIsModalOpen.value = false">
							<ion-icon slot="icon-only" name="close-circle"></ion-icon>
						</ion-button>
					</ion-buttons>
					<ion-title>Ajouter un lieu</ion-title>
				</ion-toolbar>
			</ion-header>
			<ion-content *ngIf="observableIsModalOpen.value$ | async" class="ion-padding">
				<calao-filter-bar-search-input [placeholder]="layoutParams.label" [clearInput]="false"
					[value]="observableModalInputValue.value$ | async" [hasModal]="false" [debounce]="1000" [autofocus]="true"
					(onValueChange)="observableModalInputValue.value = $event">
					<ion-button *ngIf="observableModalInputValue.value$ | async" color="primary" slot="end"
						(click)="observableModalInputValue.value = ''" fill="clear" shape="round" class="filter-button">
						<ion-icon slot="icon-only" name="close"></ion-icon>
					</ion-button>
					<ion-button (click)="locate()" color="primary" fill="outline" slot="end" class="filter-button">
						<ion-icon slot="icon-only" name="location"></ion-icon>
					</ion-button>
				</calao-filter-bar-search-input>

				<ion-list [hidden]="!(observableItems.length$ | async)">
					<ion-item *ngFor="let item of observableItems" (click)="onSelecteItem(item, observableModalInputValue.value)">
						<ion-label>
							{{item.street}} {{item.zipCode ?? ""}} {{item.city}}
						</ion-label>
						<ion-note *ngIf="item.distance >= 0" color="medium" slot="end">{{item.distance}} km</ion-note>
					</ion-item>
				</ion-list>
			</ion-content>

			<ion-footer>
				<ion-toolbar>
					<ion-button slot="end" shape="round"
						(click)="fieldValue = observableModalInputValue.value; observableIsModalOpen.value = false">
						Enregistrer
					</ion-button>
				</ion-toolbar>
			</ion-footer>
		</ng-template>
	</ion-modal>
</ng-template>