<ng-container
	*ngTemplateOutlet="(observableIsReadOnly.value$ | async) ? readOnlyTemplate : editTemplate"></ng-container>

<ng-template #readOnlyTemplate>
	<div class="readonly-container">
		<ion-icon class="notification" name="notifications"></ion-icon>
		<ion-label>{{ observableReadonlyLabel.value$ | async }}</ion-label>
	</div>
</ng-template>

<ng-template #editTemplate>
	<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

	<ng-template #normal>
		<div class="reminder-selector" (click)="openReminderAddModal()">
			<ion-label class="placeholder">Ajouter un rappel ...</ion-label>
			<ion-icon name="caret-down"></ion-icon>
		</div>

		<div class="tag-container">
			<ion-button *ngFor="let notification of observableNotifications" class="tag-btn" mode="ios" shape="round"
				color="primary" (click)="removeNotification(notification)">
				{{ getButtonlabel(notification.before) }}
				<ion-icon name="close"></ion-icon>
			</ion-button>
		</div>
	</ng-template>

	<ng-template #inline>
		<calao-inline-list-field-layout [icon]="layoutParams.icon" [addButtonLabel]="layoutParams.addButtonLabel"
			[separator]="layoutParams.separator" (onAddClicked)="openReminderAddModal()">
			<calao-deletable-tags-list *ngIf="(observableNotificationTags.length$ | async)"
				[tags]="observableNotificationTags.changes$ | async"
				(onRemoveTagClicked)="removeNotificationTag($event)"></calao-deletable-tags-list>
		</calao-inline-list-field-layout>
	</ng-template>
</ng-template>