import { NgModule } from '@angular/core';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { BluetoothService } from './bluetooth.service';

/**
 * Module de gestion du Bluetooth de Logistics.
 */
@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [BluetoothService, BluetoothSerial]
})
export class BluetoothModule {
	constructor() {
	}
}