import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IonImg, IonItemSliding } from '@ionic/angular';
import { AlertButton, AlertInput } from '@ionic/core';
import { EMPTY, Observable, defer, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ESortOrder } from '../../../model/ESortOrder';
import { ListComponentBase } from '../../../model/ListComponentBase';
import { EBarElementDock } from '../../../model/barElement/EBarElementDock';
import { EBarElementPosition } from '../../../model/barElement/EBarElementPosition';
import { IBarElement } from '../../../model/barElement/IBarElement';
import { IFormDescriptor } from '../../../model/forms/IFormDescriptor';
import { IFormDescriptorDataSource } from '../../../model/forms/IFormDescriptorDataSource';
import { IFormListEvent } from '../../../model/forms/IFormListEvent';
import { IFormListParams } from '../../../model/forms/IFormListParams';
import { IItemOption } from '../../../model/forms/IItemOption';
import { IListDefinition } from '../../../model/forms/IListDefinition';
import { IListDefinitionsField } from '../../../model/forms/IListDefinitionsField';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { IPicture } from '../../../model/picture/IPicture';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { ISearchOptions } from '../../../model/search/ISearchOptions';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { EInput } from '../../../model/uiMessage/EInput';
import { IEntity } from '../../../modules/entities/models/ientity';
import { EntitiesService } from '../../../modules/entities/services/entities.service';
import { HasPermissions } from '../../../modules/permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../modules/permissions/models/epermission-scopes';
import { IHasPermission, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { FavoritesService } from '../../../modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '../../../modules/preferences/model/IPreferences';
import { PageManagerService } from '../../../modules/routing/services/pageManager.service';
import { ESelectorDisplayMode } from '../../../modules/selector/selector/ESelectorDisplayMode';
import { PatternPipe } from '../../../pipes/pattern.pipe';
import { ApplicationService } from '../../../services/application.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FormsService } from '../../../services/forms.service';
import { InjectorService } from '../../../services/injector.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';

/** Composant affichant une liste référençants des formulaires. */
@Component({
	selector: "calao-formList",
	templateUrl: 'formList.component.html',
	styleUrls: ['./formList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent<T extends IEntity> extends ListComponentBase<T>
	implements OnInit, OnChanges, AfterViewInit, IHasPermission, IFormListParams<T> {

	//#region FIELDS

	/** Texte pour le loader lors d'une compilation de vue. */
	private static readonly C_VIEW_COMPILATION_LOADER_TEXT = "Indexation des données : cette étape peut nécessiter un moment lors du premier lancement de l'application.";
	/** "duplicateName" */
	private static readonly C_DUPLICATE_NAME = "duplicateName";
	/** "_cmp_params" */
	private static readonly C_COMPONENT_ENTRIES_PARAMS_STRING = "_cmp_params";
	/** Mode d'affichage par défaut : 'tab'. */
	private static readonly C_DEFAULT_DISPLAY_TYPE = "tab";
	/** Taille par défaut en pixel des options dans les itemSliding. */
	private static readonly C_DEFAULT_ITEM_OPTION_SIZE = 80;
	/** Texte par défaut du bouton ajouter. */
	private static readonly C_ADD_BUTTON_TEXT = "Ajouter";
	/** Nombre maximal de champs dans la liste. */
	private static readonly C_MAX_NUMBER_FIELDS = 7;
	private static readonly C_SEND_EVENT_ACTION = "sendEvent";

	/** Contient les champs marqués comme searchable. */
	private maSearchableFields: Array<IListDefinitionsField>;
	/** Définition donnant un grand nombre d'informations sur la formList. */
	protected moListDefinition: IListDefinition<T>;
	/** Pattern à utiliser pour générer l'identifiant d'un nouvel élément. */
	private msDocumentIdPattern: string;

	//#endregion

	//#region PROPERTIES

	public static readonly C_FAVORITES_EVENT_ID = "saveFavorite";
	public readonly favoritesEventId: string = FormListComponent.C_FAVORITES_EVENT_ID;

	/** Identificateur du FormDescription utilisé par la liste. */
	@Input() public idFormDesc: string;
	/** @implements */
	@Input() public idFormList: string;
	/** @implements */
	@Input() public customEntries: Array<T>;
	/** @implements */
	@Input() public assignedInstanceId: string;
	/** @implements */
	@Input() public getItemCssClass?: (poItem: T) => string;
	/** @implements */
	@Input() public canDisplayOptions: (poItem: T) => boolean;
	/** @implements */
	@Input() public customGetEntries: () => Observable<Array<T>>;
	/** @implements */
	@Input() public customItemClicked: (poModel: T) => void;

	/** Donne le titre du formList. */
	public title: string;
	/** Contient les champs non marqués hidden. */
	public displayFields: Array<IListDefinitionsField<T>>;
	/** Css. */
	public gridCssClass: string;
	public rowCssClass: string;
	/** Indique si la duplication des entrées est permise ou non. */
	public allowDuplicate = false;
	/** Type d'affichage (list ou tab). */
	public displayType: string;
	/** Tableau des différentes options pour les itemSliding. */
	public itemOptions: Array<IItemOption>;
	/** Indique si on a une searchBox. */
	public hasSearchbox = false;
	/** Définit si le focus doit être mis sur la searchbox dès que c'est possible. */
	public autofocusSearchbox = false;
	/** Indique si le composant like doit être afficher ou non, non par défaut (temporaire). */
	public hasLike = false;
	/** Indique si on veut pouvoir ouvrir les ItemSliding ou non. */
	public itemSlidingEnabled: boolean;
	/** Indique si le tri est inversé. */
	public isReverseSorted = false;
	/** Représente l'énumération sous forme d'objet. */
	public avatarSize: typeof EAvatarSize = EAvatarSize;
	/** Affiche un message si la liste ne contient aucun élément. */
	public emptyMessage: string;
	public permissionScope: EPermissionScopes;
	/** Texte à afficher pendant le chargement. */
	public loadingText: string = ApplicationService.C_LOAD_DATA_LOADER_TEXT;
	public favorites: IPreferences;
	public hasFavorites: boolean;

	private mbIsLoading = true;
	/** Indique si le chargement des données est en cours, `true` pendant le chargement de données. */
	public get isLoading(): boolean { return this.mbIsLoading; }
	public set isLoading(pbIsLoading: boolean) {
		if (pbIsLoading !== this.mbIsLoading) {
			this.mbIsLoading = pbIsLoading;
			this.detectChanges();
		}
	}

	private mnPrefillThreshold: number;
	/** Seuil du nombre de clients à atteindre avant de ne plus afficher tous les clients  */
	public get prefillThreshold(): number { return this.mnPrefillThreshold; }

	@HasPermissions({ permission: "delete" })
	/** Indique si la suppression des entrées est permie ou non. */
	public get allowDelete(): boolean { return this.moListDefinition && this.moListDefinition.allowDelete; };

	@HasPermissions({ permission: "create" })
	public get hasAddButton(): boolean { return this.moListDefinition && this.moListDefinition.hasAddButton; };

	/** Descripteur de formulaire du composant. */
	protected moFormDescriptor: IFormDescriptor<T>;
	protected get formDescriptor(): IFormDescriptor<T> { return this.moFormDescriptor; }
	protected set formDescriptor(poValue: IFormDescriptor<T>) {
		if (poValue)
			this.moFormDescriptor = poValue;
	}

	public readonly isvcPermissions: PermissionsService;

	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	//#endregion

	//#region METHODS

	constructor(
		poParentPage: DynamicPageComponent<ComponentBase>,
		protected isvcPageManager: PageManagerService,
		protected isvcUiMessage: UiMessageService,
		protected isvcForms: FormsService<T>,
		private isvcEntity: EntityLinkService,
		private isvcEntities: EntitiesService,
		private ioPatternPipe: PatternPipe,
		private ioRenderer: Renderer2,
		private isvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		protected ioRoute: ActivatedRoute,
		protected ioRouter: Router,
		/** Service de gestion des requêtes en base de données. */
		protected isvcStore: Store,
		private isvcFavorites: FavoritesService
	) {
		super(poParentPage, poChangeDetectorRef);

		// Injection dynamique pour éviter les répercussions sur l'appel au constructeur dans les classes filles.
		this.isvcPermissions = InjectorService.instance.get<PermissionsService>(PermissionsService);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		if (!StringHelper.isBlank(this.assignedInstanceId))
			this.setInstanceId(this.assignedInstanceId);
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.loadData()
			.pipe(
				mergeMap((pbResult: boolean) => {
					if (ArrayHelper.hasElements(this.documents) && this.itemOptions?.some((poOption: IItemOption) => poOption.key === this.favoritesEventId)) {
						this.hasFavorites = true;

						return this.isvcFavorites.get(IdHelper.getPrefixFromId(ArrayHelper.getFirstElement(this.documents)?._id), true)
							.pipe(
								tap((poFavorites: IPreferences) => {
									this.favorites = poFavorites;
									this.detectChanges();
								})
							);
					}
					return of(pbResult);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe(
				(_: boolean) => { },
				poError => console.error("FRML.C::", poError)
			);
	}

	/** Appellée quand un changement a lieu dans les propriétés bindées. */
	public ngOnChanges(): void {
		// Le ngChange peut être appelé lors de la résolution d'un observable servant à fournir des entries "custom" venant d'un autre composant.
		if (this.customEntries) {
			console.debug("FRML.C::Custom entries model changed.");
			this.documents = this.customEntries;

			// On doit avoir les colonnes de prêtes sinon c'est que la definition n'est pas encore disponible.
			if (this.displayFields) {
				this.sortDocuments();
				this.isReverseSorted = false; // On réinitialise le reverse.
				this.manageToolbar();
			}

			this.detectChanges();
		}
	}

	protected override createBarElements(): IBarElement[] {
		const laBarElements: IBarElement[] = [];

		if (this.hasAddButton) {
			const loAddBarElement: IBarElement = {
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.onAddItem(),
				priority: 100
			};
			laBarElements.push(loAddBarElement);
		}

		return laBarElements;
	}

	protected override createSearchOptions(): ISearchOptions<T> {
		return {
			hasPreFillData: this.moListDefinition.hasPreFillData,
			isSearchPanelEnable: this.moListDefinition.isSearchPanelEnable,
			locationSearch: this.moListDefinition.locationSearch,
			searchableFields: this.maSearchableFields,
			searchboxPlaceholder: this.moListDefinition.searchboxPlaceholder,
			prefillThreshold: this.moListDefinition.prefillThreshold
		};
	}

	/** Permet de passer à une page de formulaire.
	 * @param poDocument Document à utiliser pour le formulaire cible.
	 */
	public override onItemClicked(poDocument: T): void {
		super.onItemClicked(poDocument);

		if (this.customItemClicked)
			this.customItemClicked(poDocument);
		else
			this.isvcEntities.navigateToEntityViewAsync(poDocument, this.ioRoute);
	}

	public override onItemOptionClicked(poDocument: T, psAction: string, poItemSliding: IonItemSliding, poOption: IItemOption): void {
		super.onItemOptionClicked(poDocument, psAction, poItemSliding, poOption);

		if (psAction === FormListComponent.C_SEND_EVENT_ACTION) {
			const loFormListEvent: IFormListEvent<T> = {
				id: poOption.key,
				model: poDocument,
				descriptor: this.idFormDesc,
				dataSource: this.moDataSource
			};
			this.isvcForms.raiseFormListEvent(loFormListEvent);
		}
	}

	public onFavoriteClicked(poDocument: T): void {
		const loFormListEvent: IFormListEvent<T> = {
			id: FormListComponent.C_FAVORITES_EVENT_ID,
			model: poDocument,
			descriptor: this.idFormDesc,
			dataSource: this.moDataSource
		};
		this.isvcForms.raiseFormListEvent(loFormListEvent);
	}

	protected override prepareDataSource(): void {
		// Recherche parmi les DataSources déclarées dans le descripteur celle utilisée par la définition de liste actuelle.
		const loFormDescDataSource: IFormDescriptorDataSource | undefined = ArrayHelper.hasElements(this.formDescriptor.dataSources) ?
			this.formDescriptor.dataSources?.find((poItem: IDataSource<T>) => poItem.id === this.moListDefinition.dataSource) : undefined;

		this.moDataSource = this.isvcForms.prepareFormDataSource(loFormDescDataSource);

		if (this.hasSearchbox) {
			const laDataSourceFields: Array<keyof T> = [];
			const laKeys: Array<keyof T> = (this.searchOptions.searchableFields ?? []).concat(this.moListDefinition.fields ?? [])
				.map((poSearchableField: IListDefinitionsField<T>) => poSearchableField.key);

			ArrayHelper.unique(laKeys)
				.forEach((psField: keyof T) => laDataSourceFields.push(...this.ioPatternPipe.getResolvedKeys(psField))); // On résout les possibles patterns.

			this.moDataSource.fields = laDataSourceFields;
		}
	}

	/** Ordonne les entrées avec la clé en paramètre. Si la clé est déjà la sortKey, inverse la liste.
	 * @param poCol Clé de tri des données.
	 */
	public orderOrReverse(poCol: IListDefinitionsField): void {
		if (!poCol.isPicture || (poCol.isPicture && !StringHelper.isBlank(poCol.searchableKey))) {

			if (this.sortKey === (poCol.searchableKey ? poCol.searchableKey : poCol.key)) {
				this.isReverseSorted = !this.isReverseSorted;
				this.filteredDocuments.reverse();
			}
			else {
				this.sortKey = (poCol.searchableKey ? poCol.searchableKey : poCol.key) as keyof T;
				this.sortDocuments();
				this.isReverseSorted = false; // On réinitialise le reverse.
			}

			this.filteredDocuments = [...this.filteredDocuments];
			this.detectChanges();
		}
	}

	/** Return true si le formList est en cours d'initialisation. */
	public isInitializing(): boolean {
		return this.moParentPage.isInitializing();
	}

	/** Charge et affiche les données de la formlist. */
	private loadData(): Observable<boolean> {
		return of(null)
			.pipe(
				mergeMap(() => this.isvcForms.getFormDescriptor(this.idFormDesc)),
				mergeMap((poFormDescriptor: IFormDescriptor<T>) => poFormDescriptor ? this.onGetFormDescriptorSuccess(poFormDescriptor) : of(undefined)),
				tap(
					_ => {
						this.isLoading = false;
						this.detectChanges();
					},
					_ => this.isLoading = false
				),
				takeUntil(this.destroyed$)
			);
	}

	/** La récupération du descripteur de formulaire a réussi, on poursuit les traitements.
	 * @param poFormDescriptor Descripteur du formulaire récupéré.
	 */
	private onGetFormDescriptorSuccess(poFormDescriptor: IFormDescriptor<T>): Observable<boolean> {
		let loGotEntries$: Observable<boolean>;

		this.formDescriptor = poFormDescriptor;
		this.initAssignments();
		this.searchOptions = this.createSearchOptions();

		// Mis à jour de l'UI. Affiche la searchBar avec les searchOptions même si les données ne sont pas chargées.
		this.detectChanges();

		this.initDisplayFields();

		// Le tri par défaut est celui du premier champ valide (mode 'list' -> index 0 peut être undefined (pas de photo)).
		this.sortKey = (this.moListDefinition.sortBy ?
			this.moListDefinition.sortBy :
			this.displayFields.find((poItem: IListDefinitionsField<T>) => poItem && poItem.key !== "picture").key) as keyof T;

		if (this.moListDefinition.dataSource !== FormListComponent.C_COMPONENT_ENTRIES_PARAMS_STRING) {
			this.prepareDataSource();
			loGotEntries$ = this.loadEntries();
		}
		else if (this.documents) {
			this.isReverseSorted = false; // On réinitialise le reverse.
			this.manageToolbar();
			loGotEntries$ = of(true);
		}
		else
			loGotEntries$ = of(false);

		return loGotEntries$;
	}

	/** Réalise les affectations nécessaires lors de l'initialisation. */
	private initAssignments(): void {
		this.msDocumentIdPattern = this.formDescriptor.entryIdPattern;

		this.permissionScope = this.formDescriptor.permissionScope;
		this.moListDefinition = this.getListDefinitionFromDescriptor();

		this.maSearchableFields = this.fieldsFilterByDataSource();

		this.moListDefinition.buttonText = this.moListDefinition.buttonText || FormListComponent.C_ADD_BUTTON_TEXT;
		this.title = this.moListDefinition.title;
		this.hasSearchbox = this.moListDefinition.hasSearchbox;
		this.autofocusSearchbox = this.moListDefinition.autofocusSearchbox || false;
		this.hasLike = this.moListDefinition.hasLike;
		this.gridCssClass = this.moListDefinition.gridCssClass;
		this.rowCssClass = this.moListDefinition.rowCssClass;
		this.allowDuplicate = this.moListDefinition.allowDuplicate;
		this.displayType = this.moListDefinition.displayType || FormListComponent.C_DEFAULT_DISPLAY_TYPE;
		this.itemOptions = this.filterNonAllowedOptions(this.moListDefinition.itemOptions || []);
		this.itemSlidingEnabled = ArrayHelper.hasElements(this.itemOptions);
		this.emptyMessage = this.moListDefinition.emptyMessage;
		this.meSortOrder = this.moListDefinition.sortOrder || ESortOrder.ascending;
		this.mnPrefillThreshold = this.moListDefinition.prefillThreshold;

		this.moListDefinition.itemOptionSize = this.moListDefinition.itemOptionSize || FormListComponent.C_DEFAULT_ITEM_OPTION_SIZE;
	}

	protected filterNonAllowedOptions(paOptions: IItemOption[]): IItemOption[] {
		return paOptions.filter((poOption: IItemOption) =>
			StringHelper.isBlank(poOption.permission) || this.isvcPermissions.evaluatePermission(this.permissionScope, poOption.permission)
		);
	}

	/** Initialisation des colonnes affichées. */
	private initDisplayFields(): void {
		this.displayFields = [];

		if (this.displayType === FormListComponent.C_DEFAULT_DISPLAY_TYPE) { // Mode 'tab'.
			for (let lnIndex = 0; lnIndex < this.moListDefinition.fields.length; ++lnIndex) {
				if (!this.moListDefinition.fields[lnIndex].hidden)
					this.displayFields.push(this.moListDefinition.fields[lnIndex]);
			}
		}
		else { // Mode 'list'.
			// On ajoute la première occurrence d'un champ marqué comme image (isPicture: true), undefined si pas d'occurrence.
			this.displayFields.push(this.moListDefinition.fields.find((poField: IListDefinitionsField) => poField.isPicture && !poField.hidden));

			let lnFieldCount = 0;
			for (let lnIndex = 0; lnIndex < this.moListDefinition.fields.length; ++lnIndex) {
				const loCurrentField: IListDefinitionsField = this.moListDefinition.fields[lnIndex];

				if (!loCurrentField.hidden && !loCurrentField.isPicture) {
					this.displayFields.push(loCurrentField);

					if (++lnFieldCount === FormListComponent.C_MAX_NUMBER_FIELDS)
						break;
				}
			}
		}
	}

	/** Récupère les entrées de la formList et les trie. */
	private loadEntries(): Observable<boolean> {
		let loGetEntries$: Observable<T[]>;

		if (this.customGetEntries) {
			loGetEntries$ = this.customGetEntries()
				.pipe(catchError(poError => { console.warn(`FRML.C:: Can't retrieve entries from customGetEntries :`, poError); return throwError(poError); }));
		}
		else {
			loGetEntries$ = this.checkCompiledView()
				.pipe(
					mergeMap(() => this.isvcForms.getEntries(this.moDataSource)),
					catchError(poError => { console.warn(`FRML.C:: Cannot retreive entries for form descriptor ${this.idFormDesc}:`, poError); return throwError(poError); })
				);
		}

		return defer(() => { this.manageToolbar(); return loGetEntries$; })
			.pipe(
				map((paEntries: T[]) => {
					this.documents = paEntries;
					this.sortDocuments();
					this.isReverseSorted = false; // On réinitialise le reverse.
					this.manageToolbar();
					return true;
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Vérifie que la vue a été compilée et change le texte du loader si ce n'est pas le cas, ne fait rien si on n'utilise pas de vue pour requêter. */
	private checkCompiledView(): Observable<void> {
		if (StringHelper.isBlank(this.moDataSource.viewName))
			return of(undefined);
		else
			return this.presentEntryViewCompilationLoaderIfRequired(this.moDataSource.viewName).pipe(mapTo(undefined));
	}

	/** Vérifie si la vue demandée a été compilée ou non et affiche un loader si besoin.
 * ### ATTENTION : La disparition du loader doit être gérée par l'appelant !
 * @param psViewName Nom de la vue dont il faut vérifier si elle est compilée ou non.
 */
	private presentEntryViewCompilationLoaderIfRequired(psViewName: string): Observable<boolean> {
		return this.isvcStore.isViewCompiled(psViewName)
			.pipe(
				tap((pbIsCompiled: boolean) => this.loadingText = pbIsCompiled ?
					ApplicationService.C_LOAD_DATA_LOADER_TEXT : FormListComponent.C_VIEW_COMPILATION_LOADER_TEXT
				)
			);
	}

	/** Récupère la `listDefinition` à partir du formDescriptor. */
	private getListDefinitionFromDescriptor(): IListDefinition<T> {
		// S'il existe, on prend l'id du FormList, sinon celui par défaut du descriptor.
		const lsListDefinitionId: string | undefined = !StringHelper.isBlank(this.idFormList) ? this.idFormList : this.formDescriptor.defaultList;
		const loListDefinition: IListDefinition<T> | undefined = this.formDescriptor.listDefinitions?.find((poItem: IListDefinition<T>) => lsListDefinitionId === poItem.id);

		if (!loListDefinition)
			throw new Error(`Impossible de trouver la formlist ${lsListDefinitionId} dans le descripteur.`);

		return loListDefinition;
	}

	/** Regroupe la liste des champs qui sont "recherchables" en fonction de la source de données afin par exemple en cas de recherche géographique
	 * de regrouper les champs latitude et longitude en un seul champ coordonnées qui requête sur la même source de données.
	 */
	private fieldsFilterByDataSource(): IListDefinitionsField[] {
		const laFilteredFields: IListDefinitionsField[] = [];
		const laFieldsCopy: IListDefinitionsField[] | undefined = ObjectHelper.clone(this.moListDefinition.fields);

		if (laFieldsCopy) {
			for (let lnIndex = 0, lnLength = laFieldsCopy.length; lnIndex < lnLength; ++lnIndex) {

				if (laFieldsCopy[lnIndex].searchableKey) {
					laFieldsCopy[lnIndex].key = laFieldsCopy[lnIndex].searchableKey;
					laFilteredFields.push(laFieldsCopy[lnIndex]);
				}
				else if (laFieldsCopy[lnIndex].searchable !== false && !laFieldsCopy[lnIndex].isPicture)
					laFilteredFields.push(laFieldsCopy[lnIndex]);
			}
		}

		return laFilteredFields;
	}

	/** Affiche un toast lorsqu'une erreur de suppression ou duplication par exemple est survenue.
	 * @param psMessage Message à afficher dans un toast pour informer l'utilisateur.
	 */
	private showError(psMessage: string): Observable<never> {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: psMessage, header: "Erreur", cssClass: "errorToast" }));
		return EMPTY;
	}

	/** Affiche une popup pour valider ou non la suppression de l'élément.
	 * @param poDocument Objet qu'il faut potentiellement supprimer.
	 */
	public confirmDelete(poDocument: T): void {
		const lfExec: () => void = () => {
			this.execDelete(poDocument)
				.pipe(takeUntil(this.destroyed$))
				.subscribe();
		};

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: "Supprimer cet élément ?",
				header: "Suppression",
				buttons: [
					{ text: "Annuler" },
					{ text: "Continuer", cssClass: "deleteButton", handler: () => lfExec }
				]
			})
		);
	}

	/** Affiche une popup avec un input pour valider ou non la duplication de l'objet.
	 * @param poDocument Objet qu'il faut potentiellement dupliquer.
	 * @param psTitle Titre de la popoup, optionnel.
	 * @param psMessage Message de la popup, optionnel.
	 */
	public confirmDuplicate(poDocument: T, psTitle?: string, psMessage?: string): void {
		if (StringHelper.isBlank(psMessage))
			psMessage = "Souhaitez-vous dupliquer cet élément ?";

		if (StringHelper.isBlank(psTitle))
			psTitle = "Duplication";

		const loPopupParams = new ShowMessageParamsPopup({
			message: psMessage,
			header: psTitle,
			buttons: this.getConfirmDuplicateButtons(poDocument),
			inputs: this.getConfirmDuplicateInputs()
		});

		this.isvcUiMessage.showMessage(loPopupParams);
	}

	private getConfirmDuplicateButtons(poDocument: T): AlertButton[] {
		return [
			{ text: "Annuler", },
			{
				text: "Dupliquer",
				cssClass: "deleteButton",
				handler: poInputData => this.duplicateItem(poDocument, poInputData[FormListComponent.C_DUPLICATE_NAME].trim())
			}
		] as AlertButton[];
	}

	private getConfirmDuplicateInputs(): AlertInput[] {
		return [
			{
				type: EInput.text,
				name: FormListComponent.C_DUPLICATE_NAME,
				value: "",
				label: "Nom du nouvel élément ?",
				placeholder: "",
				id: "",
				checked: false
			}
		] as AlertInput[];
	}

	/** Duplique l'objet souhaité.
	 * @param poDocument Objet qu'il faut dupliquer.
	 * @param psName Objet contenant les différents inputs de l'alerte avec leur valeur.
	 */
	private duplicateItem(poDocument: T, psName: string): void {

		if (!StringHelper.isBlank(psName)) { // On veut dupliquer donc on doit regénérer l'id on le supprime ainsi que la révision.
			const loDuplicateDocument: T = ObjectHelper.clone(poDocument);
			delete loDuplicateDocument._id;
			delete loDuplicateDocument._rev;

			if (this.moListDefinition.duplicateKey) // Si le champ "duplicateKey" est renseigné, faut vérifier les doublons.
				loDuplicateDocument[this.moListDefinition.duplicateKey] = psName;

			if (this.hasDuplicate(loDuplicateDocument)) // Si un double est présent, on arrête et on le fait savoir.
				this.confirmDuplicate(poDocument, "Erreur de duplication", "Un élément porte déjà ce nom, veuillez le renommer.");

			else { // Sinon, on peut dupliquer l'élément.
				loDuplicateDocument._id = this.isvcPatternResolver.generateCustomId(this.msDocumentIdPattern, loDuplicateDocument); // Création d'un nouvel id customisé ou non.

				this.isvcForms.postFormEntry(loDuplicateDocument, this.moDataSource.databaseId)
					.pipe(
						catchError(poError => {
							console.error(`FRML.C:: Duplicate document failed:`, poError);
							return this.showError("L'application n'a pas pu dupliquer cet élément.");
						}),
						takeUntil(this.destroyed$)
					)
					.subscribe();
			}
		}
	}

	/** Exécute la suppression du document.
	 * @param poDocument Document à supprimer.
	 */
	private execDelete(poDocument: T): Observable<IStoreDataResponse> {
		return this.isvcForms.deleteFormEntry(poDocument, this.moDataSource.databaseId)
			.pipe(
				catchError(poError => {
					console.error(`FRML.C:: Erreur suppression document ${poDocument._id} :`, poError);
					return this.showError("L'application n'a pas pu supprimer cet élément.");
				})
			);
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poData Données contenant les informations de l'avatar à récupérer.
	 */
	public getAvatar(poData: IPicture): IAvatar {
		return {
			text: "",
			size: EAvatarSize.big,
			data: StringHelper.isBlank(poData.url) ? poData.base64 : poData.url,
			mimeType: poData.mimeType,
			guid: poData.guid
		};
	}

	/** Retourne `true` si un doublon est présent entre la clé de duplication de l'élément et le duplicat, `false` sinon.
	 * @param poDuplicateDocument Document dupliqué.
	 */
	private hasDuplicate(poDuplicateDocument: T): boolean {
		const lsDuplicateKey: string = this.moListDefinition.duplicateKey;
		const loDuplicateValue: any = poDuplicateDocument[lsDuplicateKey];

		return loDuplicateValue ? this.documents.some((poDocument: T) => poDocument[lsDuplicateKey] === loDuplicateValue) : false;
	}

	/** Création d'un nouvel item à partir du bouton d'ajout. */
	public onAddItem(): void {
		const loNavigationExtras: NavigationExtras = {};

		if (this.isvcEntity.currentEntity) {
			// S'il existe un parent, transmet le parent à la route suivante.
			// La navigation se fait de façon absolue par rapport au type de l'enfant.
			loNavigationExtras.state = { parentModel: this.isvcEntity.currentEntity };
			//TODO Gérer le cas des rapports dans une page spécifique !!!
			this.ioRouter.navigate(["rapports", ERouteUrlPart.new], loNavigationExtras);
		}
		else {
			// Sinon, navigue relativement à la route courante.
			loNavigationExtras.relativeTo = this.ioRoute;
			this.ioRouter.navigate(['.', ERouteUrlPart.new], loNavigationExtras);
		}
	}

	/** Empêche l'ouverture des itemSliding si on n'a aucune option.
	 * @param poEvent Événement levé de l'itemSliding lorsqu'on veut l'ouvrir (de type 'CustomEvent' avec des méthodes supplémentaires).
	 */
	public onDrag(poEvent: Event & { close?: Function }, poItemSliding: IonItemSliding): void {
		//! Quand on ouvre un itemSlide via le bouton, on passe par la méthode `openItemSlidingOptions()` et celle-ci
		//! alors qu'on devrait passer seulement dans `openItemSlidingOptions()`.
		//! Si on décommente les lignes, une erreur survient dans le composant fils (`contactsDynHostComponent` par exemple)
		//! "can't read property 'open' of undefined". Tester quelques temps avant d'être sûr de supprimer ces commentaires et le `else` commenté.

		if (!this.itemSlidingEnabled && poEvent.close)
			poEvent.close();
		// else
		// 	this.openOrCloseItemSliding(poItemSliding, poEvent);
	}

	/** Gère l'erreur d'une balise `ion-img` en remplaçant sa source si possible ou en ne mettant aucune source.
	 * @param poImg Balise `ion-img` qui est tombée en erreur.
	 */
	public onImgError(poImg: IonImg, poItem?: any): void {
		// S'il y a une image par défaut dans la définition de formulaire, on l'applique.
		if (!StringHelper.isBlank(this.moListDefinition.defaultImageUrl)) {
			poImg.src = this.moListDefinition.defaultImageUrl;
			this.ioRenderer.addClass((poImg as IonImg & { el: any }).el, "defaultImg");
		}
		else if (poItem) // Sinon on utilise l'image par défaut du fichier HTML.
			poItem.picture = undefined;

		this.detectChanges();
	}

	//#endregion
}
