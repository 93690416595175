<!-- Mode Edit (!RO) -->
<div *ngIf="!(observableIsReadonly.value$ | async) as isReadonly">
	<ng-container
		*ngTemplateOutlet="(observableBoundsPosition.value$ | async) === ESliderBoundsPosition.side ? sideBounds : bottomBounds"></ng-container>
</div>

<!-- template pour un slider avec les bornes affichées en dessous du slider -->
<ng-template #bottomBounds>
	<ng-container *ngTemplateOutlet="range"></ng-container>
	<div class="bottom">
		<ng-container *ngTemplateOutlet="leftBound"></ng-container>
		<ng-container *ngTemplateOutlet="rightBound"></ng-container>
	</div>
</ng-template>

<!-- template pour un slider avec les bornes affichées de chaque côté du slider -->
<ng-template #sideBounds>
	<div class="side">
		<ng-container *ngTemplateOutlet="leftBound"></ng-container>
		<ng-container *ngTemplateOutlet="range"></ng-container>
		<ng-container *ngTemplateOutlet="rightBound"></ng-container>
	</div>
</ng-template>

<!-- template de la borne gauche -->
<ng-template #leftBound>
	<div class="label">
		<ion-label *ngIf="(observableShowMinValue.value$ | async) && (observableMinValue.value$ | async) as minValue">
			{{ minValue }}</ion-label>
		<ion-label *ngIf="observableMinLabel.value$ | async as minLabel">{{ minLabel }}</ion-label>
	</div>
</ng-template>

<!-- template du slider -->
<ng-template #range>
	<ion-range [min]="observableMinValue.value$ | async" [max]="observableMaxValue.value$ | async"
		[step]="observableStep.value$ | async" [pin]="observablePin.value$ | async" [value]="observableValue.value$ | async"
		[ticks]="observableTicks.value$ | async" [snaps]="observableSnaps.value$ | async"
		[style]="observableStyle.value$ | async" (ionInput)="onChanged($event)">
	</ion-range>
</ng-template>

<!-- template de la borne droite -->
<ng-template #rightBound>
	<div class="label">
		<ion-label *ngIf="(observableShowMaxValue.value$ | async) && (observableMaxValue.value$ | async) as maxValue"
			class="align-right">{{ maxValue }}</ion-label>
		<ion-label *ngIf="observableMaxLabel.value$ | async as maxLabel">{{ maxLabel }}</ion-label>
	</div>
</ng-template>