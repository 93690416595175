import { NgModule } from '@angular/core';
import { SqlRemoteProvider } from '../../sqlite/services/providers/sql-remote-provider.service';
import { CatalogDiagnosticsModule } from './catalog-diagnostics/catalog-diagnostics.module';
import { CatalogModalItemModule } from './catalog-modal-item/catalog-modal-item.module';
import { CatalogModalMainModule } from './catalog-modal-main/catalog-modal-main.module';
import { CatalogModalSearchModule } from './catalog-modal-search/catalog-modal-search.module';
import { CatalogRemoteProviderService } from './services/catalog-remote-provider.service';
import { CatalogService } from './services/catalog.service';

@NgModule({
	imports: [CatalogModalMainModule, CatalogModalSearchModule, CatalogModalItemModule],
	exports: [CatalogDiagnosticsModule],
	declarations: [],
	providers: [
		CatalogService,
		{ provide: SqlRemoteProvider, useClass: CatalogRemoteProviderService, multi: true }
	]
})
export class CatalogModule { }
