import { NgModule } from '@angular/core';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { UserFeedBackService } from './services/user-feedback.service';

@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [UserFeedBackService, Vibration],
})
export class UserFeedbackModule { }