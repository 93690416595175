import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { ActivePageManager } from '../../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IChangeEvent } from '../../../../model/store/IChangeEvent';
import { IDataSource } from '../../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { Store } from '../../../../services/store.service';
import { ILastChange } from '../../../hooks/models/ilast-change';
import { HooksService } from '../../../hooks/services/hooks.service';
import { IDataSourceRemoteChanges } from '../../../store/model/IDataSourceRemoteChanges';
import { ITour } from '../../../tour/models/ITour';
import { TourService } from '../../../tour/tour/tour.service';
import { TaskHelper } from '../helpers/task.helper';
import { WrongIdFormatError } from '../models/WrongIdFormat';
import { EKnownTaskSubType } from '../models/eknown-task-sub-type';
import { EKnownTaskType } from '../models/eknown-task-type';
import { ITask } from '../models/itask';
import { ITaskTypeLabelParams } from '../models/itask-type-label-params';

@Injectable({ providedIn: "root" })
export class TaskService {

	//#region FIELDS

	private static readonly C_LOG_ID = "TSK.SVC::";

	public static readonly C_TASK_APPOINT_TOUR_PREFIX: EPrefix = "task_appoint_tour_" as EPrefix;

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcHooks: HooksService
	) { }

	/** Récupération d'une tâche en base de données.
	 * @param psId Identifiant de l'objet à récupérer.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getTask<T extends ITask>(psId: string, pbLive?: boolean): Observable<T>;
	/** Récupération d'une tâche en base de données.
	 * @param psTourId Identifiant de la tournée (avec ou sans préfixe).
	 * @param psAppointId Identifiant du rendez-vous (avec ou sans préfixe).
	 * @param psTaskId Identifiant de la tâche (avec ou sans préfixe).
	 * @param pbLive Indique la récupération est continue ou non, `false` par défaut.
	 */
	public getTask<T extends ITask>(psTourId: string, psAppointId: string, psTaskId: string, pbLive?: boolean): Observable<T>;
	public getTask<T extends ITask>(psIdOrTourId: string, poAppointIdOrIsLive?: string | boolean, psTaskId?: string, pbLive?: boolean): Observable<T> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: { include_docs: true }
		};

		if (typeof poAppointIdOrIsLive === "string") { // Deuxième surcharge, `taskId` obligatoire.
			loDataSource.viewParams!.key = this.buildTaskId(psIdOrTourId, poAppointIdOrIsLive, psTaskId!);
			loDataSource.live = pbLive;
		}
		else {
			loDataSource.viewParams!.key = psIdOrTourId;
			loDataSource.live = poAppointIdOrIsLive;
		}

		return this.isvcStore.getOne<T>(loDataSource);
	}

	/** Construit et retourne l'identifiant complet d'une tâche.
	 * @param psTourId Identifiant de la tournée (avec ou sans préfixe).
	 * @param psAppointId Identifiant du rendez-vous (avec ou sans préfixe).
	 * @param psTaskId Identifiant de la tâche (avec ou sans préfixe).
	 */
	public buildTaskId(psTourId: string, psAppointId: string, psTaskId: string): string {
		return `${TaskService.C_TASK_APPOINT_TOUR_PREFIX}${IdHelper.extractIdWithoutPrefix(psTourId, TourService.C_TOUR_PREFIX)}_${IdHelper.extractIdWithoutPrefix(psAppointId, EPrefix.appoint)}_${IdHelper.extractIdWithoutPrefix(psTaskId, EPrefix.task)}`;
	}

	/** Récupère les tâches pour un rendez-vous.
	 * @param psTourId Identifiant de la tournée (avec ou sans préfixe).
	 * @param psAppointGuid Guid du rendez-vous.
	 * @param pbIsLive Indique si la récupération est continue ou non.
	 * @param poActivePageManager Gestionnaire d'activité de l'appelant.
	 */
	public getTasks<T extends ITask>(psTourId: string, psAppointGuid: string, pbIsLive: boolean = false, poActivePageManager?: ActivePageManager): Observable<T[]> {
		const lsId = `${TaskService.C_TASK_APPOINT_TOUR_PREFIX}${IdHelper.extractIdWithoutPrefix(psTourId, TourService.C_TOUR_PREFIX)}_${IdHelper.extractIdWithoutPrefix(psAppointGuid, EPrefix.appoint)}_`;
		const loDataSource: IDataSource | IDataSourceRemoteChanges = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsId,
				endkey: `${lsId}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbIsLive
		};

		if (poActivePageManager) {
			(loDataSource as IDataSourceRemoteChanges).remoteChanges = true;
			(loDataSource as IDataSourceRemoteChanges).activePageManager = poActivePageManager;
		}

		return this.isvcStore.get<T>(loDataSource);
	}

	/** Récupère toutes les tâches d'un rendez-vous.
	 * @param psAppointmentId Identifiant du rendez-vous dont on doit récupérer les tâches associées.
	 */
	public getTasksFromAppointmentIdAsync(psAppointmentId: string): Promise<ITask[]> {
		const lsTaskIdWithoutGuid = `${EPrefix.task}${psAppointmentId}`;

		return this.isvcStore.get<ITask>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsTaskIdWithoutGuid,
				endkey: `${lsTaskIdWithoutGuid}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		} as IDataSource)
			.toPromise();
	}

	/** Retourne toutes les tâches du rendez-vous en excluant la tâche passée en paramètre. */
	public getOtherTasksOfAppointmentAsync(psTaskId: string): Promise<ITask[]> {
		return this.getTasksFromAppointmentIdAsync(TaskHelper.getAppointmentId(psTaskId)).then(
			((paTasks: ITask[]) => paTasks.filter((poTask: ITask) => poTask._id !== psTaskId))
		);
	}

	/** Retourne l'identifiant à partir de l'`_id`.
	 * @param poTask Tâche dont il faut extraire l'identifiant.
	 * @returns L'identifiant de la tâche.
	 * @example ```typescript
	 * getId({_id: "task_appoint_tour_123_465_789"});
	 * ```
	 * Va retourner "789"
	 * @throws WrongIdFormatError
	 */
	public static getId<T extends IStoreDocument>(poTask: T): string {
		if (poTask._id.startsWith(TaskService.C_TASK_APPOINT_TOUR_PREFIX))
			return ArrayHelper.getLastElement(poTask._id.split("_"));

		console.error(`${TaskService.C_LOG_ID}Impossible d'extraire l'identifiant de la tâche "${poTask._id}".`);
		throw new WrongIdFormatError();
	}

	/** Retourne un identifiant de la dernière modification de la tâche.
	 * @param psAppName L'identifiant de l'application (ex: merchapp)
	 */
	public getLastChange(psAppName: string): ILastChange {
		return this.isvcHooks.getLastChange(psAppName);
	}

	/** Retourne les changements faits sur la tâche en base.
	 * @param poTask Tâche dont on veut écouter les changements.
	 * @param pbLive `true` pour une récupération live.
	 * @returns Un flux contenant la tâche à jour.
	 */
	public getLocalChanges<T extends ITask>(poTask: T, pbLive: boolean = false): Observable<T> {
		return this.isvcStore.localChanges<T>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: poTask._id,
				include_docs: true
			},
			live: pbLive
		})
			.pipe(map((poChangeEvent: IChangeEvent<T>) => poChangeEvent.document));
	}

	/** Enregistre un document 'task' en base de données.
	 * @param poTask Document à enregistrer.
	 * @param psAppName L'identifiant de l'application. (ex: merchapp)
	 * @returns Le numéro de révision du document à jour.
	 */
	public saveTask(poTask: ITask, psAppName: string): Observable<string> {
		poTask.lastChange = this.getLastChange(psAppName);

		return this.isvcStore.put(poTask).pipe(map((poResponse: IStoreDataResponse) => poResponse.rev));
	}

	/** Retourne le morceau de message correspondant au type de tâche. `de Reprise` ou `Reprise` pour une tâche de reprise, `de Livraison` ou `Livraison` pour une tâche de livraison
	 *  ou une chaîne vide si le type de tâche est inconnu.
	 * @param poTask La tâche où regarder le type.
	 * @param pbHasPreposition Si `true` alors ajoute la préposition `de` ou `d'` est placée devant.
	 */
	public getTaskTypeLabel<T extends ITask>(poTask: T, poParams: ITaskTypeLabelParams = {}): string {
		switch (poTask.taskType) {
			case EKnownTaskType.return:
				if (poTask.taskSubType === EKnownTaskSubType.sondage)
					return poParams.hasPreposition ? "de Sondage" : poParams.hasPersonalPronoun ? "le Sondage" : "Sondage";
				else
					return poParams.hasPreposition ? "de Reprise" : poParams.hasPersonalPronoun ? "la Reprise" : "Reprise";

			case EKnownTaskType.delivery:
				return poParams.hasPreposition ? "de Livraison" : poParams.hasPersonalPronoun ? "la Livraison" : "Livraison";

			case EKnownTaskType.counting:
				return poParams.hasPreposition ? "de Comptage" : poParams.hasPersonalPronoun ? "le Comptage" : "Comptage";

			case EKnownTaskType.inventory:
				return poParams.hasPreposition ? "d'Inventaire" : poParams.hasPersonalPronoun ? "l'Inventaire" : "Inventaire";

			default:
				console.error(`${TaskService.C_LOG_ID}Unknown task type '${poTask.taskType}'.`);
				return "";
		}
	}

	/** Récupère toutes les tâches d'une tournée.
	 * @param poTour Tournée dont il faut récupérer les tâches.
	 */
	public getAllTasksFromTourAsync<T extends ITask, U>(poTour: ITour<U>): Promise<T[]>;
	/** Récupère toutes les tâches d'une tournée.
	 * @param psTourId Identifiant de la tournée dont il faut récupérer les tâches.
	 */
	public getAllTasksFromTourAsync<T extends ITask>(psTourId: string): Promise<T[]>;
	public getAllTasksFromTourAsync<T extends ITask, U>(poTourData: ITour<U> | string): Promise<T[]> {
		const lsStartKey = `${EPrefix.task}${EPrefix.appoint}${typeof poTourData === "string" ? poTourData : poTourData._id}_`;

		return this.isvcStore.get<T>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			}
		})
			.toPromise();
	}

	//#endregion

}