import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../../../helpers/objectHelper';
import { UserHelper } from '../../../../../helpers/user.helper';
import { EPrefix } from '../../../../../model/EPrefix';
import { IContact } from '../../../../../model/contacts/IContact';
import { IStoreDocument } from '../../../../../model/store/IStoreDocument';
import { EntityLinkService } from '../../../../../services/entityLink.service';
import { Contact } from '../../../../contacts/models/contact';
import { IEntity } from '../../../../entities/models/ientity';
import { IEntityLinkRelationData } from '../../../../entities/models/ientity-link-relation-data';
import { IDeletableTag } from '../../../../tags/models/ideletable-tag';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../models/FieldBase';
import { IContactsListFieldParams } from '../../../models/IContactsListFieldParams';
import { FormsService } from '../../../services/forms.service';
import { IInlineFieldParams } from '../models/iinline-field-params';
import { IInlineFieldLayoutParams } from './inline-field-layout/models/iinline-field-layout-params';
import { IInlineListFieldLayoutParams } from './inline-list-field-layout/models/iinline-list-field-layout-params';

type TFieldType = Array<IContact> | Array<string> | string | IContact;

/** Champs contenant une liste de contacts. */
@Component({
	templateUrl: './contactsListField.component.html',
	styleUrls: ['./contactsListField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListFieldComponent extends FieldBase<TFieldType> implements OnInit, IInlineFieldParams {

	//#region FIELDS

	/** Indique si c'est le premier changement de modèle opéré ou non, permettant de déterminer s'il faut marquer le champ comme dirty ou non. */
	private mbIsFirstModelChanged = true;

	//#endregion

	//#region PROPERTIES

	/** Objet contenant les différents paramètres que le composant peut gérer. */
	public params: IContactsListFieldParams & IInlineFieldParams;
	public layout: "inline";
	public hideLabelWhenFilled?: boolean;
	public layoutParams: IInlineFieldLayoutParams | IInlineListFieldLayoutParams;

	public get isDefined(): boolean {
		return (this.fieldValue instanceof Array && ArrayHelper.hasElements(this.fieldValue)) || !!this.fieldValue;
	}

	public get useLinks(): boolean {
		return !ObjectHelper.isDefined(this.key) || this.key.toString().startsWith("#");
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.initParams();

		if (this.useLinks) {
			this.isvcEntityLink.getLinkedEntities(
				this.isvcEntityLink.currentEntity._id,
				this.params.contactsSelectorParams?.prefix ?? EPrefix.contact,
				this.params.linkTypes?.contact,
				true
			).pipe(
				tap((paLinkedEntities: IStoreDocument[]) => {
					let laValues: IContact[] | string[];

					if (this.fieldValue instanceof Array)
						laValues = [...this.fieldValue] as IContact[] | string[];
					else // On transforme en tableau si besoin.
						laValues = this.fieldValue ? (typeof this.fieldValue === "string" ? [this.fieldValue as string] : [this.fieldValue as IContact]) : [];

					if (ArrayHelper.hasElements(laValues) && typeof laValues[0] !== "string") {
						paLinkedEntities.forEach((poLinkedContact: IContact) => {
							if ((laValues as IContact[]).every((poContact: IContact) => poContact._id !== poLinkedContact._id))
								(laValues as IContact[]).push(poLinkedContact);
						});
					}
					else {
						paLinkedEntities.forEach((poLinkedContact: IContact) => {
							if (this.params.isInSectors &&
								!(poLinkedContact._id === ArrayHelper.getFirstElement(this.model.pointOfContactIds) || // TODO Revoir le typage et virer ce code tout en any vers un composant spécifique
									poLinkedContact._id === (ArrayHelper.getFirstElement(this.model.pointOfContactIds) as Contact)?._id)
							) {
								if ((laValues as string[]).every((psContactId: string) => psContactId !== poLinkedContact._id))
									(laValues as string[]).push(poLinkedContact._id);
							}
							else if (!this.params.isInSectors) {
								if ((laValues as string[]).every((psContactId: string) => psContactId !== poLinkedContact._id))
									(laValues as string[]).push(poLinkedContact._id);
							}
						});
					}

					this.fieldValue = ArrayHelper.hasElements(laValues) ? laValues : [];

					if (this.params.addCurrentUserIfEmpty)
						this.onModelChanged([UserHelper.getUserContactId()]);

					this.detectChanges();
				}),
				secure(this)
			).subscribe();
		}
	}

	private initParams(): void {
		this.params = this.to.data;

		if (this.params.readOnly === undefined)
			this.params.readOnly = this.to.readonly;

		this.params.contactsContainer = this.model;

		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;
		this.hideLabelWhenFilled = this.params.hideLabelWhenFilled;
	}

	public onModelChanged(paValues: string[] | IContact[]): void {
		if (this.canSetModelAfterChange(paValues)) {
			// Si le modèle a des éléments et que c'est le premier changement, il s'agit du remplissage du composant de formulaire, pas une modification.
			if (this.isDefined && this.mbIsFirstModelChanged)
				this.mbIsFirstModelChanged = false;
			else
				this.markAsDirty();

			if (this.useLinks) {
				const laNewEntities: IEntity[] = this.prepareFieldValueToUpdateEntityLinks(paValues);
				const laOldEntities: IEntity[] = this.prepareFieldValueToUpdateEntityLinks(this.fieldValue);
				let loRelationData: Map<string, IEntityLinkRelationData> | undefined;

				if (this.params.linkTypes)
					loRelationData = this.getRelationData(laNewEntities, laOldEntities);

				this.isvcEntityLink.updateCachedEntityLinks(
					this.model,
					this.prepareFieldValueToUpdateEntityLinks(this.fieldValue),
					laNewEntities,
					loRelationData
				);
			}

			this.fieldValue = paValues;
			this.detectChanges();
		}
	}

	private getRelationData(paNewEntities: IEntity[], paOldEntities: IEntity[]): Map<string, IEntityLinkRelationData> {
		const loRelationData = new Map<string, IEntityLinkRelationData>();

		ArrayHelper.unique([...paNewEntities, ...paOldEntities]).forEach((poEntity: IEntity) => {
			loRelationData.set(
				poEntity._id,
				{
					[poEntity._id]: this.params.linkTypes.contact,
					[this.model._id]: this.params.linkTypes.entity
				}
			);
		});

		return loRelationData;
	}

	private prepareFieldValueToUpdateEntityLinks(poFieldValue: TFieldType): IEntity[] {
		return coerceArray(poFieldValue).map((poValue: string | IContact) =>
			typeof poValue === "string" ? { _id: poValue } : poValue
		);
	}

	private canSetModelAfterChange(paValues: Array<IContact> | Array<string>): boolean {
		return (typeof ArrayHelper.getFirstElement(paValues as string[]) === "string" && !ArrayHelper.areArraysEqual(paValues as string[], this.fieldValue as string[])) ||
			!ArrayHelper.areArraysFromDatabaseEqual(paValues as IContact[], this.fieldValue as IContact[]);
	}

	public getTagsFromContacts(paContacts: IContact[], pfGetName: (poContact: IContact) => string): IDeletableTag[] {
		return paContacts.map((poContact: IContact) => {
			return {
				id: poContact._id,
				label: pfGetName(poContact),
				deletable: true
			}
		})
	}

	//#endregion
}