import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../../../../pipes/pipes.module';
import { CustomerSelectorComponent } from './customer-selector.component';

const laComponents = [CustomerSelectorComponent];

@NgModule({
	declarations: laComponents,
	imports: [
		CommonModule,
		IonicModule,
		PipeModule
	],
	exports: laComponents
})
export class CustomerSelectorModule { }
