import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { NumberHelper } from "../../../../helpers/numberHelper";
import { ConfigData } from "../../../../model/config/ConfigData";
import { OsappApiHelper } from "../../../osapp-api/helpers/osapp-api.helper";
import { SqlDataSource } from "../../../sqlite/models/sql-data-source";
import { SqlRemoteProvider } from "../../../sqlite/services/providers/sql-remote-provider.service";
import { IStockVersionResponse } from "../models/istock-version-response";
import { STOCK_DATABASE_ID } from "../models/stock.constante";

@Injectable()
export class StockRemoteProviderService extends SqlRemoteProvider {

	//#region FIELDS

	private static readonly C_LOG_ID = "STCK.RMT.PRVDR.S::";

	/** ID du workspace courant. */
	private readonly msWorkspaceId = "common";

	//#endregion FIELDS

	//#region PROPERTIES

	//! Ne pas mettre le préfixe d'url dans une constante statique car ConfigData.environment est défini dans un service, plus tardivement que la construction de cet objet.
	private msUrlPrefix?: string;
	private get urlPrefix(): string {
		if (this.msUrlPrefix)
			return this.msUrlPrefix;
		else
			return this.msUrlPrefix = `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${this.msWorkspaceId}/entities/stocks/`;
	}

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(poHttpClient: HttpClient) {
		super(poHttpClient);
	}

	public override canProcess(psDatabaseId: string): boolean {
		return psDatabaseId.startsWith(STOCK_DATABASE_ID);
	}

	public override getLastUrl(psDatabaseId: string): string {
		return `${this.getUrl(psDatabaseId)}/version`;
	}

	public override getUrl(psDatabaseId: string): string {
		// Sur mobile le databaseId commence par "stock-" mais pas sur mobile. Fix temporaire.
		if (psDatabaseId.indexOf("-") !== -1)
			return `${this.urlPrefix}warehouses/${psDatabaseId.split("-")[1]}`;
		else
			return `${this.urlPrefix}warehouses/${psDatabaseId}`;
	}

	public override getLastDatabaseAsync(psDatabaseId: string): Promise<SqlDataSource> {
		return this.getRemoteLastVersionAsync(psDatabaseId)
			.then((pnVersion: number) => new SqlDataSource(psDatabaseId, pnVersion, this.getUrl(psDatabaseId)));
	}

	private getRemoteLastVersionAsync(psDatabaseId: string): Promise<number> {
		const lsLastRemoteStockURL = this.getLastUrl(psDatabaseId);

		return this.ioHttpClient.get(lsLastRemoteStockURL,
			{
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: ConfigData.authentication.token,
					"api-key": ConfigData.environment.API_KEY,
					accept: "application/json"
				}),
				observe: "body",
				responseType: "json"
			}
		).pipe(
			map((poVersionResponse?: IStockVersionResponse) => NumberHelper.isValidPositive(+poVersionResponse?.version) ? +poVersionResponse?.version : 0),
			catchError((poError) => {
				console.error(`${StockRemoteProviderService.C_LOG_ID}Getting last version of the stock '${psDatabaseId}' failed.`, poError);
				return of(-1);
			})
		).toPromise();
	}

	//#endregion METHODS

}