import { Injectable } from '@angular/core';
import {
	Barcode,
	BarcodeScannedEvent,
	BarcodeScanner
} from '@capacitor-mlkit/barcode-scanning';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StringHelper } from '../../../../helpers/stringHelper';
import { PlatformService } from '../../../../services/platform.service';
import { IBarcodeAcquisition } from '../../devices/models/ibarcode-acquisition';
import { IBarcodeReader } from '../models/IBarcodeReader';

const C_APP_ROOT_QUERY_SELECTOR = "app-root";
const C_GUIDELINE_HTML_ID = "android-barcode-scanner-guideline";

@Injectable()
export class CordovaBarcodeReaderService implements IBarcodeReader {

	//#region FIELDS

	private readonly moBarcodeReadedSubject = new Subject<Barcode>();

	//#endregion

	//#region METHODS

	public constructor(
		private readonly isvcPlatform: PlatformService
	) { }

	/** @implements */
	public initializeBarcode(): void {
		// Pas d'initialisation nécessaire pour le plugin "BarcodeScanner".
		BarcodeScanner.addListener(
			"barcodeScanned",
			(poEvent: BarcodeScannedEvent) => this.moBarcodeReadedSubject.next(poEvent.barcode)
		);
	}

	/** @implements */
	public readBarcode(): void {
		let loSubscription: Subscription;

		BarcodeScanner.checkPermissions()
			.then(() => {
				loSubscription = this.isvcPlatform.getBackButtonSubscription(() => BarcodeScanner.stopScan());
				return BarcodeScanner.startScan();
			})
			.finally(() => {
				loSubscription.unsubscribe();
			});
	}

	/** @implements */
	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		return this.moBarcodeReadedSubject.asObservable().pipe(
			tap(
				(poBarcodeResult: Barcode) => console.debug("CORD-BARCODE.S::Code-à-barres lues ", [poBarcodeResult.rawValue]),
				poError => console.error("CORD-BARCODE.S::Erreur scan code-barres :", poError)
			),
			map((poBarcodeResult: Barcode): IBarcodeAcquisition[] => {
				// Si l'utilisateur fait retour dans la modale poBarcodeResult.text = "".
				if (StringHelper.isValid(poBarcodeResult.rawValue))
					return [{ code: poBarcodeResult.rawValue, numberOfScans: 1, taken: [new Date] }];
				else
					return [];
			})
		);
	}

	//#endregion
}