import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DmsModule } from '../../../dms/dms.module';
import { ModalModule } from '../../../modal';
import { CustomerSelectorModule } from '../../stock/components/stock-diagnostics/customer-selector/customer-selector.module';
import { MovementsListModule } from '../../stock/components/stock-diagnostics/movements-list/movements-list.module';
import { CatalogModalItemComponent } from './catalog-modal-item.component';

@NgModule({
	declarations: [CatalogModalItemComponent],
	imports: [
		IonicModule,
		ModalModule,
		DmsModule,
		AsyncPipe,
		CommonModule,
		CustomerSelectorModule,
		MovementsListModule
	]
})
export class CatalogModalItemModule { }
