import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { GuidHelper } from '../../../../../helpers/guidHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { Store } from '../../../../../services/store.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { LoggerService } from '../../../../logger/services/logger.service';
import { IReason } from '../../../reason/models/IReason';
import { ReasonService } from '../../../reason/services/reason.service';
import { RackHelper } from '../../helpers/rack.helper';
import { ERackStatus } from '../../models/ERackStatus';
import { RackService } from '../../services/rack.service';
import { TaskService } from '../../services/task.service';
import { ReturnRackHelper } from '../helpers/return-rack.helper';
import { IControlItem } from '../models/IControlItem';
import { IExtendedRackReturnArticle } from '../models/IExtendedRackReturnArticle';
import { IRackReturnArticle } from '../models/IRackReturnArticle';
import { IReturnTask } from '../models/IReturnTask';
import { IReturnRack } from '../models/ireturn-rack';

@Injectable()
export class ReturnRackService extends RackService<IReturnRack> {

	//#region METHODS

	constructor(
		protected readonly isvcReason: ReasonService,
		psvcStore: Store,
		psvcLogger: LoggerService,
		psvcTask: TaskService,
		psvcUiMessage: UiMessageService
	) {
		super(psvcStore, psvcLogger, psvcTask, psvcUiMessage);
	}

	/** Retourne tous les articles des portants passés en paramètre.
	 * @param paRackIds Identifiants des portants dont on veut les articles.
	 */
	public getArticlesOfRacks(psTaskId: string, psIgnoredRackId?: string): Observable<IExtendedRackReturnArticle[]> {
		return this.getRacksFromTaskId$(psTaskId)
			.pipe(
				map((paRacks: IReturnRack[]) => {
					const laArticles: IExtendedRackReturnArticle[] = [];

					paRacks.forEach((poRack: IReturnRack) => {
						if (poRack._id !== psIgnoredRackId) {
							Object.keys(poRack.picking).forEach((psItemId: string) =>
								laArticles.push({ ...poRack.picking[psItemId], isRackCanceled: poRack.status === ERackStatus.canceled })
							);
						}
					});

					return laArticles;
				})
			);
	}

	/** Retourne l'identifiant de la tâche à laquelle appartient le portant.
	 * @param psRackId Identifiant du portant en question.
	 */
	public getTaskIdFromRackId(psRackId: string): string {
		return psRackId.split("_").splice(1, 6).join("_");
	}

	/** Retourne `true` si l'article peut être contrôlé dans le portant donné, `false` sinon.
	 * @param psItemId Identifiant de l'article à tester.
	 * @param poRack Portant dont il faut vérifier si l'article peut être contrôlé.
	 * @param psNoPickingReasonId Identifiant du motif mère des motifs de non prélèvement.
	 */
	public canItemBeControlledInRack(psItemId: string, poRack: IReturnRack, psNoPickingReasonId: string): Observable<boolean> {
		return this.isvcReason.getReasonChildren(psNoPickingReasonId)
			.pipe(
				map((paNoPickingReasons: IReason[]) => {
					const loArticle: IRackReturnArticle = poRack.picking[psItemId];

					return loArticle &&
						(StringHelper.isBlank(loArticle.reasonId) || paNoPickingReasons.every((poNoPickingReason: IReason) => poNoPickingReason._id !== loArticle.reasonId));
				})
			);
	}

	/** Récupère les portants non annulés d'une tâche.
	 * @param psTaskId Identifiant de la tâche à partir duquel récupérer les portants.
	 */
	public getNotCanceledRacksFromTaskIdAsync(psTaskId: string): Promise<IReturnRack[]> {
		return this.getRacksFromTaskId$(psTaskId)
			.pipe(map((paRacks: IReturnRack[]) => paRacks.filter((poRack: IReturnRack) => poRack.status !== ERackStatus.canceled)))
			.toPromise();
	}

	/** Récupère les portants non annulés d'une tâche.
	 * @param poTask Tâche à partir de laquelle récupérer les portants.
	 */
	public getNotCanceledRacksFromTaskAsync(poTask: IReturnTask): Promise<IReturnRack[]> {
		return this.getNotCanceledRacksFromTaskIdAsync(poTask._id);
	}

	/** Récupère les portants validés d'une tâche.
	 * @param psTaskId Identifiant de la tâche à partir duquel récupérer les portants.
	 */
	public getValidatedRacksFromTaskIdAsync(psTaskId: string): Promise<IReturnRack[]> {
		return this.getRacksFromTaskId$(psTaskId)
			.pipe(map((paRacks: IReturnRack[]) => paRacks.filter((poRack: IReturnRack) => poRack.status === ERackStatus.closed)))
			.toPromise();
	}

	/** Récupère les portants validés d'une tâche.
	 * @param poTask Tâche à partir de laquelle récupérer les portants.
	 */
	public getValidatedRacksFromTaskAsync(poTask: IReturnTask): Promise<IReturnRack[]> {
		return this.getValidatedRacksFromTaskIdAsync(poTask._id);
	}

	/** Dévalide un portant et retourne le nouveau portant qui peut être modifié contrairement à celui qu'on dévalide.
	 * @param poRack Portant à dévalider.
	 * @param poTask Tâche de retour qui contient le portant à dévalider.
	 * @throws `DevalidateRackError` si une erreur est survenue lors de la dévalidation.
	 */
	public devalidateRack$(poRack: IReturnRack, poTask: IReturnTask): Observable<IReturnRack> {
		return super.devalidate(poRack, this.createDevalidateRack(poRack, poTask));
	}

	/** Crée et retourne un portant à partir d'un portant à dévalider.
	 * @param poRack Portant à dévalider.
	 */
	private createDevalidateRack(poRack: IReturnRack, poTask: IReturnTask): IReturnRack {
		return {
			...poRack,
			_id: RackHelper.buildRackId(poTask._id, GuidHelper.newGuid()),
			_rev: undefined,
			status: ERackStatus.active,
			createDate: new Date()
		};
	}

	/** Récupère tous les motifs présents dans tous les portants.
	 * @param poRack Portant à partir duquel récupérer les identifiants des motifs.
	 */
	public getAllReasonIds(poRack: IReturnRack): string[];
	/** Récupère tous les motifs présents dans tous les portants.
	 * @param paRacks Tableau des portants à partir desquels récupérer les identifiants des motifs.
	 */
	public getAllReasonIds(paRacks: IReturnRack[]): string[];
	public getAllReasonIds(poData: IReturnRack | IReturnRack[]): string[] {
		const laRacks: IReturnRack[] = poData instanceof Array ? poData : [poData];
		const laAllReasonIds: string[] = [];

		laRacks.forEach((poRack: IReturnRack) => {
			// Récupération des identifiants de motifs issus des prélèvements.
			laAllReasonIds.push(...this.getPickedReasonIds(poRack));
			// Récupération des identifiants de motifs issus des contrôles.
			laAllReasonIds.push(...this.getControlledReasonIds(poRack));
		});

		return laAllReasonIds;
	}

	/** Récupère les identifiants de motif des articles prélevés d'un portant.
	 * @param poRack Portant dont on veut récupérer les identifiants de motif.
	 */
	public getPickedReasonIds(poRack: IReturnRack): string[] {
		const laReasonIds: string[] = [];

		ReturnRackHelper.getPickedArticles(poRack)
			.forEach((poArticle: IRackReturnArticle) => {
				if (poArticle.reasonId)
					laReasonIds.push(poArticle.reasonId);
			});

		return laReasonIds;
	}

	/** Récupère les identifiants de motif des articles scannés/contrôlés d'un portant.
	 * @param poRack Portant dont on veut récupérer les identifiants de motif.
	 */
	public getControlledReasonIds(poRack: IReturnRack): string[] {
		const laReasonIds: string[] = [];

		poRack.controls.forEach((poControlItem: IControlItem) => {
			// Récupération de l'identifiant du motif de l'élément de contrôle s'il en a un.
			if (poControlItem.reasonId)
				laReasonIds.push(poControlItem.reasonId);
		});

		return laReasonIds;
	}

	public canNavigateToValidation(poRack: IReturnRack): boolean {
		const laPickedArticles: IRackReturnArticle[] = ReturnRackHelper.getPickedArticles(poRack);

		// Si on a au moins un article prélevé avec un motif de portée "item" ou si on a au moins un article scanné, on peut naviguer.
		return laPickedArticles.some((poPickedArticle: IRackReturnArticle) => StringHelper.isValid(poPickedArticle.reasonId)) ||
			ArrayHelper.hasElements(poRack.controls);
	}

	//#endregion

}