<div class="separator" [ngClass]="observableSeparator.value$ | async">
	<div [ngClass]="observableSeparator.value$ | async"></div>
</div>

<div class="container">
	<div class="content">
		<div class="icon">
			<ion-icon [name]="observableIcon.value$ | async"></ion-icon>
		</div>

		<div class="inline-content">
			<ng-content></ng-content>
			<ion-label (click)="raiseAddClicked()">{{ observableAddButtonLabel.value$ | async }}</ion-label>
		</div>
	</div>
</div>