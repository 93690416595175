<ion-list>

	<!-- Contournement bug session expirée. -->
	<ion-item-divider>
		Session
		<ion-icon name="bug" slot="start"></ion-icon>
	</ion-item-divider>
	<ion-item class="ion-text-wrap">
		<ion-button (click)="resetTokenAsync()">Supprimer session</ion-button>
	</ion-item>

	<!-- APPLICATION -->
	<ion-item-divider>
		Application
		<ion-icon name="phone-portrait" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Widget ID :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ widgetId }}</ion-label>
	</ion-item>
	<ion-item *ngIf="canUpdate" class="ion-text-wrap">
		<ion-label>Mises à jour :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="onCheckUpdatesButtonClick()">
				{{ availableUpdateVersion }}
			</ion-button>
		</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>ID :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ appId }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Version :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ appVersion }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Environnement :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ appSelectedEnvironment }}</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>Débogage PouchDB :</ion-label>
		<ion-toggle [ngModel]="diagnosticsDocument.isPouchDbDebugEnabled" (ngModelChange)="onPouchDbDebugChanged($event)">
		</ion-toggle>
	</ion-item>
	<br />

	<!-- UTILISATEUR -->
	<ion-item-divider>
		Utilisateur
		<ion-icon name="person" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Nom affiché :</ion-label>
		<ion-label *ngIf="userName" slot="end" style="vertical-align: middle">{{ userName }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Login :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ userLogin }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Espaces de travail :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ workspaceIds.join(", ") }}</ion-label>
	</ion-item>
	<br />

	<!-- Permissions -->
	<ion-item-divider>
		Sécurité
		<ion-icon name="lock-closed" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Groupes de l'utilisateur :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="onShowUserGroupsClick()">Voir</ion-button>
		</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Permissions de l'utilisateur :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="onShowUserPermissionsClick()">Voir</ion-button>
		</ion-label>
	</ion-item>
	<br />

	<!-- Documents -->
	<ion-item-divider>
		Documents
		<ion-icon name="document-text" slot="start"></ion-icon>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="navigateToDiagnosticFiles()">Plus d'infos
			</ion-button>
		</ion-label>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Nombre et poids</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{numberOfFiles}} | {{(fileSize ?? 0) | byte}}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>En attente d'envoi</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{pendingUpload}}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>En attente de réception</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{pendingDownload}}</ion-label>
	</ion-item>
	<br />

	<!-- Tâches -->
	<ion-item-divider>
		Tâches
		<ion-icon name="swap-horizontal-outline" slot="start"></ion-icon>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="navigateToDiagnosticTasks()">Plus d'infos
			</ion-button>
		</ion-label>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>En cours</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ numberOfRunningTasks !== undefined ? numberOfRunningTasks :
			"--"}}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>En attente</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ numberOfWaitingTasks !== undefined ? numberOfWaitingTasks :
			"--" }}</ion-label>
	</ion-item>
	<br />


	<!-- Notifications -->
	<ion-item-divider>
		Notification
		<ion-icon name="notifications" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>État</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ notificationState }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Identifiant</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ notificationId }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>PushToken</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ notificationPushToken }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Token</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ notificationToken }}</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>Débogage OneSignal :</ion-label>
		<ion-toggle [ngModel]="diagnosticsDocument.isOneSignalDebugEnabled"
			(ngModelChange)="onOneSignalDebugChanged($event)">
		</ion-toggle>
	</ion-item>
	<br />

	<!-- SYSTÈME -->
	<ion-item-divider>
		Système
		<ion-icon name="cog" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>ID appareil :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ systemDeviceId }}</ion-label>
	</ion-item>
	<br />

	<!-- RESEAU -->
	<ion-item-divider>
		Réseaux
		<ion-icon name="globe-outline" slot="start"></ion-icon>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="onRefreshNetworkButtonClick()">Actualiser</ion-button>
		</ion-label>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Type de réseau :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ networkType }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Débit max. :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ networkDownlinkMax }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Connectivité :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ networkOnline }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Fiabilité :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ networkReliable }}</ion-label>
	</ion-item>
	<br />

	<!-- DONNÉES -->
	<ion-item-divider>
		Données
		<ion-icon name="analytics" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>API :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ dataUrl }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Données de configuration :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">{{ dataConfigDatabase.id }}</ion-label>
	</ion-item>
	<ion-item class="ion-text-wrap">
		<ion-label>Métadonnées :</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="showDatabaseMeta()">Voir</ion-button>
		</ion-label>
	</ion-item>

	<!-- ChangeTracking -->
	<ion-item-divider>
		ChangeTracking
		<ion-icon name="footsteps" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item *ngFor="let item of observableTrackedCountByDatabaseId | keyvalue"
		(click)="displayChangeTrackingDetail(item.key)" class="ion-text-wrap">
		<ion-label>{{item.key}}</ion-label>
		<ion-label slot="end" style="vertical-align: middle" class="sync">
			{{item.value.value$| async}} document(s) tracés
		</ion-label>
	</ion-item>

	<!-- Synchronisations -->
	<ion-item-divider>
		Synchronisations
		<ion-icon name="sync" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item *ngFor="let databaseSyncMarker of obsDatabaseSyncMarkers.changes$ | async" class="ion-text-wrap">
		<ion-label><b>{{ databaseSyncMarker.databaseId }}</b></ion-label>
		<ion-label style="vertical-align: middle" class="sync">
			<ng-container *ngIf="databaseSyncMarker.fromServer">Depuis le serveur : {{ databaseSyncMarker.fromServer |
				dateWithLocale : "fr-FR" :
				C_DATABASE_SYNC_DATE_FORMAT }}<br></ng-container>
			<ng-container *ngIf="databaseSyncMarker.toServer">Vers le serveur : {{ databaseSyncMarker.toServer |
				dateWithLocale : "fr-FR" :
				C_DATABASE_SYNC_DATE_FORMAT }}</ng-container>
		</ion-label>
		<ion-button *ngIf="databaseSyncMarker.canSynchronize" (click)="showForceSynchronizePopupAsync(databaseSyncMarker)">
			<ion-icon name="sync-circle-outline" slot="icon-only"></ion-icon>
		</ion-button>
	</ion-item>

	<!-- JOURNAUX -->
	<ng-container *ngIf="isCurrentUserAdmin">
		<ion-item-divider>
			Journaux
			<ion-icon name="reload-outline" slot="start"></ion-icon>
		</ion-item-divider>

		<ion-item>
			<ion-label>Journaux</ion-label>
			<ion-label slot="end" style="vertical-align: middle">
				<ion-button (click)="navigateToLogsPage()">Voir</ion-button>
			</ion-label>
		</ion-item>

	</ng-container>
</ion-list>