import { Type } from "@angular/core";
import { Exclude, Expose, Transform, TransformFnParams } from "class-transformer";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { IDateRange } from "../../../components/date/date-range-picker/model/IDateRange";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { DateHelper, IDateTypes } from "../../../helpers/dateHelper";
import { IdHelper } from "../../../helpers/idHelper";
import { MapHelper } from "../../../helpers/mapHelper";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { StoreDocumentHelper } from "../../../helpers/storeDocumentHelper";
import { UserHelper } from "../../../helpers/user.helper";
import { EPrefix } from "../../../model/EPrefix";
import { UserData } from "../../../model/application/UserData";
import { ETimetablePattern } from "../../../model/date/ETimetablePattern";
import { GalleryFile } from "../../../model/gallery/gallery-file";
import { EDatabaseRole } from "../../../model/store/EDatabaseRole";
import { IDataSourceViewParams } from "../../../model/store/IDataSourceViewParams";
import { IStoreDocument } from "../../../model/store/IStoreDocument";
import { Store } from "../../../services/store.service";
import { Entity } from "../../entities/models/entity";
import { ObserveArray } from "../../observable/decorators/observe-array.decorator";
import { ObservableArray } from "../../observable/models/observable-array";
import { ObservableProperty } from "../../observable/models/observable-property";
import { PerformanceManager } from "../../performance/PerformanceManager";
import { IRemindable } from "../../reminder-alarms/models/iremindable";
import { ReminderAlarm } from "../../reminder-alarms/models/reminder-alarm";
import { External } from "../../store/external/decorators/external.decorator";
import { DefaultValue } from "../../utils/models/decorators/default-value.decorator";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { IRange } from "../../utils/models/models/irange";
import { BaseEventOccurrence } from "./base-event-occurrence";
import { ENotificationType } from "./enotification-type";
import { ERecurrenceType } from "./erecurrence-type";
import { EventDuration } from "./event-duration";
import { EventOccurrenceConstraint } from "./event-occurrence-constraint";
import { EventOccurrenceCriterion } from "./event-occurrence-criterion";
import { EventOccurrenceDateCriterion } from "./event-occurrence-date-criterion";
import { EventOccurrenceDifferential } from "./event-occurrence-differential";
import { EventState } from "./event-state";
import { IEvent } from "./ievent";
import { IEventNotification } from "./ievent-notification";
import { IEventOccurrence } from "./ievent-occurrence";
import { Recurrence } from "./recurrence";

interface IDateWithConstraint {
	date: Date;
	constraint: EventOccurrenceConstraint;
}

@ModelMatch((poData: Entity) => IdHelper.hasPrefixId(poData._id, EPrefix.event), Entity)
export abstract class BaseEvent<T extends BaseEventOccurrence = BaseEventOccurrence> extends Entity implements IEvent, IRemindable {

	//#region FIELDS

	private static readonly C_LOG_ID = "EVT::";

	@External({
		viewParams: (poThis: BaseEvent) => {
			return poThis.getDifferentialIdsDataSource(poThis);
		},
		role: EDatabaseRole.workspace
	})
	private readonly moObservableDifferentialIds = new ObservableProperty<IStoreDocument[]>();

	@External({
		viewParams$: (poThis: BaseEvent) => {
			return poThis.getDifferentialsDataSource$(poThis);
		},
		baseClass: EventOccurrenceDifferential,
		role: EDatabaseRole.workspace
	})
	private readonly moObservableDifferentials = new ObservableProperty<EventOccurrenceDifferential[]>();

	//#endregion

	//#region PROPERTIES

	@Exclude()
	@ResolveModel(Date)
	protected mdStartDate: Date | undefined;
	@Expose()
	public get startDate(): Date | undefined { return this.mdStartDate; }
	public set startDate(pdStartDate: Date | undefined) {
		if (pdStartDate !== this.mdStartDate)
			this.onStartDateChanged(pdStartDate);
	}

	// La date de fin est persisté en base uniquement pour les besoins futurs de l'indexeur, on l'ignore à l'instanciation par le ModelResolver
	@Expose({ toPlainOnly: true })
	@Exclude({ toClassOnly: true })
	public get endDate(): Date | undefined {
		let ldEndDate: Date | undefined;

		if (ArrayHelper.hasElements(this.recurrences)) {
			ldEndDate = DateHelper.getMax(ArrayHelper.getValidValues(this.recurrences?.map((poRecurrence: Recurrence) => poRecurrence.endDate)));
			if (ldEndDate)
				ldEndDate = this.prepareGenerationRange({ to: ldEndDate }).to; // Pour prendre en compte les contraintes.
		}
		else if (this.startDate && this.duration)
			ldEndDate = this.duration.addDurationToDate(new Date(this.startDate));

		if (ldEndDate && this.fullDay)
			ldEndDate = DateHelper.fillDay(ldEndDate);

		return ldEndDate;
	}
	@ResolveModel(Recurrence)
	public recurrences?: Recurrence[];
	@ResolveModel(EventDuration)
	public duration: EventDuration;
	@ResolveModel(EventDuration)
	public flexibilitySlot?: EventDuration;
	@DefaultValue(() => UserData.current?._id ?? "")
	public authorId: string;

	@DefaultValue(() => [])
	public participantIds: string[];
	@ObserveArray<BaseEvent>("participantIds")
	public readonly observableParticipantIds = new ObservableArray<string>();

	@ResolveModel(GalleryFile)
	public attachments?: GalleryFile[];
	@Transform(
		(poParams: TransformFnParams) => ArrayHelper.hasElements(poParams.value) ? poParams.value : undefined,
		{ toPlainOnly: true }
	)
	@ResolveModel(EventOccurrenceConstraint)
	@DefaultValue(() => [])
	public occurrenceConstraints: EventOccurrenceConstraint[];
	@ResolveModel(Date)
	@DefaultValue(() => new Date)
	public creationDate: Date;

	@Exclude()
	public get id(): string {
		return this._id;
	}

	@Exclude()
	private mbFullDay: boolean;
	@Expose()
	public get fullDay(): boolean {
		return this.mbFullDay;
	}
	public set fullDay(pbFullDay: boolean) {
		this.mbFullDay = pbFullDay;
		this.onStartDateChanged(this.startDate); // On force le recalcul de la date de début.
	}

	// L'état est persisté en base sous forme de documents séparés que l'on agrège par identifiant utilisateur dans une Map
	// TODO : Créer une classe ObservableMap avec un get$ permettant de rester à jour des modifications d'états de l'évènement
	@Exclude()
	public stateByUserId = new Map<string, EventState>();

	@Exclude()
	protected abstract readonly occurrenceType: Type<T>;

	public get canBeDeleted(): boolean {
		return this.authorId === UserData.current?._id;
	}

	@Exclude()
	public readonly canBeEdited$: Observable<boolean> = this.getCanBeEdited$();

	public override readonly _id: string = IdHelper.buildId(EPrefix.event);
	public title: string;
	public comment?: string;
	public eventType: string;
	public eventSubtype?: string;
	public street?: string;
	public zipCode?: string;
	public city?: string;
	public notes?: string;
	public previousRev?: string;
	public status?: string;
	@ResolveModel(Date)
	public statusChangeDate?: Date;
	public place: string;
	public latitude?: number;
	public longitude?: number;
	public hasVisio?: boolean;
	public private?: boolean;

	public notifications?: IEventNotification[];

	//#endregion

	//#region METHODS

	constructor(poEvent?: Partial<IEvent>) {
		super();
		if (poEvent)
			ObjectHelper.assign(this, poEvent);
	}

	/** Permet de connaitre les récurrences affectées à cet évènement, et d'en générer une dans le cas où aucune n'existe
	 * @returns Un tableau des récurrences décrivant les occurrences à générer
	 */
	private getReccurrences(): Recurrence[] {
		if (this.startDate) {
			if (ArrayHelper.hasElements(this.recurrences))
				return this.recurrences;
			return [new Recurrence({ startDate: this.startDate, every: 1, type: ERecurrenceType.daily, limit: 1 })];
		}
		else
			return [];
	}

	/**
	 * Génère les occurrences de l'évènement courant à partir des information de récurrence qu'il contient
	 * @param paContacts Contacts à associer aux occurrences générées
	 * @param poRange Plage de dates (optionnelle) pour restreindre la génération
	 * @param psRev Révision jusqu'à laquelle il faut éxecuter les contraintes avant de filtrer par date.
	 * @returns Un tableau d'occurrences de cet évènement
	 */
	public generateOccurrences(
		poRange?: IRange<Date>,
		pnLimit?: number,
		psRev?: string
	): T[] {
		return this.generateOccurrenceData(poRange, pnLimit, psRev).map(
			(poEventOccurrence: IEventOccurrence) =>
				poEventOccurrence instanceof this.occurrenceType ?
					poEventOccurrence :
					ObjectHelper.assign(this.createOccurrence(this, poEventOccurrence.startDate), poEventOccurrence)
		);
	}

	public generateOccurrenceData(
		poRange?: IRange<Date>,
		pnLimit?: number,
		psRev?: string,
	): IEventOccurrence[] {
		const loPerfManager = new PerformanceManager().markStart();
		const laOccurrences: IEventOccurrence[] = [];
		const loRange: IRange<Date> | undefined = this.prepareGenerationRange(poRange);
		const lnRev: number | undefined = this.extractRevNumber(psRev);
		const laDates: Date[] = [];

		if (ArrayHelper.hasElements(this.recurrences)) {
			this.getReccurrences().forEach((poRecurrence: Recurrence) => {
				let lnLimit: number = pnLimit;
				if (lnLimit && DateHelper.isDate(loRange.from) && DateHelper.isDate(poRange.from))
					lnLimit += Math.abs(DateHelper.diffDays(loRange.from, poRange.from));

				laDates.push(...poRecurrence.generate(loRange, lnLimit));
			});
		}
		else
			laDates.push(this.startDate);

		const loIndexedDifferentials: Map<number, EventOccurrenceDifferential> | undefined = this.indexDifferentials(this.moObservableDifferentials.value);

		DateHelper.sortByDate(
			laDates.map((pdDate: Date) => this.prepareOccurrence(pdDate, lnRev, loIndexedDifferentials)),
			(poOccurrence: IEventOccurrence) => poOccurrence.startDate
		).forEach((poOccurrence: IEventOccurrence) => {
			if (
				this.canInsertOccurrence({
					from: poOccurrence.startDate,
					to: this.duration ? this.duration.addDurationToDate(poOccurrence.startDate) : poOccurrence.startDate
				},
					laOccurrences,
					poRange,
					pnLimit)
			)
				this.insertOccurrence(laOccurrences, this.execConstraints(poOccurrence, { from: lnRev }, loIndexedDifferentials));
		});

		console.debug(`${BaseEvent.C_LOG_ID}Generated ${laOccurrences.length} occurrence(s) in ${loPerfManager.markEnd().measure()}ms.`);

		return laOccurrences;
	}

	private indexDifferentials(
		paEventOccurrenceDifferential: EventOccurrenceDifferential<IEventOccurrence>[]
	): Map<number, EventOccurrenceDifferential> | undefined {
		let loDifferentialGroupedByOccurrenceDate: Map<number, EventOccurrenceDifferential> | undefined;
		if (ArrayHelper.hasElements(paEventOccurrenceDifferential)) {
			loDifferentialGroupedByOccurrenceDate = ArrayHelper.groupByUnique(
				paEventOccurrenceDifferential,
				(poItem: EventOccurrenceDifferential) => poItem.occurrenceStartDate.getTime()
			);
		}
		return loDifferentialGroupedByOccurrenceDate;
	}

	private extractRevNumber(psRev: string): number | undefined {
		return ObjectHelper.isDefined(psRev) ? StoreDocumentHelper.getRevisionNumber(psRev) : undefined;
	}

	private insertOccurrence(laOccurrences: IEventOccurrence[], loOccurrence: IEventOccurrence) {
		ArrayHelper.binaryInsert(laOccurrences, loOccurrence, "startDate");
	}

	private prepareOccurrence(
		pdOccurrrenceDate?: Date,
		pnRev?: number,
		poDifferentialGroupedByOccurrenceDate?: Map<number, EventOccurrenceDifferential>
	): IEventOccurrence {
		if (pdOccurrrenceDate && this.startDate) // On vient définir le bon horaire pour la date dans le cas où la date de début de récurrence n'est plus cohérente.
			pdOccurrrenceDate.setHours(this.startDate.getHours(), this.startDate.getMinutes());

		return this.execConstraints(
			poDifferentialGroupedByOccurrenceDate ?
				this.createOccurrence(this, pdOccurrrenceDate) :
				{ participantIds: [...this.participantIds], startDate: pdOccurrrenceDate, eventId: this._id, notifications: [...(this.notifications ?? [])] },
			{ to: ObjectHelper.isDefined(pnRev) ? pnRev - 1 : pnRev },
			poDifferentialGroupedByOccurrenceDate
		);
	}

	private canInsertOccurrence(poOccurrenceRange: IRange<IDateTypes>, paOccurrences: IEventOccurrence[], poRange?: IRange<Date>, pnLimit?: number): boolean {
		return DateHelper.areRangesOverlaping(
			poOccurrenceRange,
			poRange ?? {}
		) &&
			(!pnLimit || paOccurrences.length < pnLimit);
	}

	private prepareGenerationRange(poRange?: IRange<Date>): IRange<Date> | undefined {
		let loRange: IRange<Date> | undefined;
		const laExecutedConstraints: EventOccurrenceConstraint[] = [];

		if (poRange) {
			loRange = { ...poRange };

			this.occurrenceConstraints.forEach((poConstraint: EventOccurrenceConstraint) =>
				loRange = this.prepareRangeFromConstraint(laExecutedConstraints, poConstraint, loRange)
			);

			if (laExecutedConstraints.length !== this.occurrenceConstraints.length) { // Si on n'a pas exécuté toutes les contraintes
				ArrayHelper.forEachReverse(this.occurrenceConstraints, (poConstraint: EventOccurrenceConstraint) =>
					loRange = this.prepareRangeFromConstraint(laExecutedConstraints, poConstraint, loRange)
				);
			}

			if (loRange.from && this.duration)
				loRange.from = this.duration.removeDurationToDate(loRange.from);
		}

		return loRange;
	}

	private prepareRangeFromConstraint(paExecutedConstraints: EventOccurrenceConstraint[], poConstraint: EventOccurrenceConstraint, poRange: IRange<Date>): IRange<Date> {
		let loRange: IRange<Date> = poRange;

		if (!paExecutedConstraints.includes(poConstraint)) {
			const loPreModifiedRange: IRange<Date> = poConstraint.execActionsOnRange(poRange);
			if (poConstraint.canMatchRange(loPreModifiedRange)) {
				loRange = loPreModifiedRange;
				paExecutedConstraints.push(poConstraint);
			}
		}

		return loRange;
	}

	/**
	 * @param poEventOccurrence
	 * @param poRevRange Plage de révisions pour laquelle il faut éxecuter les contraintes, borne de fin exclue.
	 * @returns
	 */
	private execConstraints(
		poEventOccurrence: IEventOccurrence,
		poRevRange?: IRange<number>,
		poDifferentialGroupedByOccurrenceDate?: Map<number, EventOccurrenceDifferential>
	): IEventOccurrence {
		const lnOccurrenceTime: number | undefined = DateHelper.getTime(poEventOccurrence.startDate);
		this.occurrenceConstraints.forEach((poConstraint: EventOccurrenceConstraint) => {
			const lnConstraintRevNumber: number = StoreDocumentHelper.getRevisionNumber(poConstraint.rev);
			const loDifferential: EventOccurrenceDifferential | undefined = poDifferentialGroupedByOccurrenceDate?.get(lnOccurrenceTime);

			if (
				!poEventOccurrence.differentialExecuted &&
				loDifferential &&
				lnConstraintRevNumber > StoreDocumentHelper.getRevisionNumber(loDifferential.eventRev)
			) {
				poEventOccurrence.eventOccurrenceDifferential = loDifferential;
				poEventOccurrence.eventOccurrenceDifferential.applyToOccurrence(poEventOccurrence);
			}

			if (
				(poRevRange?.from ?? lnConstraintRevNumber) <= lnConstraintRevNumber &&
				(poRevRange?.to ?? lnConstraintRevNumber) >= lnConstraintRevNumber &&
				poConstraint.match(poEventOccurrence)
			)
				poConstraint.execActions(poEventOccurrence);
		});

		if (!poEventOccurrence.differentialExecuted) {
			poEventOccurrence.eventOccurrenceDifferential =
				poDifferentialGroupedByOccurrenceDate?.get(lnOccurrenceTime);
			poEventOccurrence.eventOccurrenceDifferential?.applyToOccurrence(poEventOccurrence);
		}

		return poEventOccurrence;
	}

	/** Génère la prochaine occurrence d'un évènement, si elle existe.
	 * Par défaut pour un évènement récurrent standard dont on ne sait pas gérer les états, on prend l'occurrence suivante la plus proche de la date courante.
	 * @param paContacts Contacts à associer à l'occurence générée
	 * @param pdDate Optionnel, date à partir de laquelle la prochaine occurrence sera déterminée (par défaut la date du jour)
	 * @returns Une occurrence de l'évènement courant si elle existe, `undefined` sinon.
	 */
	public generateNextOccurrence(
		pdDate?: Date,
		psRev?: string
	): T | undefined {
		if (!this.startDate)
			return this.createOccurrence(this);

		return ArrayHelper.getLastElement(this.generateOccurrences({ from: pdDate ?? new Date() }, 2, psRev));
	}

	/** Génère l'occurrence la plus proche d'une date, si elle existe.
	 * Par défaut pour un évènement récurrent standard dont on ne sait pas gérer les états, on prend la première occurrence.
	 * @param paContacts Contacts à associer à l'occurence générée
	 * @param pdDate Optionnel, date à partir de laquelle la prochaine occurrence sera déterminée (par défaut la date du jour)
	 * @returns Une occurrence de l'évènement courant si elle existe, `undefined` sinon.
	 */
	public generateOccurrence(
		pdDate?: Date,
		psRev?: string
	): T | undefined {
		if (!this.startDate)
			return this.createOccurrence(this);

		return ArrayHelper.getFirstElement(this.generateOccurrences({ from: pdDate }, 1, psRev));
	}

	/** Retourne toutes les occurrences qui diffèrent de la série. */
	public generateExceptionalOccurrences(poRange?: IDateRange): T[] {
		const loOccurrencesByDate = new Map<number, T>();
		const laDatesAndConstraints: IDateWithConstraint[] = this.generateExceptionalDateWithConstraints();

		laDatesAndConstraints.forEach((poDateWithConstraint: IDateWithConstraint) => {
			let loOccurrence: T | undefined = loOccurrencesByDate.get(poDateWithConstraint.date.getTime());

			if (!loOccurrence) {
				loOccurrence = this.generateOccurrence(
					poDateWithConstraint.date,
					poDateWithConstraint.constraint.rev
				);
			}

			if (loOccurrence && DateHelper.areRangesOverlaping(
				{ from: loOccurrence.startDate, to: loOccurrence.observableEndDate.value },
				poRange
			)) {
				loOccurrencesByDate.delete(poDateWithConstraint.date.getTime()); // On supprime l'occurrence sur l'ancienne date.
				loOccurrencesByDate.set(loOccurrence.startDate?.getTime(), loOccurrence); // Puis on la rajoute sur la nouvelle.
			}
		});

		return MapHelper.valuesToArray(loOccurrencesByDate);
	}

	private generateExceptionalDateWithConstraints(): IDateWithConstraint[] {
		const laDatesAndConstraints: IDateWithConstraint[] = [];

		this.occurrenceConstraints.forEach((poConstraint: EventOccurrenceConstraint) => {
			const laConstraintDates: Date[] = [];
			poConstraint.criteria.forEach((poCriterion: EventOccurrenceCriterion) => {
				if (poCriterion instanceof EventOccurrenceDateCriterion)
					ArrayHelper.pushIfNotPresent(laConstraintDates, poCriterion.date, (pdDate: Date) => DateHelper.areEqual(pdDate, poCriterion.date));
			});

			if (laConstraintDates.length === 1) { // Si on a plusieurs dates, alors la contrainte ne peut rien match.
				const loDateWithConstraint: IDateWithConstraint = {
					date: ArrayHelper.getFirstElement(laConstraintDates),
					constraint: poConstraint
				};
				ArrayHelper.pushIfNotPresent(
					laDatesAndConstraints,
					loDateWithConstraint,
					(poDateWithConstraint: IDateWithConstraint) => DateHelper.areEqual(poDateWithConstraint.date, loDateWithConstraint.date));
			}
		});

		return laDatesAndConstraints;
	}

	/** Crée l'occurrence de base qui correspond au paramétrage de la série. */
	public getBaseOccurrence(poRange?: IDateRange): T | undefined {
		const laExceptionalOccurrences: BaseEventOccurrence[] = this.generateExceptionalOccurrences();
		let loOccurrence: T | undefined;
		let ldPreviousDate: Date | undefined;

		do {
			ldPreviousDate = loOccurrence?.startDate;
			loOccurrence = this.generateOccurrence(
				loOccurrence ? DateHelper.addDays(loOccurrence.startDate, 1) : undefined // La prochaine occurrence sera au minimum le lendemain.
			);
		} while (
			loOccurrence &&
			!DateHelper.areEqual(loOccurrence.startDate, ldPreviousDate) &&
			DateHelper.areRangesOverlaping({ from: loOccurrence.startDate, to: loOccurrence.observableEndDate.value }, poRange) &&
			laExceptionalOccurrences.some(
				(poOccurrence: BaseEventOccurrence) => DateHelper.areEqual(poOccurrence.startDate, loOccurrence.startDate)
			)
		);

		return loOccurrence;
	}

	/** Permet de créer une occurrence correctement typée pour les classes étendant Event
	 * @param paParams Paramètres pour la création de l'instance de la classe correspondant au type d'occurrence
	 * @returns Une occurrence dont le type étend EventOccurrence
	 */
	public createOccurrence(...paParams: ConstructorParameters<typeof BaseEventOccurrence>): T {
		return new this.occurrenceType(...paParams);
	}

	/**
	 * Permet d'ajouter à l'évènement les informations obtenues depuis un document evtState
	 * @param poState Informations d'état à ajouter à l'évènement
	 */
	public addState(poState: EventState): void {
		const lsUserId: string = IdHelper.getIdFromComposedId(poState._id.replace(EPrefix.eventState, ""), EPrefix.user);
		this.stateByUserId.set(lsUserId, poState);
	}

	/** Recherche l'état le plus récent de l'évènement, qui est considéré comme étant l'état courant de l'évènement
	 * @returns Une instance d'EventState
	 */
	public getLastState(): EventState | undefined {
		let loCurrentState: EventState | undefined;

		this.stateByUserId.forEach((poState: EventState) => {
			if (!loCurrentState || poState.lastUpdate > loCurrentState.lastUpdate)
				loCurrentState = poState;
		});

		return loCurrentState;
	}

	protected onStartDateChanged(pdStartDate?: Date): void {
		if (pdStartDate) {
			this.mdStartDate = this.fullDay ? DateHelper.resetDay(new Date(pdStartDate)) : DateHelper.resetMinutes(new Date(pdStartDate));
			const loRecurrence: Recurrence | undefined = ArrayHelper.getFirstElement(this.recurrences);
			if (loRecurrence)
				loRecurrence.startDate = this.mdStartDate;
		}
		else
			this.mdStartDate = undefined;
	}

	public hasSomeParticipants(paParticipantsIds?: string[]): boolean {
		return !ArrayHelper.hasElements(this.participantIds) || !ArrayHelper.hasElements(paParticipantsIds) ||
			paParticipantsIds.some((psId: string) => this.participantIds.includes(psId));
	}

	private getCanBeEdited$(): Observable<boolean> {
		return this.observableParticipantIds.changes$.pipe(
			map(() => this.authorId === UserData.current?._id || this.hasSomeParticipants([UserHelper.getUserContactId()])),
			distinctUntilChanged()
		);
	}

	private getFilteredOccurrences(pdMaxDate: Date): IEventOccurrence[] {
		return this.generateOccurrenceData({ to: pdMaxDate })
			.filter((poOccurrenceData: IEventOccurrence) => {
				// On ne garde que les occurrences datées dont on est participant
				return !!poOccurrenceData.startDate && poOccurrenceData.participantIds.some((participantId: string) => participantId === UserHelper.getUserContactId());
			});
	}

	protected generateReminderAlarmsFromOccurence(poOccurrence: IEventOccurrence): ReminderAlarm[] {
		return (poOccurrence.notifications ?? []).map((poEventNotification: IEventNotification) => {
			return new ReminderAlarm(
				this._id,
				poEventNotification.type === ENotificationType.absolute ? poEventNotification.date : poOccurrence.startDate,
				this.getReminderDuration(poEventNotification),
				this.title,
				`Commence à ${DateHelper.transform(poOccurrence.startDate, ETimetablePattern.HH_mm)} le ${DateHelper.transform(poOccurrence.startDate, ETimetablePattern.EEEE_dd_MMMM)}`,
				this.getRoute(poOccurrence.startDate),
				{ eventRev: this._rev, occurrenceStartDate: poOccurrence.startDate }
			);
		});
	}

	public getReminders(pdMaxDate: Date): ReminderAlarm[] {
		return this.getFilteredOccurrences(pdMaxDate)
			.map((poOccurrenceData: IEventOccurrence) => this.generateReminderAlarmsFromOccurence(poOccurrenceData))
			.flat();
	}

	protected getReminderDuration(poEventNotification: IEventNotification): number {
		if (poEventNotification.type === ENotificationType.absolute)
			return 0;

		let lnReminderDuration: number;
		Object.entries(poEventNotification.before).forEach(([psKey, pnValue]: [string, number]) => {
			if (psKey === "minutes")
				lnReminderDuration = pnValue;
			else if (psKey === "hours")
				lnReminderDuration = pnValue * 60;
			else if (psKey === "days")
				lnReminderDuration = pnValue * 1440;
		});
		return lnReminderDuration;
	}

	public getPlanificationDescription(): string | undefined {
		if (ArrayHelper.hasElements(this.recurrences))
			return this.recurrences.map((poRecurrence: Recurrence) => poRecurrence.label).join(". ");
		return undefined;
	}

	public abstract getRoute(pdDate?: Date | undefined): string;

	private getDifferentialsDataSource$(poThis: BaseEvent<BaseEventOccurrence>): Observable<IDataSourceViewParams> {
		return poThis.moObservableDifferentialIds.value$.pipe(
			filter((paDocuments?: IStoreDocument[]) => ObjectHelper.isDefined(paDocuments)),
			map((paDocuments: IStoreDocument[]) => {
				return {
					keys: paDocuments.map((poDoc: IStoreDocument) => poDoc._id),
					include_docs: true
				};
			})
		);
	}

	private getDifferentialIdsDataSource(poThis: BaseEvent<BaseEventOccurrence>): IDataSourceViewParams {
		const lsStartKey: string = IdHelper.buildChildId(
			EPrefix.eventOccurrenceDifferential,
			IdHelper.buildVirtualNode([poThis._id, EPrefix.user]),
			""
		);
		return {
			startkey: lsStartKey,
			endkey: lsStartKey + Store.C_ANYTHING_CODE_ASCII
		};
	}

	//#endregion

}
