import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { IDeletableTag } from '../../models/ideletable-tag';

@Component({
	selector: 'calao-deletable-tags-list',
	templateUrl: './deletable-tags-list.component.html',
	styleUrls: ['./deletable-tags-list.component.scss'],
})
export class DeletableTagsListComponent {

	//#region FIELDS

	@Output("onRemoveTagClicked") private readonly moRemoveTagClicked = new EventEmitter<IDeletableTag>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Tableau des tags à afficher. */
	@Input() public tags?: IDeletableTag[];
	@ObserveArray<DeletableTagsListComponent>("tags")
	public readonly observableGroups = new ObservableArray<IDeletableTag>();

	//#endregion PROPERTIES

	//#region METHODS

	public removeTag(poTag: IDeletableTag): void {
		if (poTag.deletable)
			this.moRemoveTagClicked.emit(poTag);
	}

	//#endregion METHODS

}
