<div class="separator" [ngClass]="observableSeparator.value$ | async">
	<div [ngClass]="observableSeparator.value$ | async"></div>
</div>

<div (click)="raiseFieldClicked($event)" class="content">
	<ion-icon [name]="observableIcon.value$ | async"></ion-icon>
	<ion-label class="field-label" *ngIf="!(observableHideLabel.value$ | async)">{{ observableLabel.value$ | async
		}}</ion-label>
	<ng-content select="calao-inline-field-layout-tags-content"></ng-content>
	<div [ngClass]="(observableHideLabel.value$ | async) ? '' : 'justify-end' " class="inline-content">
		<ng-content select="calao-inline-field-layout-content"></ng-content>
	</div>
</div>

<div class="bottom-content">
	<ng-content select="calao-inline-field-layout-bottom-content"></ng-content>
</div>