import { Component, OnInit } from '@angular/core';
import { ModalComponentBase } from '@calaosoft/osapp/modules/modal';
import { CatalogModalComponent } from '../catalog-modal-search/catalog-modal-search.component';

@Component({
	selector: 'merch-catalog-modal-main',
	templateUrl: './catalog-modal-main.component.html',
	styleUrls: [],
})
export class CatalogModalMainComponent extends ModalComponentBase<void> implements OnInit {

	//#region PROPERTIES

	public readonly rootComponent = CatalogModalComponent;

	//#endregion PROPERTIES

}
