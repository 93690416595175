import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ComponentBase } from '../helpers/ComponentBase';

/** Ce gardien permet de valider ou non le fait de quitter une page. */
@Injectable({ providedIn: "root" })
export class CanDeactivateGuard  {

	//#region METHODS

	public canDeactivate(poComponent: DynamicPageComponent<ComponentBase>, poCurrentRoute: ActivatedRouteSnapshot, poCurrentState: RouterStateSnapshot, poNextState?: RouterStateSnapshot): Observable<boolean> {
		return poComponent.canNavigate(poNextState?.url);
	}

	//#endregion

}