import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MapHelper } from '@calaosoft/osapp/helpers/mapHelper';
import { BarcodeReaderService } from '@calaosoft/osapp/modules/logistics/barcode-reader/services/barcode-reader.service';
import { IItemData } from '@calaosoft/osapp/modules/logistics/catalog/models/iitem-data';
import { CatalogService } from '@calaosoft/osapp/modules/logistics/catalog/services/catalog.service';
import { ModalComponentBase } from '@calaosoft/osapp/modules/modal/model/ModalComponentBase';
import { UserFeedBackService } from '@calaosoft/osapp/modules/user-feedback/services/user-feedback.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { IonNav, ModalController } from '@ionic/angular';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { secure } from '../../../utils/rxjs/operators/secure';
import { CatalogModalItemComponent } from '../catalog-modal-item/catalog-modal-item.component';
import { ICatalogModalItemParams } from '../catalog-modal-item/models/i-catalog-modal-item-params';

@Component({
	selector: "merch-catalog-modal",
	templateUrl: './catalog-modal-search.component.html',
	styleUrls: ['./catalog-modal-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogModalComponent extends ModalComponentBase<void> {

	//#region FIELDS

	private static readonly C_LOG_ID = "CATALOG.MDL.C::";

	//#endregion

	//#region PROPERTIES

	private msItemId?: string;
	/** Identifiant de l'article entré manuellement par l'utilisateur. */
	public get itemId(): string | undefined { return this.msItemId; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCatalog: CatalogService,
		private readonly isvcUserFeedback: UserFeedBackService,
		private readonly isvcBarcodeReader: BarcodeReaderService,
		private readonly ionNav: IonNav,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ionViewWillLeave(): void {
		this.isvcBarcodeReader.stopReadBarcodeAsync();
	}

	/** Réception d'un code-barres lu, change le mode d'affichage de la modale.
	 * @param psBarcode Code-barres lu.
	 */
	public onBarcodeRead(psBarcode: string): void {
		this.isvcCatalog.getItemIdByBarcode([psBarcode]).pipe(
			mergeMap((poResult: Map<string, string>) => {
				if (poResult.size !== 1) {
					console.error(`${CatalogModalComponent.C_LOG_ID}Barcode "${psBarcode}" not found in catalog.`);
					this.isvcUserFeedback.notifyError();
					return of(false);
				} else {
					return this.navigateToItem(MapHelper.valuesToArray(poResult)[0]);
				}
			})
		).subscribe();
	}

	/** Réception des chiffres entrés par l'utilisateur, change le mode d'affichage de la modale.
	 * @param psEvent Événement correspondant aux chiffres entrés par l'utilisateur.
	 */
	public onManualScan(psEvent: string): void {
		this.isvcCatalog.getItemFromId(psEvent).pipe(
			mergeMap((poItem?: IItemData) => {
				if (poItem)
					return this.navigateToItem(poItem.itemId);
				else {
					console.error(`${CatalogModalComponent.C_LOG_ID}Item id "${psEvent}" not found in catalog.`);
					// TODO #19967 Signaler à l'utilisateur que le code article n'est pas valide.
					return of(false);
				}
			}),
			secure(this)
		).subscribe();

		this.msItemId = psEvent;
	}

	private navigateToItem(psItemId: string): Promise<boolean> {
		const loModalParams: ICatalogModalItemParams = {
			itemId: psItemId
		};
		return this.ionNav.push(CatalogModalItemComponent, loModalParams);
	}

	//#endregion

}