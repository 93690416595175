<osapp-header-modal title="FICHE ARTICLE" toolbarButtonsSlot="end"></osapp-header-modal>

<ng-container *ngIf="observableIsLoading.value$ | async; then loading else productItem"></ng-container>

<ng-template #loading>
	<ion-spinner></ion-spinner>
</ng-template>

<ng-template #productItem>
	<ng-container *ngIf="(observableItem.value$ | async) as item">
		<calao-dms-image *ngIf="(dmsIds$ | async) as dmsIds" [imageDmsIds]="dmsIds"></calao-dms-image>
		<div class="bottom-sheet-area">
			<div class="top-area">
				<ion-note>{{item.label}}</ion-note>
				<ion-label class="article-id">{{itemId}}</ion-label>

				<ul>
					<li *ngFor="let category of item.categories">{{category.label}}</li>
				</ul>
			</div>

			<div class="bottom-area">
				<ion-label>Historique article client</ion-label>
				<customer-selector-component (onCustomerSelected)="onCustomerChanged($event)"></customer-selector-component>
			</div>
			<calao-movements-list [customerId]="observableSelectedCustomerId.value$ | async" [itemId]="itemId">
			</calao-movements-list>
		</div>
	</ng-container>
</ng-template>