import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import { StringHelper } from '../../../../helpers/stringHelper';
import { BarcodeHelper } from '../../barcode-reader/helpers/BarcodeHelper';
import { IBarcodeReader } from '../../barcode-reader/models/IBarcodeReader';
import { BluetoothService } from '../../bluetooth/bluetooth.service';
import { IBluetoothDevice } from '../../bluetooth/models/IBluetoothDevice';
import { EAcquisitionType } from '../../models/EAcquisitionType';
import { TypeNotSupportedError } from '../../models/errors/TypeNotSupportedError';
import { DeviceUnknownError } from '../models/errors/DeviceUnknownError';
import { ETslType } from '../models/ETslType';
import { IBarcodeAcquisition } from '../models/ibarcode-acquisition';
import { IInventoryParam } from '../models/IInventoryParam';
import { IRfidAcquisition } from '../models/IRfidAcquisition';
import { ITslResponse } from '../models/ITslResponse';

/** Service qui permet de manipuler les différents appareil TSL.
 * Appareils testés: 1126 et 1153.
 */
@Injectable()
export class TslService implements IBarcodeReader {

	//#region FIELDS

	private static readonly C_CR: string = String.fromCharCode(13);
	private static readonly C_LF: string = String.fromCharCode(10);
	/** Caractères indiquant la fin d'une commande pour le TSL. */
	private static readonly C_END_FRAME: string = TslService.C_CR + TslService.C_LF;
	/** Pattern utilisé pour le nom des appareils TSL 1128.
	 * Ex: "001466-EU-1128".
	 */
	private static readonly C_TSL_1128: RegExp = /^[0-9]{6}-EU-1128$/;
	/** Pattern utilisé pour le nom des appareils TSL 1128.
	 * Ex: "001508-S-EU-1153".
	 */
	private static readonly C_TSL_1153: RegExp = /^[0-9]{6}-S-EU-1153$/;

	/** Sujet qui retourne un résultat pour chaque tag lu lors d'un inventaire. */
	private moInventorySubject = new Subject<IRfidAcquisition>();
	private moReadingSubject = new Subject<IRfidAcquisition[]>();
	private moBarcodeSubject = new Subject<IBarcodeAcquisition>();

	/** Stock les réponses de la lecture en cours. Émet le tableau dans l'observable `reading$` lorsque la lecture est terminée. */
	private maCurrentReadings: IRfidAcquisition[] = [];

	//#endregion

	//#region PROPERTIES

	public get inventory$(): Observable<IRfidAcquisition> {
		return this.moInventorySubject.asObservable();
	}

	public get reading$(): Observable<IRfidAcquisition[]> {
		return this.moReadingSubject.asObservable();
	}

	public get barcode$(): Observable<IBarcodeAcquisition> {
		return this.moBarcodeSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcBluetooth: BluetoothService,
	) { }

	/** @implements */
	public initializeBarcode(): void {
		// Change le comportement d'une gachette simple pour la lecture de code-barres.
		this.switchActionCommand("-s bar").subscribe();
	}

	/** @implements */
	public readBarcode(): void {
		// Lance le scanner de code-barres.
		this.sendCommand(".bc").pipe(
			catchError(poError => { console.error("TSL.S::", poError); return throwError(() => poError); }),
		).subscribe();
	}

	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		// Complète l'observable pour que la lecture précédente n'émette plus.
		this.moBarcodeSubject.complete();
		this.moBarcodeSubject = new Subject<IBarcodeAcquisition>();

		// Retourne l'observable des code-barres et transforme le résultat en tableau.
		return this.barcode$.pipe(
			map((poAcq: IBarcodeAcquisition) => [poAcq]),
		);
	}

	private send(psCommand: string): Observable<any> {
		return of(true).pipe(
			tap(_ => console.log("TSL.S::Commande envoyée:", psCommand)),
			mergeMap(_ => this.isvcBluetooth.write(psCommand))
		);
	}

	/** Ajoute la fin de la frame à la commande et l'envoie. */
	private sendCommand(psCommand: string): Observable<any> {
		return this.send(`${psCommand}${TslService.C_END_FRAME}`);
	}

	/** Lance une acquisition par RFID. */
	public startReading(poParam: IInventoryParam): Observable<IRfidAcquisition> {
		// Commande qui sera envoyé au TSL.
		let lsCommand = ".iv ";

		// Cas désactivation des alertes (bips).
		lsCommand += this.setCommandParam(poParam.alert, "-al on", "-al off");

		// Cas où on veut la fréquence de retour.
		lsCommand += this.setCommandParam(poParam.withOutputPower, "-r on", "-r off");

		return this.sendCommand(lsCommand).pipe(
			tap(
				poResult => console.log("TSL.S::Start inventory response :", poResult),
				poError => console.error("TSL.S::Start inventory error :", poError)
			),
			mergeMapTo(this.inventory$)
		);
	}

	/** Applique un paramétrage sur une commade du Tsl. Si undefined, laisse le paramétrage par défaut, sinon spécifie un paramètre. */
	private setCommandParam(pbParameter: boolean | undefined | null, psTrueCase: string, psFalseCase: string): string {
		// On ajoute un espace car sinon les paramètres seront collés ou on laisse le comportement par défaut du Tsl.
		return typeof pbParameter === "boolean" ? `${pbParameter ? psTrueCase : psFalseCase} ` : "";
	}

	/** Retourne tous les paramètres du mode inventaire. */
	public getInventoryParam(): Observable<any> {
		return this.sendCommand(".iv -p -n");
	}

	/** Initialise les sujets, lisible du service. */
	private init(): void {
		this.isvcBluetooth.subscribe(TslService.C_CR + TslService.C_LF + TslService.C_CR + TslService.C_LF)
			.pipe(
				tap(
					(psResult: string) => {
						console.log(`TSL.S::Le TSL a envoyé une réponse :\n${psResult}`);
						// Réponses obtenues dans le buffer.
						const laResponses: ITslResponse[] = psResult
							.split(TslService.C_LF)
							.map((poValue: string): ITslResponse => {
								const laParts: Array<string> = poValue.split(":");
								return { header: laParts[0] as string, value: laParts[1] };
							});

						// La dernière commande contient deux retours à la ligne : (Ok:CR LF CR LF) ou (ERR:val CR LF CR LF). On les supprime.
						this.handleResponses(this.removeEmptyResponses(laResponses));
					},
					poError => console.error("TSL.S::Erreur serial.subscribe :", poError)
				)
			).subscribe();
	}

	/** La dernière commande contient deux retours à la ligne : (Ok:CR LF CR LF) ou (ERR:val CR LF CR LF). On les supprime. */
	private removeEmptyResponses(paResponses: ITslResponse[]): ITslResponse[] {
		return paResponses.splice(0, paResponses.length - 2);
	}

	/** Gère le résultat d'une réponse du Tsl. */
	private handleResponses(paResponses: ITslResponse[]): void {
		for (let i = 0; i < paResponses.length; i++) {
			switch (paResponses[i].header) {

				case "EP":
					i += this.handleEPResponse(paResponses, i) - 1;
					break;

				case "BC":
					this.handleBCResponse(paResponses, i);
					break;

				default:
					console.warn("TSL.S::Réponse du TSL non prise en charge: ", paResponses[i]);
					break;
			}
		}

		this.moReadingSubject.next(this.maCurrentReadings);
		this.maCurrentReadings = [];
	}

	/** Gère les réponses de type EP (récupèration d'une valeur EPC).
	 * @param paResponses Tableaux des réponses du TSL.
	 * @param pnIndex Index de l'élément courant du tableau (celui avec l'en-tête EP).
	 * @returns Nombre de lignes traitées par la fonction.
	 */
	private handleEPResponse(paResponses: ITslResponse[], pnIndex: number): number {
		const loRfidResponse: IRfidAcquisition = { code: paResponses[pnIndex].value.trim() } as IRfidAcquisition;
		// Nombre de lignes gérés par la fonction.
		let lnHandledLine = 1;

		// Récupère la valeur du RSSI en dBm.
		if (this.nextResponseIfOfType(paResponses, pnIndex, "RI")) {
			lnHandledLine++;
			loRfidResponse.dbm = +(paResponses[++pnIndex].value.trim());
		}

		this.moInventorySubject.next(loRfidResponse);
		this.maCurrentReadings.push(loRfidResponse);

		return lnHandledLine;
	}

	private handleBCResponse(paResponses: ITslResponse[], pnIndex: number): void {
		const psCode = paResponses[pnIndex].value.replace("\r", "");

		this.moBarcodeSubject.next({ code: BarcodeHelper.removeBarcodeControlKey(psCode), numberOfScans: 1, taken: [new Date()] });
	}

	/** Retourne `true` si l'élément suivant est du type souhaité. */
	private nextResponseIfOfType(paResponses: ITslResponse[], pnIndex: number, psType: string): boolean {
		return paResponses[pnIndex + 1] && paResponses[pnIndex + 1].header === psType;
	}

	/** Change le comportement de la gachette. */
	private switchActionCommand(psCommand: string): Observable<void> {
		return this.sendCommand(`.sa ${psCommand}`);
	}

	/** ??? */
	public config(): Observable<any> {
		return this.sendCommand(".sa -d off -s off").pipe(mergeMap(_ => this.sendCommand(".al -bon -thig -dsho -n")));
	}

	/** Connecte le mobile à l'appareil Tsl dont l'adresse est passée en paramètre. */
	public connect(psDeviceId: string): Observable<IBluetoothDevice> {
		// TODO Vérifier que l'id passé est celui d'un appareil TSL.
		return this.isvcBluetooth.connect(psDeviceId).pipe(tap(_ => this.init()));
	}

	/** Change le bouton d'action du TSL.
	 * @param peType Type d'acquisition souhaité.
	 * @throws {TypeNotSupportedError}
	 */
	public setActionButton(peType: EAcquisitionType): Observable<any> {
		// TODO Testé
		let lsCommand: string;

		if (peType === EAcquisitionType.rfid)
			lsCommand = ".sa -d off -s inv";
		else if (peType === EAcquisitionType.barcode)
			lsCommand = ".sa -d off -s bar";

		return StringHelper.isBlank(lsCommand) ? throwError(() => new TypeNotSupportedError(EAcquisitionType[peType])) : this.sendCommand(lsCommand);
	}

	/** Désactive le bouton d'action. */
	public disableActionButton(): Observable<any> {
		// TODO Testé
		return this.sendCommand(".sa -d off -s off");
	}

	/** ??? */
	public muteBarcode(pbMute: boolean): Observable<any> {
		// TODO Testé
		const lsCommand: string = pbMute ? ".bc -aloff -n" : ".bc -alon -n";
		return this.sendCommand(lsCommand);
	}

	/** ??? A modifier voir page 27 */
	public setAlertProfile(psId: number): Observable<any> {
		// TODO Testé
		let lsCommand: string;

		// -n signifie "No action just set the parameter" (page 27).
		switch (psId) {
			case 1:
				lsCommand = ".al -bon -thig -dsho -n";
				break;
			case 2:
				lsCommand = ".al -bon -tlow -dsho -n";
				break;
			case 3:
				lsCommand = ".al -bon -dlon -thig -n";
				break;
			default:
				break;
		}

		return StringHelper.isBlank(lsCommand) ? throwError(() => new TypeNotSupportedError(psId.toString())) : this.sendCommand(lsCommand);
	}

	/** Produit un bip .
	 * @param {number} psId Intensité du bip (entre 1 et 3).
	 */
	public bip(psId: number): Observable<any> {
		let lsCommand: string;

		switch (psId) {
			case 1:
				lsCommand = ".al -bon -thig -dsho";
				break;
			case 2:
				lsCommand = ".al -bon -tlow -dsho";
				break;
			case 3:
				lsCommand = ".al -bon -dlon -thig";
				break;
			default:
				break;
		}

		return StringHelper.isBlank(lsCommand) ? throwError(() => new TypeNotSupportedError(psId.toString())) : this.sendCommand(lsCommand);
	}

	/** TODO */
	public getBatteryStatus(): Observable<boolean> {
		// Retourne l'état du statut de la batterie en temps réel.
		return of(true);
	}

	/** Retourne l'état courant de la batterie. */
	public getCurrentBatteryStatus(): Observable<any> {
		return this.sendCommand(".bl");
	}

	/** Permet de savoir si un appareil est un TSL en se basant sur son nom (renvoyé par le listage Bluetooth).
	 * @throws {DeviceUnknownError}
	 */
	public static getTslType(psName: string): ETslType {
		if (TslService.C_TSL_1128.test(psName))
			return ETslType.TSL_1128;
		else if (TslService.C_TSL_1153.test(psName))
			return ETslType.TSL_1153;
		else
			throw new DeviceUnknownError();
	}

	/** Permet de savoir si un appareil est un TSL en se basant sur son nom (renvoyé par le listage Bluetooth).
	 */
	public static isTsl(psName: string): boolean {
		return TslService.C_TSL_1128.test(psName) || TslService.C_TSL_1153.test(psName);
	}

	//#endregion

}