import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AnomalyBarcodeReaderModule } from '@calaosoft/osapp/modules/logistics/anomaly-barcode-reader/anomaly-barcode-reader.module';
import { TitledInputModule } from '@calaosoft/osapp/modules/titled-input/titled-input.module';
import { CatalogItemFinderComponent } from './catalog-item-finder.component';

const components = [CatalogItemFinderComponent];

@NgModule({
	declarations: components,
	imports: [
		AnomalyBarcodeReaderModule,
		TitledInputModule,
		FormsModule
	],
	exports: components
})
export class CatalogItemFinderModule { }
