import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../../../pipes/pipes.module';
import { ArticleChipPriceComponent } from './article-chip-price.component';

@NgModule({
	declarations: [ArticleChipPriceComponent],
	imports: [
		IonicModule,
		CommonModule,
		PipeModule
	],
	exports: [ArticleChipPriceComponent]
})
export class ArticleChipPriceModule { }
