import { EItemPriceType } from '@calaosoft/osapp/modules/logistics/task/models/eitem-price-type';

export abstract class PriceHelper {

	//#region METHODS

	/** Récupère l'icône d'un type de prix.
	 * @param psType Type de prix qui détermine l'icône associée.
	 * @param psDefaultIcon Icône par défaut si aucune correspondance de trouvée.
	 */
	public static getPriceTypeIcon(psType: string, psDefaultIcon?: string): string {
		if (this.isNormalPrice(psType))
			return "prix-normal";
		else if (this.isPetitPrice(psType))
			return "prix-petit";
		else if (this.isSoldesPrice(psType))
			return "prix-soldes";
		else if (this.isOutletPrice(psType))
			return "prix-outlet";
		else
			return psDefaultIcon || "";
	}

	/** Retourne `true` si le type de prix est un type `normal`, `false` sinon.
	 * @param psType Type de prix à vérifier.
	 */
	private static isNormalPrice(psType: string): boolean {
		return this.isPriceType(EItemPriceType.normal, psType);
	}

	/** Retourne `true` si le type de prix est un type `petit`, `false` sinon.
	 * @param psType Type de prix à vérifier.
	 */
	public static isPetitPrice(psType: string): boolean {
		return this.isPriceType(EItemPriceType.small, psType);
	}

	/** Retourne `true` si le type de prix est un type `soldes`, `false` sinon.
	 * @param psType Type de prix à vérifier.
	 */
	private static isSoldesPrice(psType: string): boolean {
		return this.isPriceType(EItemPriceType.soldes, psType);
	}

	/** Retourne `true` si le type de prix est un type `outlet`, `false` sinon.
	 * @param psType Type de prix à vérifier.
	 */
	public static isOutletPrice(psType: string): boolean {
		return this.isPriceType(EItemPriceType.outlet, psType);
	}

	/** `true` si la valeur du type de prix vaut bien le type de prix de référence.
	 * @param peReferenceType Type de prix avec lequel tester.
	 * @param psValue Valeur du type de prix à tester.
	 */
	private static isPriceType(peReferenceType: EItemPriceType, psValue: string): boolean {
		return peReferenceType === psValue.toUpperCase();
	}

	//#endregion

}